import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import banksApi from '../api/banks'
import { parseError } from '../utils'

const initialState = {
  data: null,
  error: null
}

export const fetchBanks = createAsyncThunk('banks/fetch', async () => {
  const response = await banksApi.getBanks()

  if (!response.ok) {
    const apiError = parseError(response)
    const payload = { type: 'error' }
    if (apiError) {
      payload.error = apiError
    }
    return payload
  }

  return response.data
})

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      if (action.payload.type === 'error') {
        state.error = action.payload.error
      } else {
        state.data = action.payload
      }
    })
  }
})

export const bankActions = banksSlice.actions

export default banksSlice.reducer
