import Filter from '../globals/filter/Filter'
import DateRangeModal from '../globals/filter/DateRangeModal'
import { useContext, useState } from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import { resolveDate } from '../../helpers/queryByDate'

export default function ReceiptsFilter ({
  isOpen,
  onClose,
  filter,
  setFilter,
  dateRange,
  setDateRange
}) {
  const { userRole } = useContext(AuthLayoutContext)

  const [selectedFilter, setSelectedFilter] = useState(filter)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)

  const branches = useSelector(state => state?.orgs?.branches)

  const date_options = useMemo(() => {
    return [
      { name: 'Today', value: 'today' },
      { name: 'Yesterday', value: 'yesterday' },
      { name: 'Last 7 days', value: 'last_7_days' },
      { name: 'Last 30 days', value: 'last_30_days' },
      {
        name: (
          <>
            Range{' '}
            {selectedFilter.date === 'range' && (
              <small>
                ({resolveDate(dateRange[0].format())} -{' '}
                {resolveDate(dateRange[1].format())})
              </small>
            )}
          </>
        ),
        value: 'range'
      }
    ]
  }, [dateRange, selectedFilter.date])

  const branch_options = useMemo(() => {
    return branches
      ? branches.map(branch => ({ name: branch.name, value: branch.name }))
      : []
  }, [branches])

  const onSelectBranch = value => {
    setSelectedFilter(state => ({ ...state, branch: value }))
  }

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setSelectedFilter(state => ({ ...state, date: value }))
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const cancelDateRange = () => {
    setSelectedFilter(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  const applyFilters = () => {
    setFilter(selectedFilter)
    setDateRange(selectedDateRange)
    onClose()
  }

  return (
    <Filter.Body isOpen={isOpen} onClose={onClose} onCancel={onClose}>
      {userRole?.domain.index > 0 && (
        <Filter.SelectDropdown
          title='Branch'
          value={selectedFilter.branch}
          placeholder='Select branch'
          options={branch_options}
          onChange={onSelectBranch}
        />
      )}
      <Filter.SelectDropdown
        title='Date'
        value={selectedFilter.date}
        placeholder='Select Date'
        options={date_options}
        onChange={onSelectDate}
      />
      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />
      <DateRangeModal
        isOpen={isDateModalOpen}
        initialDateRange={dateRange}
        onCancel={cancelDateRange}
        onSave={onSaveDateRange}
      />
    </Filter.Body>
  )
}
