import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { ReactComponent as Pass } from '../../../assets/pass.svg'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../constants/routes'

const customStyles = {
  content: {
    inset: '16px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 60
  }
}

const SuccessModal = ({ setOpenModal, openModal, id }) => {
  const navigate = useNavigate()
  const gotoTransferManifest = () => {
    navigate(ROUTES.MANIFEST.TRANSFER_MANIFEST.path)
  }

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={setOpenModal}
        style={customStyles}
        appElement={document.getElementById('root')}
      >
         <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
          onClick={() => {
            setOpenModal(false)
            setTimeout(() => {
              gotoTransferManifest()
            }, 2000)
          }}
        >
          <LiaTimesSolid size={24} />
        </button>
        <div className='w-[400px]'>
          <div className='w-full h-[300px] flex flex-col justify-center items-center space-y-2'>
            <Pass />
            <div className='font-semibold'>
              Transfer manifest ID successfully generated
            </div>
            <div className='text-xs text-center'>
              A transfer manifest with ID{' '}
              <span className='text-mani-primary'>{id}</span> has been generated
              and added to the list of existing manifest
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SuccessModal
