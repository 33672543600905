import { useMemo, useState } from 'react'
import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import Filter from '../filter/Filter'
import EmptyExportDataNotification from './EmptyExportDataNotification'
import { resolveDate, resolveDateParams } from '../../../helpers/queryByDate'
import DateRangeModal from '../filter/DateRangeModal'
import { CircularProgress } from '@mui/material'
import { initializeDateRange } from '../../../utils'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ExportDialogue ({
  isOpen,
  name,
  onClose,
  options,
  onExport
}) {
  const [isEmpty, setEmpty] = useState(false)
  const [exportOptions, setExportOptions] = useState(options)
  const [isLoading, setLoading] = useState(false)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [dateRange, setDateRange] = useState(initializeDateRange())

  const date_options = useMemo(() => {
    return [
      { name: 'Today', value: 'today' },
      { name: 'Yesterday', value: 'yesterday' },
      { name: 'Last 7 days', value: 'last_7_days' },
      { name: 'Last 30 days', value: 'last_30_days' },
      {
        name: (
          <>
            Range{' '}
            {exportOptions.date === 'range' && (
              <small>
                ({resolveDate(dateRange[0].format())} -{' '}
                {resolveDate(dateRange[1].format())})
              </small>
            )}
          </>
        ),
        value: 'range'
      }
    ]
  }, [dateRange, exportOptions.date])

  const isDisabled = useMemo(() => !!exportOptions.date, [exportOptions.date])

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setExportOptions(state => ({ ...state, date: value }))
  }

  const cancelDateRange = () => {
    setExportOptions(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const onSaveDateRange = dateRange => {
    setDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const onSubmit = () => {
    if (!isLoading) {
      setLoading(true)
      const params = { ...exportOptions }
      const { start_date, end_date } = resolveDateParams(
        exportOptions.date,
        dateRange
      )
      params.start_date = start_date
      params.end_date = end_date
      delete params.date

      onExport(params, () => {
        setLoading(false)
        onClose()
      })
    }
  }

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div aria-describedby={`${name}-export-dialogue`}>
        <div className='flex items-center w-full justify-between'>
          <h3 className='text-lg text-dark-primary font-medium pl-4'>
            Select date range
          </h3>

          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <div className='flex flex-col p-4 gap-6 text-dark-primary'>
          {isEmpty && (
            <EmptyExportDataNotification
              isOpen={isEmpty}
              onClose={() => setEmpty(false)}
            />
          )}
          <Filter.SelectDropdown
            title='Date'
            value={exportOptions.date}
            placeholder='Select Date'
            options={date_options}
            onChange={onSelectDate}
          />
          <Filter.Submit
            disabled={!isDisabled}
            onSubmit={onSubmit}
            text={
              isLoading ? (
                <CircularProgress
                  style={{ marginLeft: '0.5rem' }}
                  size={28}
                  color='inherit'
                />
              ) : (
                'Export'
              )
            }
          />
          <DateRangeModal
            isOpen={isDateModalOpen}
            initialDateRange={dateRange}
            onCancel={cancelDateRange}
            onSave={onSaveDateRange}
          />
        </div>
      </div>
    </Modal>
  )
}
