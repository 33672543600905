import { useNavigate, useSearchParams } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import Page from '../../../containers/Page'
import CustomerForm from '../../../components/customers/CustomerForm'
import { Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import customerApi from '../../../api/customer'
import useCountry from '../../../hooks/useCountry'
import * as yup from 'yup'
import useToast from '../../../hooks/useToast'
import { parseError } from '../../../utils'
import { Loader } from '../../../components/globals'

const breadCrumbs = [
  {
    name: 'Organization',
    path: '',
    disabled: true
  },
  {
    name: 'External',
    path: ROUTES.ORGANIZATION.EXTERNAL.path
  },
  {
    name: 'Create New',
    path: ROUTES.ORGANIZATION.EXTERNAL.CREATE_NEW_EXTERNAL.path,
    disabled: true
  }
]

const CreateCustomer = ({ customer }) => {
  const [searchParams] = useSearchParams()

  const customerType = searchParams.get('t')
  const action = searchParams.get('action')

  const Country = useCountry()
  const toast = useToast()
  const navigate = useNavigate()

  const [phonecode, setPhonecode] = useState('')

  const initialValues = (() => {
    if (customer) {
      const phonecode = Country.getCountryByCode(customer.code)?.phonecode
      customer.phone_number = customer.phone_number.replace(phonecode, '')
      customer.business_contact = customer.business_contact.replace(
        phonecode,
        ''
      )
    }

    const config = {
      full_name: customer?.full_name || '',
      address: customer?.address || '',
      alt_address: customer?.alt_address || '',
      alt_address_2: customer?.alt_address_2 || '',
      code: customer?.code || '',
      state: customer?.state || '',
      state_name: customer?.state_name || '',
      city: customer?.city || '',
      postcode: customer?.postcode || '',
      phone_number: customer?.phone_number || '',
      email: customer?.email || '',
      business_name: customer?.business_name || '',
      business_contact: customer?.business_contact || '',
      area_or_province: customer?.area_or_province || '',
      partner: !!customer?.partner,
      shipa_or_ecommerce: !!customer?.shipa_or_ecommerce
    }

    return config
  })()

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape(
        (() => {
          const config = {
            full_name: yup.string().required('Full name is required'),
            address: yup
              .string()
              .required('Address is required')
              .max(45, 'Must not exceed 45 characters'),
            alt_address: yup.string().max(45, 'Must not exceed 45 characters'),
            alt_address_2: yup
              .string()
              .max(45, 'Must not exceed 45 characters'),
            code: yup.string().required('Country is required'),
            state_name: yup.string().required('State name is required'),
            state: yup.string().required('State code is required'),
            city: yup.string().required('City is required'),
            area_or_province: yup
              .string()
              .test(
                'is-required',
                'Province or Area is required',
                function (value) {
                  if (values.code === 'NG') {
                    return !!value
                  }
                  return true
                }
              ),
            postcode: yup
              .string()
              .required('Postcode is required')
              .min(4, 'Min. of 4 characters')
              .max(12, 'Max. of 12 characters'),
            phone_number: yup
              .string()
              .required('Phone number is required')
              .min(6, 'Minimum of 6 numbers')
              .max(15, 'Maximum of 15 numbers'),
            email: yup
              .string()
              .required('Email is required')
              .email('Must be a valid email'),
            business_name: yup.string(),
            business_contact: yup
              .string()
              .min(6, 'Minimum  of 6 numbers')
              .max(15, 'Maximum of 15 numbers')
          }

          return config
        })()
      )
    )

  const handleSubmit = async (body, actions) => {
    const country = Country.getCountryByCode(body.code)

    const payload = {
      ...body,
      phone_number: `${phonecode}${body.phone_number}`,
      business_contact: body.business_contact
        ? `${phonecode}${body.business_contact}`
        : '',
      country: country.name
    }

    if (customerType === 'Partners') {
      payload.partner = true
    } else if (customerType === 'E-commerce') {
      payload.shipa_or_ecommerce = true
    }

    let response

    if (action === 'edit') {
      response = await customerApi.updateCustomer(customer.id, payload)
    } else {
      response = await customerApi.createCustomer(payload)
    }

    actions.setSubmitting(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        if (response.data.status_code >= 500) {
          toast(response.data.message, 'error')
        } else {
          if (response.data.detail) {
            let error = ''
            if (Array.isArray(response.data.detail)) {
              error = response.data.detail[0].message
            } else {
              const errors = Object.entries(response.data.detail)
              const key = errors[0][0]
              const message = errors[0][1][0].message

              error = `${key.replace('_', ' ')}: ${message}`
            }

            toast(error, 'error')
          } else if (response.data.error) {
            toast(response.data.error, 'error')
          }
        }
      }

      return
    }

    if (action === 'edit') {
      toast('Updated customer successfully')
    } else {
      toast('Customer created successfully')
    }

    navigate(`${ROUTES.ORGANIZATION.EXTERNAL.path}?active=${customerType}`)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <CustomerForm
          formik={formik}
          phonecode={phonecode}
          setPhonecode={setPhonecode}
        />
      )}
    </Formik>
  )
}

function CreateNewExternalOrgs ({ metaTitle }) {
  const [searchParams] = useSearchParams()

  const customerType = searchParams.get('t')
  const action = searchParams.get('action')
  const customerId = searchParams.get('id')

  const [customer, setCustomer] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const loadCustomer = useCallback(async () => {
    const response = await customerApi.readCustomer(customerId)

    if (response.ok) {
      setCustomer(response.data)
    }
    setIsLoading(false)
  }, [customerId])

  useEffect(() => {
    if (customerId && action === 'edit') {
      loadCustomer()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, customerId])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title={`${action === 'edit' ? 'Edit' : 'Create New'} Customer - ${
          customerType.endsWith('s') ? customerType.slice(0, -1) : customerType
        }`}
        backRoute={`${ROUTES.ORGANIZATION.EXTERNAL.path}?active=${customerType}`}
        breadCrumbs={breadCrumbs}
      />
      <Page.Body>
        <div className='mx-auto w-full max-w-3xl pt-8'>
          {isLoading ? <Loader /> : <CreateCustomer customer={customer} />}
        </div>
      </Page.Body>
    </Page>
  )
}

export default CreateNewExternalOrgs
