import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError } from '../utils'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0
  }
}

export const fetchQuotes = createAsyncThunk(
  'quotes/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await ordersApi.getQuotes(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      response_data: response.data[0].payload,
      query_params: query
    }
  }
)

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchQuotes.fulfilled, (state, action) => {
      // const meta = action.payload?.response_data?.meta || {}
      // if (meta?.next) {
      //   meta.next = urlQueryStringToObject(meta.next).page
      // }
      // meta.page = action.payload?.query_params?.page
      // meta.previous = meta.page > 1 ? meta.page - 1 : 0
      // state.meta = Object.assign({}, state.meta, meta)
      state.data = action.payload?.response_data.sort(
        (qt1, qt2) => new Date(qt2.date_raised) - new Date(qt1.date_raised)
      )
    })
  }
})

export const quotesActions = quotesSlice.actions

export default quotesSlice.reducer
