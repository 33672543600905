const EmploymentType = Object.freeze({
  'FULL TIME': 'FT',
  'PART TIME': 'PT',
  'CONTRACT BASED': 'CB',
  INTERNSHIP: 'IT',
  'INDUSTRIAL TRAINING': 'IT'
})

const Gender = Object.freeze({
  MALE: 'M',
  FEMALE: 'F',
  NON_BINARY: 'NB',
  OTHER: 'O'
})

const GenderLookup = {
  Male: Gender.MALE,
  Female: Gender.FEMALE,
  'Non-Binary': Gender.NON_BINARY,
  Other: Gender.OTHER
}

const EmploymentLookup = {
  'Full Time': EmploymentType['FULL TIME'],
  'Part Time': EmploymentType['PART TIME'],
  Contract: EmploymentType['CONTRACT BASED'],
  Internship: EmploymentType['INTERNSHIP'],
  'Industrial Training': EmploymentType['INDUSTRIAL TRAINING']
}
export { EmploymentType, Gender, GenderLookup, EmploymentLookup }
