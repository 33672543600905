import { LiaTimesSolid } from 'react-icons/lia'

export default function FilterTag ({ name, value, onDelete }) {
  return (
    <div className='bg-white rounded-[28px] whitespace-nowrap flex items-center flex-nowrap'>
      <span className='pl-1.5'>
        <span className='text-[13px] text-[rgba(146,142,142,0.7)] mr-1 capitalize'>
          {name.replace(/[_]/g, ' ')}:
        </span>
        <span className='text-[13px] font-medium text-[#928E8E] mr-2 capitalize text-ellipsis'>
          {value}
        </span>
      </span>
      <button
        className='cursor-pointer p-1 flex bg-transparent hover:bg-neutral rounded-full transition-all'
        onClick={() => onDelete(name)}
      >
        <LiaTimesSolid size={16} />
      </button>
    </div>
  )
}
