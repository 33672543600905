import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthLayoutContext } from '../../../containers/AuthLayout'

function InternalTabs ({ activeTab, handleClick, allTabs }) {
  const navigate = useNavigate()
  const { userRole } = useContext(AuthLayoutContext)

  const tabs = useMemo(() => {
    return allTabs
  }, [userRole])

  return (
    <div className='relative flex flex-row items-end gap-4'>
      {tabs?.map((item, id) => (
        <button
          className={`text-base border-b p-1 ${
            activeTab === item.name
              ? 'font-bold border-primary bg-main-primary bg-opacity-5 rounded-t-lg'
              : 'border-transparent bg-transparent opacity-60 rounded-t-none hover:opacity-80'
          }`}
          onClick={() => {
            handleClick(item.name)
          }}
          key={id}
        >
          {item.name}
        </button>
      ))}
    </div>
  )
}

export default InternalTabs
