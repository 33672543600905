import Modal from 'react-modal'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LiaTimesSolid } from 'react-icons/lia'
import { EmploymentType, Gender } from '../../../constants'
import Button from '../../globals/Button'

const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '649px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

function EmployeeDetailsModal ({ isOpen, onClose, details, activeTab, edit }) {
  const extraData = useSelector(state => state.extra)

  const groups = useMemo(() => extraData?.groups, [extraData?.groups])

  const findGroupNames = val => {
    if (groups?.length) {
      const names = groups
        ?.filter(group => val.includes(group.id))
        ?.map(group => group.name)
        ?.join(', ')

      return names
    }
  }

  function getKeyByValue (item, value) {
    const name = Object.keys(item).find(key => item[key] === value)
    return name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase()
  }

  const employeeDetails = [
    { title: 'User ID', value: details['Employee ID'] },
    { title: 'Email', value: details.Email },
    {
      title: 'Phone number',
      value: details?.others?.user_details?.phone_no || '--'
    },
    {
      title: 'Address',
      value: details?.others?.user_details?.phone_no || '--'
    },
    { title: 'Gender', value: getKeyByValue(Gender, details?.others?.gender) },
    { title: 'Branch', value: details['Employee ID'] },
    {
      title: 'Employment type',
      value: getKeyByValue(EmploymentType, 'FT') || '--'
    },
    { title: 'Department', value: details?.others?.department || '--' },
    { title: 'Group', value: findGroupNames(details.others.groups) },
    {
      title: 'Status',
      value: details?.others?.user_details?.is_active
        ? 'Active'
        : 'Inactive' || '--'
    }
  ]

  const partnersDetails = [
    { title: 'User ID', value: details['ID'] },
    { title: 'Company/Business Name', value: details['Company Name'] },
    { title: 'Email', value: details['Email'] },
    { title: 'State', value: details['State'] },
    { title: 'Contact Name', value: details['Contact Name'] }
  ]

  const formattedDetails = useMemo(() => {
    switch (activeTab) {
      case 'Employees':
        return employeeDetails
      case 'Partners':
        return partnersDetails
      default:
        return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, details])

  return (
    <Modal style={styles} isOpen={isOpen}>
      <div className='flex justify-end'>
        <button
          className='w-8 h-8 border-transparent border-solid border-[0.75px] hover:border-gray-500 cursor-pointer'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
      </div>
      <div>
        <h3 className='text-lg font-semibold mb-6'>{details['Full Name']}</h3>
        {formattedDetails.map((item, index) => (
          <div key={index} className='flex justify-between items-center mb-6'>
            <p className='text-[#333333CC] text-sm font-normal'>{item.title}</p>
            <p className='text-sm font-medium'>{item.value}</p>
          </div>
        ))}
      </div>
      <div className='flex justify-end'>
        <Button
          bgColor='#FF4D00'
          onClick={edit}
          text={`Edit`}
          color='#FFFFFF'
          size='text-sm'
          borderRadius='10px'
        />
      </div>
    </Modal>
  )
}

export default EmployeeDetailsModal
