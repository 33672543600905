import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseError } from '../utils'
import extraDataApi from '../api/extraData'

const initialState = {
  roles: null,
  groups: null
}

export const fetchRoles = createAsyncThunk('extraData/roles', async () => {
  const response = await extraDataApi.listRoles()

  if (!response.ok) {
    return parseError(response)
  }
  return response.data
})

export const fetchGroups = createAsyncThunk('extraData/groups', async () => {
  const response = await extraDataApi.listGroups()

  if (!response.ok) {
    return parseError(response)
  }

  return response.data
})

const extraDataSlice = createSlice({
  name: 'extraData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.roles = action.payload
    })
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.groups = action.payload
    })
  }
})

export const extraDataActions = extraDataSlice.actions

export default extraDataSlice.reducer
