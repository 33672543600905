import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { Formik } from 'formik'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import * as yup from 'yup'
import { CircularProgress } from '@mui/material'
import salesforceApi from '../../api/salesforce'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBanks } from '../../slices/banksSlice'
import { PiWarningCircle } from 'react-icons/pi'
import CreateAgentSuccessful from './CreateAgentSuccessful'
import banksApi from '../../api/banks'

const style = {
  content: {
    inset: '16px',
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CreateSalesforceAgent ({ isOpen, onClose }) {
  const toast = useToast()
  const dispatch = useDispatch()

  const [isSuccess, setSuccess] = useState(false)
  const [agent, setAgent] = useState({})

  useEffect(() => {
    dispatch(fetchBanks())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    bank_details: { account_number: '', bank: '', account_name: '' },
    commission: 10
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        first_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('First name is required'),
        last_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('Last name is required'),
        phone: yup
          .string()
          .min(6, 'Minimum of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
          .required('Phone number is required'),
        email: yup
          .string()
          .email('Must be a valid email')
          .required('Email is required'),
        bank_details: yup.object().shape({
          account_number: yup
            .string()
            .required('Account number is required')
            .test('is-digits-only', 'Must be digits only', function (value) {
              if (value) {
                return value.match(/^\d+$/)
              }
              return true
            })
            .min(10, 'Minimum of 10 digits')
            .max(10, 'Maximum of 10 digits'),
          bank: yup.string().required('Bank is required'),
          account_name: yup.string().required('Account name is required')
        }),
        commission: yup
          .number()
          .max(100, 'Cannot be more than 100%')
          .min(0, 'Cannot be less than 0%')
          .required('Commission is required')
      })
    )

  const handleSubmit = async (body, actions) => {
    const { code: bank_code, name: bank } = JSON.parse(body.bank_details.bank)

    const payload = {
      ...body,
      phone: `+234${body.phone}`,
      bank_details: {
        bank_code,
        bank,
        account_number: body.bank_details.account_number,
        account_name: body.bank_details.account_name
      },
      is_verified: true
    }

    const response = await salesforceApi.createSalesforceAgent(payload)

    actions.setSubmitting(false)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        const errors = Object.values(apiError.data.detail)
        const message = errors[0][0].message
        toast(message ?? 'Error creating salesforce agent', 'error')
      }
      return
    }

    setAgent({
      name: `${body.first_name} ${body.last_name}`,
      email: body.email
    })
    setSuccess(true)
  }

  const handleSuccessClose = () => {
    setSuccess(false)
    onClose(true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      appElement={document.getElementById('root')}
    >
      <div>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
        <div className='flex flex-col p-4 gap-6'>
          <h5 className='text-[#3A3A3A] text-xl text-center font-bold'>
            Create New SalesForce Agent
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formik => <AgentForm formik={formik} />}
          </Formik>
        </div>
      </div>
      {isSuccess && (
        <CreateAgentSuccessful
          isOpen={isSuccess}
          onClose={handleSuccessClose}
          agent={agent}
        />
      )}
    </Modal>
  )
}

const AgentForm = ({ formik }) => {
  const banks = useSelector(state => state.banks)

  const [resolvingAccount, setResolvingAccount] = useState({
    isLoading: false,
    success: false,
    error: false
  })

  const { values, setFieldValue } = formik

  const resolveAccount = async () => {
    setResolvingAccount({
      isLoading: true,
      success: false,
      error: false
    })

    const bank_details = values.bank_details
    const bank = JSON.parse(bank_details.bank)

    const response = await banksApi.resolveAccountNumber({
      bank_code: bank.code,
      account_number: bank_details.account_number
    })

    if (!response.ok) {
      setResolvingAccount({
        isLoading: false,
        success: false,
        error: true
      })
      return
    }

    setResolvingAccount({
      isLoading: false,
      success: true,
      error: false
    })
    setFieldValue('bank_details.account_name', response.data.data.account_name)
  }

  useEffect(() => {
    if (
      values.bank_details.account_number?.length === 10 &&
      values.bank_details.account_number.match(/^\d+$/) &&
      values.bank_details.bank
    ) {
      resolveAccount()
    } else if (values.bank_details.account_name) {
      setFieldValue('bank_details.account_name', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bank_details.account_number, values.bank_details.bank])

  return (
    <div className='px-4'>
      <AppForm onSubmit={formik.handleSubmit} id='new_agent_form'>
        <div className='bg-g-200/50 p-6 grid grid-cols-2 gap-3'>
          <AppFormField
            name='first_name'
            title='First Name'
            placeholder='e.g Noah'
          />
          <AppFormField
            name='last_name'
            title='Last Name'
            placeholder='e.g Ayodele'
          />
          <AppFormField
            name='email'
            type='email'
            title='Email'
            placeholder='e.g noahayodele@example.com'
          />
          <AppFormField
            name='phone'
            title='Phone Number'
            placeholder='8123456789'
            type='number'
            phonecode='+234'
          />
          <AppFormField
            name='bank_details.account_number'
            title='Bank Account Number'
            placeholder='0012385424'
          />
          <AppFormSelectField name='bank_details.bank' title='Bank Name'>
            <option value=''>Select bank</option>
            {banks.data?.map((bank, id) => (
              <option
                value={JSON.stringify({ name: bank.name, code: bank.code })}
                key={id}
              >{`${bank.name} (${bank.code})`}</option>
            ))}
          </AppFormSelectField>
          <div>
            <div className='form-control w-full'>
              <label className='label'>
                <span className='label-text'>Account Name</span>
              </label>
              <input
                value={values.bank_details.account_name}
                name='bank_details.account_name'
                readOnly
                className='sr-only'
              />
              <div className='h-[38px] w-full text-sm px-4 py-2 rounded-lg border border-[#cbcbcb] bg-white'>
                {resolvingAccount.isLoading ? (
                  <div className='flex w-full items-center justify-between text-[#6b7281]'>
                    <span>Resolving account...</span>
                    <CircularProgress size={14} style={{ color: '#FF4D00' }} />
                  </div>
                ) : resolvingAccount.success ? (
                  <span className='text-dark-primary'>
                    {values.bank_details.account_name}
                  </span>
                ) : (
                  <span className='text-[#6b7281]'>Account Name</span>
                )}
              </div>
            </div>
            {resolvingAccount.error && (
              <div className='flex items-center gap-1 w-full mt-1'>
                <span className='w-4 block'>
                  <PiWarningCircle size={14} color='#dc2626' />
                </span>
                <p className='text-xs text-error w-[calc(100%_-_1.25rem)]'>
                  Bank Account not found
                </p>
              </div>
            )}
          </div>
          <AppFormField
            name='commission'
            type='number'
            title='Commission (%)'
            placeholder='10'
          />
        </div>
        <div className='flex w-full justify-end'>
          <button
            className='btn btn-primary'
            type='submit'
            form='new_agent_form'
          >
            {formik.isSubmitting ? (
              <CircularProgress size={28} color='inherit' />
            ) : (
              'Create'
            )}
          </button>
        </div>
      </AppForm>
    </div>
  )
}
