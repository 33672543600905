import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import receiptsReducer from '../slices/receiptsSlice'
import transactionsReducer from '../slices/transactionSlice'
import shipmentSocketReducer from '../slices/shipmentSocketSlice'
import paymentSocketReducer from '../slices/paymentSocketSlice'
import invoicesReducer from '../slices/invoicesSlice'
import customersReducer from '../slices/customersSlice'
import addonsReducer from '../slices/addonsSlice'
import dimensionsReducer from '../slices/dimensionsSlice'
import ordersReducer from '../slices/ordersSlice'
import quotesReducer from '../slices/quotesSlice'
import manifestReducer from '../slices/manifestSlice'
import singleManifestReducer from '../slices/singleManifestSlice'
import hubsReducer from '../slices/hubsSlice'
import ridersReducer from '../slices/ridersSlice'
import { shipmentSocketMiddleware } from './middlewares/shipmentSocket'
import { paymentSocketMiddleware } from './middlewares/paymentSocket'
import shipmentsReducer from '../slices/shipmentsSlice'
import allBagsReducer from '../slices/bagsSlice'
import dashboardReducer from '../slices/dashboardSlice'
import exceptionsReducer from '../slices/exceptionsSlice'
import categoriesReducer from '../slices/categoriesSlice'
import locationsReduer from '../slices/locationsSlice'
import shipmentTrackerReducer from '../slices/shipmentTrackerSlice'
import orgsReducer from '../slices/orgsSlice'
import closedBagsReducer from '../slices/closedBagSlice'
import allManifestShipments from '../slices/allInManifestShipments'
import authReducer from '../slices/authSlice'
import salesforceReducer from '../slices/salesforceSlice'
import banksReducer from '../slices/banksSlice'
import extraReducers from '../slices/extraDataSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    receipts: receiptsReducer,
    transactions: transactionsReducer,
    shipmentSocket: shipmentSocketReducer,
    paymentSocket: paymentSocketReducer,
    invoices: invoicesReducer,
    customers: customersReducer,
    orders: ordersReducer,
    shipments: shipmentsReducer,
    quotes: quotesReducer,
    addons: addonsReducer,
    dimensions: dimensionsReducer,
    categories: categoriesReducer,
    locations: locationsReduer,
    manifest: manifestReducer,
    singleManifest: singleManifestReducer,
    hubs: hubsReducer,
    riders: ridersReducer,
    allBags: allBagsReducer,
    exceptions: exceptionsReducer,
    trackShipment: shipmentTrackerReducer,
    orgs: orgsReducer,
    closeBags: closedBagsReducer,
    allShipments: allManifestShipments,
    salesforce: salesforceReducer,
    banks: banksReducer,
    extra: extraReducers
  },
  middleware: getDefaultMiddleWare => {
    return [
      ...getDefaultMiddleWare(),
      thunk,
      shipmentSocketMiddleware(),
      paymentSocketMiddleware()
    ]
  }
})

export default store
