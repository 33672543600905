import { CircularProgress } from '@mui/material'
import ReactModal from 'react-modal'
import { IoMdClose } from 'react-icons/io'

const styles = {
  content: {
    borderRadius: '0',
    maxWidth: '649px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    padding: '0',
    border: 'none'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

function DeleteModal ({
  selectedEmployee,
  isOpen,
  title,
  close,
  subtitle,
  btnText,
  handleAction,
  loading
}) {
  return (
    <ReactModal style={styles} isOpen={isOpen}>
      <div onClick={close} className='flex items-center justify-end p-4'>
        <IoMdClose size={28} />
      </div>

      <div className='max-w-[566px] flex justify-center items-center mx-auto mb-16'>
        <div className='bg-white flex flex-col items-center  '>
          <h4 className='text-center text-3xl font-bold mb-7'>{title}</h4>
          <p className='text-center text-[#333333CC] text-base font-normal'>
            {subtitle}
          </p>
          <div className='flex w-full justify-center items-center mx-auto mt-10 gap-8'>
            <button
              className='btn btn-outline text-[#FF4D00] hover:bg-primary  w-[225px]'
              onClick={close}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary w-[225px]'
              onClick={handleAction}
            >
              {loading ? (
                <CircularProgress size={28} color='inherit' />
              ) : (
                btnText
              )}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default DeleteModal
