import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { Button } from '../../../components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import customerApi from '../../../api/customer'
import { PiWarningCircle } from 'react-icons/pi'
import { Loader } from '../../../components/globals'

const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '649px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CustomerDetailsModal ({
  isOpen,
  customerId,
  onClose,
  onEdit
}) {
  const [customer, setCustomer] = useState()
  const [status, setStatus] = useState('isLoading')

  const loadCustomer = useCallback(async () => {
    const response = await customerApi.readCustomer(customerId)

    if (!response.ok) {
      setStatus('error')

      return
    }
    setCustomer(response.data)
    setStatus('fetched')
  }, [customerId])

  useEffect(() => {
    if (customerId) {
      loadCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  const formattedDetails = useMemo(() => {
    if (customer) {
      return [
        { title: 'Email', value: customer.email },
        {
          title: 'Phone Number',
          value: customer.phone_number
        },
        {
          title: 'Address',
          value: customer.address
        },
        {
          title: 'Landmark',
          value: customer.alt_address
        },
        {
          title: 'Postcode',
          value: customer.postcode
        },
        customer.code === 'NG' && {
          title: 'Province',
          value: customer.area_or_province
        },
        {
          title: 'City',
          value: customer.city
        },
        {
          title: 'State',
          value: customer.state_name || customer.state
        },
        {
          title: 'Country',
          value: `${customer.country} (${customer.code})`
        },
        {
          title: 'Business Name',
          value: customer.business_name
        },
        {
          title: 'Business Contact',
          value: customer.business_contact
        },
        {
          title: 'Customer type',
          value: customer.partner
            ? 'Partner'
            : customer.shipa_or_ecommerce
            ? 'E-commerce'
            : 'Regular'
        }
      ]
    }
  }, [customer])

  return (
    <Modal style={styles} isOpen={isOpen}>
      <div className='flex justify-end'>
        <button
          className='w-8 h-8 border-transparent border-solid border-[0.75px] hover:border-gray-500 cursor-pointer'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
      </div>
      {status === 'isLoading' && <Loader />}
      {status === 'error' && (
        <div className='py-6 flex items-center justify-center flex-col text-center gap-4 w-full'>
          <PiWarningCircle size={36} color='#dc2626' />
          <p className='text-error'>Error: Unable to fetch customer.</p>
        </div>
      )}
      {status === 'fetched' && (
        <>
          <div>
            <h3 className='text-lg font-semibold mb-6'>{customer.full_name}</h3>
            {formattedDetails.map((detail, index) => (
              <div
                key={index}
                className='flex justify-between items-center mb-6'
              >
                <p className='text-[#333333CC] text-sm font-normal'>
                  {detail.title}
                </p>
                <p className='text-sm font-medium'>{detail.value}</p>
              </div>
            ))}
          </div>
          <div className='flex justify-end'>
            <Button
              bgColor='#FF4D00'
              onClick={() => onEdit(customer.id)}
              text={`Edit`}
              color='#FFFFFF'
              size='text-sm'
              borderRadius='10px'
            />
          </div>
        </>
      )}
    </Modal>
  )
}
