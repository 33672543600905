export default function ManifestsIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.25 5.5H8.75C7.36929 5.5 6.25 6.61929 6.25 8V24.25C6.25 25.6307 7.36929 26.75 8.75 26.75H21.25C22.6307 26.75 23.75 25.6307 23.75 24.25V8C23.75 6.61929 22.6307 5.5 21.25 5.5Z'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
      />
      <path
        d='M11.25 11.75H18.75M11.25 16.75H18.75M11.25 21.75H16.25'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  )
}
