import { twMerge } from 'tailwind-merge'
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg'

export default function FilterButton ({ isOpen, onClick }) {
  return (
    <button
      className={twMerge(
        'bg-white rounded-lg p-2 w-12 sm:w-24 flex flex-nowrap whitespace-nowrap items-center justify-center text-g-600 hover:bg-[#E5E4E5] text-sm h-[2.5rem] gap-1 border',
        isOpen ? 'border-primary' : ''
      )}
      onClick={onClick}
    >
      <FilterIcon />
      <span className='hidden sm:inline-block'>Filter by</span>
    </button>
  )
}
