export default function DashboardIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.875 11.75V5.5H25V11.75H16.875ZM5 15.5V5.5H13.125V15.5H5ZM16.875 25.5V15.5H25V25.5H16.875ZM5 25.5V19.25H13.125V25.5H5ZM6.25 14.25H11.875V6.75H6.25V14.25ZM18.125 24.25H23.75V16.75H18.125V24.25ZM18.125 10.5H23.75V6.75H18.125V10.5ZM6.25 24.25H11.875V20.5H6.25V24.25Z'
        fill={variant === 'primary' ? '#fe6802' : '#333333'}
      />
    </svg>
  )
}
