import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseError } from '../utils'
import orgsApi from '../api/orgs'

const initialState = {
  branches: null,
  areas: null,
  regions: null,
  systems: null
}

export const fetchBranches = createAsyncThunk(
  'orgs/branches',
  async (params = {}) => {
    const response = await orgsApi.getBranches(params)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }
    return response.data
  }
)

export const fetchAreas = createAsyncThunk('orgs/areas', async () => {
  const response = await orgsApi.getAreas()

  if (!response.ok) {
    const apiError = parseError(response)
    if (apiError) {
      return {
        type: 'error',
        error: apiError
      }
    }
  }

  return response.data.payload
})

export const fetchRegions = createAsyncThunk(
  'orgs/regions',
  async (params = {}) => {
    const response = await orgsApi.getRegions(params)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }
    return response.data.payload
  }
)

export const fetchSystems = createAsyncThunk('orgs/systems', async () => {
  const response = await orgsApi.getAllSystems()

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return response.data
  }
)

const orgsSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    filter (state, action) {
      state.filter = action.payload
    },
    cleanupFilter (state) {
      state.filter = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      state.branches = action.payload?.payload
    })
    builder.addCase(fetchAreas.fulfilled, (state, action) => {
      state.areas = action.payload
    })
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.regions = action.payload
    })
    builder.addCase(fetchSystems.fulfilled, (state, action) => {
      state.systems = action.payload
    })
  }
})

export const orgsActions = orgsSlice.actions

export default orgsSlice.reducer
