export default function SalesforceIcon ({
  variant = 'secondary',
  ...restProps
}) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.125 6.125V4.25H25.3125V2.375H23.4375V4.25H22.5C21.4659 4.25 20.625 5.09188 20.625 6.125V8C20.625 9.03406 21.4659 9.875 22.5 9.875H26.25V11.75H20.625V13.625H23.4375V15.5H25.3125V13.625H26.25C27.2841 13.625 28.125 12.7841 28.125 11.75V9.875C28.125 8.84188 27.2841 8 26.25 8H22.5V6.125H28.125ZM22.5 19.25V21.125H24.9244L21.5625 24.4869L19.4137 22.3372C19.2382 22.1616 19.0002 22.0628 18.7519 22.0625H18.75C18.5017 22.0628 18.2637 22.1616 18.0881 22.3372L13.125 27.2994L14.4506 28.625L18.7509 24.3256L20.8997 26.4753C21.0755 26.6511 21.3139 26.7498 21.5625 26.7498C21.8111 26.7498 22.0495 26.6511 22.2253 26.4753L26.25 22.4506V24.875H28.125V19.25H22.5ZM3.75 28.625H1.875V23.9375C1.875 20.3188 4.81875 17.375 8.4375 17.375H14.0625C15.9272 17.375 17.7094 18.1719 18.9534 19.5622L17.5566 20.8128C17.1169 20.3212 16.5784 19.9278 15.9763 19.6585C15.3742 19.3892 14.7221 19.25 14.0625 19.25H8.4375C5.85281 19.25 3.75 21.3528 3.75 23.9375V28.625ZM11.25 15.5C12.9905 15.5 14.6597 14.8086 15.8904 13.5779C17.1211 12.3472 17.8125 10.678 17.8125 8.9375C17.8125 7.19702 17.1211 5.52782 15.8904 4.29711C14.6597 3.0664 12.9905 2.375 11.25 2.375C9.50952 2.375 7.84032 3.0664 6.60961 4.29711C5.3789 5.52782 4.6875 7.19702 4.6875 8.9375C4.6875 10.678 5.3789 12.3472 6.60961 13.5779C7.84032 14.8086 9.50952 15.5 11.25 15.5ZM11.25 4.25C12.4932 4.25 13.6855 4.74386 14.5646 5.62294C15.4436 6.50201 15.9375 7.6943 15.9375 8.9375C15.9375 10.1807 15.4436 11.373 14.5646 12.2521C13.6855 13.1311 12.4932 13.625 11.25 13.625C10.0068 13.625 8.81451 13.1311 7.93544 12.2521C7.05636 11.373 6.5625 10.1807 6.5625 8.9375C6.5625 7.6943 7.05636 6.50201 7.93544 5.62294C8.81451 4.74386 10.0068 4.25 11.25 4.25Z'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        fill={variant === 'primary' ? '#fe6802' : '#333333'}
        strokeWidth='0'
      />
    </svg>
  )
}
