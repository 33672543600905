import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import receiptsApi from '../api/receipts'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0,
    page_size: 50
  }
}

export const fetchReceipts = createAsyncThunk(
  'receipts/fetchReceipts',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await receiptsApi.getReceipts(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchReceipts.fulfilled, (state, action) => {
      const meta = action.payload?.response_data?.meta || {}
      if (meta.next) {
        meta.next = urlQueryStringToObject(meta.next).page
      }
      meta.page = action.payload?.query_params?.page
      meta.page_size = action.payload?.query_params?.page_size
      meta.previous = meta.page > 1 ? meta.page - 1 : 0
      state.meta = Object.assign({}, state.meta, meta)
      state.data = action.payload?.response_data?.payload
        .sort((a, b) => {
          const date_a = new Date(a.date_time).getTime()
          const date_b = new Date(b.date_time).getTime()
          return date_a - date_b
        })
        .reverse()
    })
  }
})

export default receiptsSlice.reducer
