import { SuccessPopup } from '../globals'

export default function PaymentSuccessful ({ isOpen, transaction }) {
  return (
    <SuccessPopup
      isOpen={isOpen}
      title='Payment Successful!'
      description='Your Payment has been processed! Details of transaction are included below'
      secondaryDescription={
        <>
          <div className='mb-2'>
            <span className='font-semibold mr-2'>Transaction Reference:</span>
            <span className='text-dark-primary/70'>
              {transaction?.reference_number}
            </span>
          </div>
          <div>
            <span className='font-semibold mr-2'>Amount Paid:</span>
            <span className='text-dark-primary/70'>
              {(transaction?.amount.total || 0).toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
          </div>
        </>
      }
    />
  )
}
