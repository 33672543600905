import React, { useState, useEffect } from 'react'
import { capitalizeFirstLetter } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { FaPlus } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa6'
import { ReactComponent as Back } from '../../assets/goBack.svg'
import { ReactComponent as Map } from '../../assets/map.svg'
import ProceedToGenerate from '../../components/manifests/ProceedToGenerate'
import { useNavigate } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddNewDestinationModal from '../../components/manifests/components/AddNewDestination'
import { fetchAllClosedBags } from '../../slices/closedBagSlice'
import Page from '../../containers/Page'

const CreateManifest = ({ metaTitle }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const allClosedBags = useSelector(state => state?.closeBags?.allCloseBags)
  const allHubs = useSelector(state => state?.hubs?.hubs)
  const [listBags, setListBags] = useState([])
  const [allHub, setAllHub] = useState([])

  const [bags, setBags] = useState([])
  const [expandedView, setExpandedView] = useState(false)
  const [originHub, setOriginHub] = useState(['Gbagada phase 1 Hub'])
  const [destination, setDestination] = useState('')
  const [finalDestination, setFinalDestination] = useState('')
  const [stopDestination, setStopDestination] = useState('')
  const [destinationID, setDestinationID] = useState(0)
  const [destinationCounter, setDestinationCounter] = useState([])
  const [selectedTag, setSelectedTag] = useState([])
  const [selectedTagTwo, setSelectedTagTwo] = useState([])
  const [confirmSelected, setConfirmSelected] = useState([])
  const [confirmSelectedTwo, setConfirmSelectedTwo] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [proceed, setProceed] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [bagCount, setBagCount] = useState(0)
  const [groupedBag, setGroupedBag] = useState({})
  const [mapGroupedBag, setMapGroupedBag] = useState([])
  const [bagTracker, setBagTracker] = useState([])
  const [shipmentCount, setShipmentCount] = useState([])
  const [shipmentCountTwo, setShipmentCountTwo] = useState([])
  const [ss, setSs] = useState([])
  const [selectedGroupCount, setSelectedGroupCount] = useState(0)
  const [destinationTracker, setDestinationTracker] = useState([])
  const [disabled, setDisabled] = useState(true)

  const getBagsByHubs = destination => {
    const filterByHub = allClosedBags?.filter(
      bags => bags?.destination_hub === destination
    )
    setListBags(filterByHub)
  }

  useEffect(() => {
    const hubClone = allHubs?.filter(
      item => item?.category !== 'EXPRESS_CENTRE'
    )
    setAllHub(hubClone)
  }, [allHubs])

  useEffect(() => {
    dispatch(fetchAllClosedBags())
  }, [dispatch])

  useEffect(() => {
    originHub.push(listBags[0]?.origin_hub)
    setOriginHub([...originHub])
    if (destinationCounter?.length === 0 && listBags?.length !== 0) {
      destinationCounter.push(finalDestination)
      setDestinationCounter([...destinationCounter])
      setDestinationTracker([
        ...destinationTracker,
        { destination: finalDestination, bags: listBags }
      ])
    } else if (
      destinationCounter?.length === 1 &&
      confirmSelectedTwo?.length === 0
    ) {
      destinationCounter[0] = finalDestination
      destinationTracker[0] = { destination: finalDestination, bags: listBags }
      setDestinationCounter([...destinationCounter])
      setDestinationTracker([...destinationTracker])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBags, finalDestination])

  // useEffect(() => {
  //   //setListBags(allBags)
  //   originHub.push(allBags[0]?.origin_hub)
  //   setOriginHub([...originHub])
  //   if (destinationCounter?.length === 0) {
  //     destinationCounter.push(finalDestination)
  //     setDestinationCounter([...destinationCounter])
  //     setDestinationTracker([
  //       ...destinationTracker,
  //       { destination: finalDestination, bags: allBags }
  //     ])
  //   } else if (
  //     destinationCounter?.length === 1 &&
  //     confirmSelectedTwo?.length === 0
  //   ) {
  //     destinationCounter[0] = finalDestination
  //     destinationTracker[0] = { destination: finalDestination, bags: allBags }
  //     setDestinationCounter([...destinationCounter])
  //     setDestinationTracker([...destinationTracker])
  //   }
  // }, [allBags, finalDestination])

  useEffect(() => {
    if (confirmSelectedTwo?.length !== 0) {
      //setListBags(allBags)
      originHub.push(listBags[0]?.origin_hub)
      setOriginHub([...originHub])

      if (destinationTracker.some(e => e.destination !== stopDestination)) {
        setDestinationCounter([...destinationCounter, stopDestination])
        setDestinationTracker([
          ...destinationTracker,
          { destination: stopDestination, bags: listBags }
        ])
      } else {
        alert('Destination already exist')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopDestination, mapGroupedBag])

  let selectedArr = selectedTag
  let selectedArrTwo = selectedTagTwo

  const handleChange = item => {
    setSelectedList([...selectedList, item?.tag])
    if (!selectedArr.includes(item?.tag)) {
      selectedArr.push(item?.tag)
      selectedArrTwo.push(item?.tag)
      ss.push({ tag: item?.tag, destination: item?.destination_hub })
      setSs([...ss])
      bags.push(item?.id)
      shipmentCount.push({
        tag: item?.tag,
        shipment: item?.shipments === null ? 0 : item?.shipments?.length
      })
      shipmentCountTwo.push({
        tag: item?.tag,
        shipment: item?.shipments === null ? 0 : item?.shipments?.length
      })
      setShipmentCount([...shipmentCount])
      setShipmentCountTwo([...shipmentCountTwo])
      setBags([...bags])
      setSelectedTag(selectedArr)
      setSelectedTagTwo(selectedArrTwo)
      setBagCount(bagCount + 1)
      bagTracker.push(bagCount)
      setBagTracker([...bagTracker])
    } else if (selectedTag.includes(item?.tag)) {
      const removeSelected = selectedTag?.filter(items => items !== item?.tag)
      const removeSs = ss?.filter(items => items?.tag !== item?.tag)
      const removeShipmentCount = shipmentCount?.filter(
        items => items?.tag !== item?.tag
      )
      const removeShipmentCountTwo = shipmentCountTwo?.filter(
        items => items?.tag !== item?.tag
      )

      setShipmentCount(removeShipmentCount)
      setShipmentCountTwo(removeShipmentCountTwo)
      setSs(removeSs)
      const cloneBags = bags?.filter(bag => bag !== item?.id)
      setBags(cloneBags)
      setSelectedTag(removeSelected)
      setSelectedTagTwo(removeSelected)
      setSelectedList(removeSelected)
      setBagCount(selectedList?.length - 1)
    }
  }

  const handleConfirmSelected = () => {
    if (listBags?.length !== 0 && destinationID !== 0) {
      setMapGroupedBag([
        ...mapGroupedBag,
        {
          origin: originHub[0],
          destination: destination,
          bag: bagCount,
          waybill: [...selectedTagTwo],
          shipments: shipmentCountTwo
        }
      ])
      setSelectedGroupCount(selectedGroupCount + 1)
      const removedBags = listBags.filter(
        val => !selectedList.includes(val?.tag)
      )
      setListBags(removedBags)
      setConfirmSelectedTwo([...ss])
      setConfirmSelected([...ss])
      setSelectedList([])
      setListBags([])
      setDisabled(false)
    } else {
      alert('Please select a transiting hub')
    }
  }

  const handleChooseAll = () => {
    const chooseAll = listBags?.map(list => {
      return list?.tag
    })

    setSelectedTag([...chooseAll])
    setSelectedList(chooseAll)
    setBagCount(chooseAll?.length)
  }

  const getAllHubData = hubId => {
    const filteredHub = allHub?.filter(hub => hub?.name === hubId)
    setDestination(filteredHub[0]?.name)
    setFinalDestination(filteredHub[0]?.name)
    //dispatch(fetchAllBags(`hubs/${filteredHub[0]?.id}/bags`))
    setSelectedGroupCount(0)
    setBagCount(0)
    setSelectedTagTwo([])
    setShipmentCountTwo([])
  }

  const getAllHubDataTwo = hubId => {
    const filteredHub = allHub?.filter(hub => hub?.name === hubId)
    setDestination(filteredHub[0]?.name)
    setStopDestination(filteredHub[0]?.name)
    //setDestinationID(filteredHub[0]?.id);
    //dispatch(fetchAllBags(`hubs/${filteredHub[0]?.id}/bags`))
    setSelectedGroupCount(0)
    setBagCount(0)
    setSelectedTagTwo([])
    setShipmentCountTwo([])
  }

  const handleProceed = () => {
    if (selectedGroupCount !== 0) {
      setGroupedBag({
        type: 'TM',
        destination_hub: destinationID,
        bags: bags
      })
      setTimeout(() => {
        setProceed(true)
      }, 1000)
      setSelectedGroupCount(0)
    } else {
      setProceed(true)
    }
  }

  const removeEmptyAccordion = () => {
    const newTracker = destinationTracker?.filter(
      item => item?.bags?.length !== 0 && item?.destination !== ''
    )

    setDestinationTracker(newTracker)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        {proceed === false ? (
          <div className='w-full py-8'>
            <div className='flex justify-start items-center space-x-2'>
              <Back
                onClick={() => navigate(`/manifest/tm`)}
                className='cursor-pointer'
              />
              <div className='font-bold text-3xl'>Create Transfer manifest</div>
            </div>
            {/* second container */}
            <div className='w-full px-4 py-6 bg-white mt-12 space-y-4'>
              <div className='font-bold text-1xl'>
                Shipment origin and final intended delivery location
              </div>
              <div className='w-full flex justify-between space-x-2 py-6'>
                <div className='w-[50%] space-y-4'>
                  <div className='text-sm font-medium text-[#3A3A3A]'>
                    Destination
                  </div>
                  {/* <div className="border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]">
                    {allBags?.length !== 0 ? originHub[0] : originHub[0]}
                  </div> */}

                  {ss.length !== 0 ? (
                    <select
                      className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px] mt-4'
                      onChange={e => {
                        let hubId = e.target.value
                        getBagsByHubs(hubId)
                        setDestinationID(0)
                        if (hubId !== 0) {
                          getAllHubDataTwo(hubId)
                        }
                      }}
                      onClick={() => removeEmptyAccordion()}
                    >
                      <option className='bg-white' value={0}>
                        {' '}
                        Select destination
                      </option>
                      {allHub?.map((hub, id) => (
                        <option className='bg-white' value={hub?.name} key={id}>
                          {capitalizeFirstLetter(hub?.name)} Hub,{' '}
                          {capitalizeFirstLetter(hub?.location)}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'
                      onChange={e => {
                        let hubId = e.target.value
                        getBagsByHubs(hubId)
                        setDestinationID(0)
                        setConfirmSelected([])
                        setSelectedList([])
                        setConfirmSelectedTwo([])
                        setMapGroupedBag([])
                        setSs([])
                        setSelectedTag([])
                        if (hubId !== 0) {
                          getAllHubData(hubId)
                        }
                      }}
                    >
                      <option className='bg-white' value={0}>
                        {' '}
                        Select destination
                      </option>
                      {allHub?.map((hub, id) => (
                        <option className='bg-white' value={hub?.name} key={id}>
                          {capitalizeFirstLetter(hub?.name)} Hub,{' '}
                          {capitalizeFirstLetter(hub?.location)}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className='w-[50%] space-y-4'>
                  <div className='text-sm font-medium text-[#3A3A3A]'>
                    Transiting Hub
                  </div>

                  <select
                    className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'
                    onChange={e => {
                      setDestinationID(e.target.value)
                    }}
                  >
                    <option className='bg-white' value={0}>
                      {' '}
                      Select transiting hub
                    </option>
                    {allHub
                      ?.filter(item => item?.category !== 'EXPRESS_CENTRE')
                      ?.map((hub, id) => (
                        <option className='bg-white' value={hub?.id} key={id}>
                          {capitalizeFirstLetter(hub?.name)} Hub,{' '}
                          {capitalizeFirstLetter(hub?.location)}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            {/* Edn of first block */}
            {/* Start of second block */}
            <div className='w-full px-4 py-6 bg-white mt-12 space-y-8'>
              {/* <div className="font-bold text-1xl">Add Bag(s)</div>
              <div className="text-xs text-dark-primary opacity-80">
                From the list of bag below, select and add a bag of choice to a
                stop destination
              </div> */}
              {/* start filter container */}
              <div className='w-full'>
                <div className='flex justisfy-between items-center space-x-2'></div>
              </div>
              {/* end filter container */}
              {/* start of bags selected */}
              <div className='w-full flex justify-start space-x-2'>
                <div className='w-[50%] space-y-4'>
                  <div className='text-sm font-medium text-[#3A3A3A]'>
                    Total no of bags selected
                  </div>
                  <div className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'>
                    {bagCount >= 0 ? bagCount : '0'}
                  </div>
                </div>
              </div>
              {/* start of bags selected */}
              {/* start of choose all */}
              <div className='flex justify-start text-main-primary'>
                <div
                  className='font-semibold cursor-pointer'
                  onClick={handleChooseAll}
                >
                  Choose All
                </div>
              </div>
              {/* end of choose all */}
              <div className='w-full flex justify-between space-x-3'>
                {/* start of list of bags container */}
                <div className='w-[50%]'>
                  <div className='h-[48px] bg-main-primary text-white flex flex-col justify-center pl-4'>
                    List Of Bags
                  </div>
                  <div className='h-[342px] border-[#BDBDBD] border-[1px]'>
                    {listBags?.length === 0 ? (
                      <div className='flex space-x-4 items-center p-3'>
                        No bags to select
                      </div>
                    ) : (
                      listBags?.map((bag, id) => (
                        <div
                          className='flex space-x-4 items-center hover:bg-main-hover p-3 cursor-pointer'
                          key={id}
                          onClick={() => {
                            //setBagTag(bag);
                            handleChange(bag)
                          }}
                        >
                          {selectedList?.includes(bag?.tag) ? (
                            <FaCheck className='text-green-500' />
                          ) : (
                            <FaPlus />
                          )}

                          <div className={`text-sm cursor-pointer"`}>
                            {bag?.tag}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                {/* start of accordion container */}
                <div className='w-[50%] flex flex-col justify-start'>
                  {destinationCounter?.length !== 0 &&
                    (stopDestination || finalDestination !== '') &&
                    _.sortedUniqBy(destinationTracker, e => e.destination)?.map(
                      (dest, id) => (
                        <Accordion
                          onChange={(e, expanded) => {
                            if (expanded) {
                              setExpandedView(true)
                              setDestination(dest?.destination)
                            } else {
                              setExpandedView(false)
                              setDestination('')
                            }
                          }}
                          key={id}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <div className='flex justify-start items-center space-x-4'>
                              <Map />
                              {/* <AiOutlineDelete
                              className="text-primary"
                              onClick={() => {
                                deleteAccordion(dest?.destination);
                              }}
                            /> */}

                              <div className='text-sm font-medium'>
                                {originHub[0] === dest?.destination
                                  ? ''
                                  : originHub[0] + ' - '}{' '}
                                {capitalizeFirstLetter(dest?.destination)} Hub
                              </div>
                            </div>
                          </AccordionSummary>
                          <div className='h-[230px] border-[#BDBDBD] border-[1px] overflow-y-scroll'>
                            {confirmSelected?.map((confirm, id) => (
                              <div key={id}>
                                {expandedView === true &&
                                  confirm?.destination === destination && (
                                    <div className='bg-[#F9F9F9] w-full h-[30px] flex justify-between items-center px-4 py-6 border-[1px] border-[#BDBDBD] text-dark-primary text-opacity-60'>
                                      {confirm?.tag}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        </Accordion>
                      )
                    )}

                  <div className='flex justify-end mt-8'>
                    {destinationCounter?.length !== 0 && (
                      // <span
                      //   className="flex space-x-2 justify-center items-center w-[130px] h-[50px] bg-main-primary text-white rounded-[40px] cursor-pointer font-semibold"
                      //   onClick={handleProceed}
                      // >
                      //   <p className="text-xs">Proceed</p>
                      // </span>

                      <button
                        className={`btn ${
                          disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
                        } btn-rounded`}
                        disabled={disabled}
                        onClick={handleProceed}
                      >
                        Proceed
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* end of list of bags and accordion */}
              {/* start of add new stop and confirm bag */}
              <div className='w-[50%] flex justify-start items-center space-x-4'>
                {/* <button
                  className="w-[45%] flex justify-center space-x-2 items-center h-[50px] bg-g-500 text-[rgba(51, 51, 51, 1)] rounded-[100px] cursor-pointer font-semibold"
                  onClick={() => {
                    if (confirmSelectedTwo?.length !== 0) {
                      setOpenModal(true);
                      removeEmptyAccordion();
                    }
                  }}
                >
                  <AddStop />
                  <p className="text-xs">Add new stop destination</p>
                </button> */}
                <div
                  className='text-main-primary font-semibold text-sm cursor-pointer'
                  onClick={handleConfirmSelected}
                >
                  Confirm Bag Selection
                </div>
              </div>
            </div>
            {/* End of second block */}
          </div>
        ) : (
          <ProceedToGenerate
            setProceed={setProceed}
            groupedBag={groupedBag}
            mapGroupedBag={mapGroupedBag}
            setDisabled={setDisabled}
          />
        )}
        <AddNewDestinationModal
          allHubs={allHub}
          setOpenModal={setOpenModal}
          openModal={openModal}
          getAllHubData={getAllHubDataTwo}
        />
      </Page.Body>
    </Page>
  )
}

export default CreateManifest
