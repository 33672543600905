import React, { useState, useEffect, useMemo } from 'react'
import AppForm from '../../../components/globals/Form/AppForm.jsx'
import AppFormField from '../../../components/globals/Form/AppFormField.jsx'
import AppFormSelectField from '../../../components/globals/Form/AppFormSelectField.jsx'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { fetchBranches, fetchSystems } from '../../../slices/orgsSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import {
  GenderLookup,
  EmploymentType,
  Gender,
  EmploymentLookup
} from '../../../constants/index.js'
import accountsApi from '../../../api/accounts.js'
import useToast from '../../../hooks/useToast.js'
import { useNavigate } from 'react-router-dom'
import { fetchRoles, fetchGroups } from '../../../slices/extraDataSlice.js'
import SuccessModal from '../../../components/organization/SuccessModal.jsx'

const personalDetailsValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  middle_name: Yup.string(),
  last_name: Yup.string().required('Last name is required'),
  start_date: Yup.string().required('Date of Birth is required'),
  date_of_birth: Yup.string().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  branch: Yup.string().required('Branch is required'),
  employment_type: Yup.string().required('Employment type is required'),
  roles: Yup.string().required('Role is required'),
  groups: Yup.string().required('Group is required'),
  systems: Yup.string().required('System access is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required')
})

function EmployeeForm ({ editId }) {
  const [successModal, setSuccessModal] = useState(false)
  const dispatch = useDispatch()
  const orgs = useSelector(state => state.orgs)
  const [loading, setLoading] = useState(false)

  const genders = ['Male', 'Female']
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    start_date: '',
    gender: '',
    branch: '',
    employment_type: '',
    roles: '',
    groups: '',
    systems: '',
    email: '',
    password: ''
  })

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchBranches())
    dispatch(fetchSystems())
    dispatch(fetchRoles())
    dispatch(fetchGroups())
  }, [dispatch])

  const toast = useToast()

  const extraData = useSelector(state => state.extra)

  const branches = useMemo(() => orgs.branches, [orgs.branches])
  const systems = useMemo(() => orgs.systems, [orgs.systems])

  const employment_type = [
    'Full Time',
    'Part Time',
    'Contract',
    'Internship',
    'Industrial Training'
  ]

  const orgGroups = useMemo(() => extraData?.groups, [extraData?.groups])
  const orgRoles = useMemo(() => extraData?.roles, [extraData?.roles])

  const rolesData = orgGroups?.map(role => role)
  const groupsData = orgRoles?.map(group => group)
  const system_access = systems?.map(system => system)

  const getGenderDisplayByValue = value => {
    return Object.keys(GenderLookup).find(key => GenderLookup[key] === value)
  }
  const getEmploymentDisplayByValue = value => {
    return Object.keys(EmploymentLookup).find(
      key => EmploymentLookup[key] === value
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      if (editId) {
        try {
          const response = await accountsApi.getSingleAccount(editId)
          const employee = response.data.payload

          setInitialValues({
            first_name: employee.employee.first_name,
            middle_name: '',
            last_name: employee.employee.last_name,
            date_of_birth: '',
            start_date: '',
            gender: getGenderDisplayByValue(employee.employee.gender),
            branch: employee.employee.branch,
            employment_type: getEmploymentDisplayByValue(
              employee.employee.employment_type
            ),
            roles: employee.employee.roles.join(','),
            groups: employee.employee.groups.join(','),
            systems: employee.employee.systems.join(','),
            email: employee.user.email,
            password: ''
          })
        } catch (error) {
          console.error('Failed to fetch branch:', error)
        }
      }
    }

    fetchData()
  }, [editId])

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)
    setLoading(true)
    const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000
    const payload = {
      employee_data: {
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        employment_type:
          EmploymentType[values.employment_type.trim().toUpperCase()],
        branch: parseInt(values.branch, 10),
        gender: GenderLookup[values.gender],
        date_of_birth: values.date_date,
        start_date: values.start_date,
        systems: values.systems ? [values.systems] : [],
        roles: [parseInt(values.roles, 10)],
        groups: [parseInt(values.groups, 10)]
      },
      user_data: {
        email: values.email,
        password: values.password,
        phone_no: `+234${randomNumber}`
      }
    }
    let response
    if (editId) {
      response = await accountsApi.updateAccount(editId, payload)
    } else {
      response = await accountsApi.createAccount(payload)
    }
    setLoading(false)
    if (!response.ok) {
      const apiError = response.error || 'Unable to create branch'
      toast(apiError)
      return
    }
    setSuccessModal(true)
    setLoading(false)
  }
  const handleSuccessModalAction = () => {
    setSuccessModal(false)
    navigate('/organization/internal')
  }

  return (
    <div>
      {successModal && (
        <SuccessModal
          isOpen={successModal}
          title='You’ve successfully created an employee'
          onClose={handleSuccessModalAction}
        />
      )}

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={personalDetailsValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {formik => {
          const { isSubmitting, handleSubmit, isValid } = formik

          return (
            <div>
              <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
                <h1 className='text-xl font-semibold'>Personal Details</h1>
                <AppForm id='personal_details_form' padding='0'>
                  <div className='flex flex-row gap-6 pt-4'>
                    <AppFormField
                      name='first_name'
                      title='First Name'
                      placeholder='First Name'
                    />
                    <AppFormField
                      name='middle_name'
                      title='Middle Name'
                      placeholder=''
                    />
                  </div>
                  <div className='flex flex-row gap-6 pt-4'>
                    <AppFormField
                      name='last_name'
                      title='Last Name'
                      placeholder='Last Name'
                    />
                    <AppFormField
                      title='Date of Birth'
                      name='date_of_birth'
                      type='date'
                    />
                  </div>
                  <div className='flex flex-row gap-6 pt-4'>
                    <AppFormSelectField name='gender' title='Gender'>
                      <option value=''>Select gender</option>
                      {genders?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </AppFormSelectField>
                    <AppFormField
                      title='Start Date'
                      name='start_date'
                      type='date'
                    />
                  </div>
                  <div className='flex flex-row gap-6 pt-4 w-1/2'>
                    <AppFormSelectField name='branch' title='Branch'>
                      <option value=''>Select branch</option>
                      {branches?.map((branch, ind) => (
                        <option key={ind} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </div>
                </AppForm>
              </div>
              <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
                <h1 className='text-xl font-semibold'>
                  Employment Details & Roles
                </h1>
                <AppForm id='employment_details_form' padding='0'>
                  <div className='flex flex-row gap-6 pt-4'>
                    <AppFormSelectField
                      name='employment_type'
                      title='Employee Type'
                    >
                      <option value=''>Select Employment Type</option>
                      {employment_type?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </AppFormSelectField>
                    <AppFormSelectField name='roles' title='Roles'>
                      <option value=''>Select role</option>
                      {groupsData?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </div>

                  <div className='flex flex-row gap-6 pt-4 w-1/2'>
                    <AppFormSelectField name='groups' title='Groups'>
                      <option value=''>Select Group</option>
                      {rolesData?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </div>
                </AppForm>
              </div>
              <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
                <h1 className='text-xl font-semibold'>System Access</h1>
                <AppForm id='system_access_form' padding='0'>
                  <div className='flex flex-row gap-6 pt-4 w-1/2'>
                    <AppFormSelectField name='systems' title='System Access'>
                      <option value=''>Select system access</option>
                      {system_access?.map((item, index) => (
                        <option key={index} value={item.sid}>
                          {item.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </div>
                </AppForm>
              </div>
              <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
                <h1 className='text-xl font-semibold'>User Credentials</h1>
                <AppForm id='user_credentials_form' padding='0'>
                  <div className='flex flex-row gap-6 pt-4 '>
                    <AppFormField
                      name='email'
                      title='Email'
                      placeholder='Email'
                      type='email'
                    />
                    <AppFormField
                      title='Password'
                      name='password'
                      type='password'
                      toggleVisibility={true}
                    />
                  </div>
                </AppForm>
              </div>
              <div className='flex items-center justify-end'>
                <button
                  className={`btn mt-10 ${
                    isValid ? 'btn-primary' : 'btn-neutral btn-disabled'
                  }`}
                  disabled={!isValid || isSubmitting}
                  type='button' // Changed from 'submit' to 'button'
                  onClick={handleSubmit} // Trigger Formik submit
                >
                  {loading ? (
                    <CircularProgress size={28} color='inherit' />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default EmployeeForm
