import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useToast from '../../hooks/useToast'
import { ReactComponent as Truck } from '../../assets/truck.svg'
import { IoIosSearch } from 'react-icons/io'
import manifestApi from '../../api/manifest'
import { fetchRiders } from '../../slices/ridersSlice'
import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { BiSearch } from 'react-icons/bi'
import { Loader } from '../globals'
import { CircularProgress } from '@mui/material'
import { MdCheck } from 'react-icons/md'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AssignShipmentToDriver ({ isOpen, onClose, shipment }) {
  const dispatch = useDispatch()
  const ridersData = useSelector(state => state?.riders?.riders)
  const toast = useToast()
  const [riderId, setRiderId] = useState('')
  const [riderName, setRiderName] = useState('')
  const [checkSelected, setCheckSelected] = useState('')
  const [loading2, setLoading] = useState(false)
  const [riderSearchedVal, setRiderSearchedVal] = useState('')

  useEffect(() => {
    dispatch(fetchRiders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const riders = useMemo(() => {
    if (ridersData?.length) {
      if (riderSearchedVal) {
        return ridersData?.filter(item =>
          item?.name.toLowerCase().includes(riderSearchedVal.toLowerCase())
        )
      }

      return ridersData
    }

    return []
  }, [riderSearchedVal, ridersData])

  const handleSelectRider = rider => {
    setRiderId(rider.id)
    setRiderName(rider.name)
    setCheckSelected(rider.id)
    localStorage.setItem('riderName', rider.name)
  }

  const handleAssign = async () => {
    setLoading(true)
    const result = await manifestApi.assignManifest(shipment.id, {
      manifest_type: 'CM',
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
      setLoading(false)
    } else {
      toast(result?.problem, 'error')
      setLoading(false)
    }
  }

  const disabled = useMemo(() => {
    return true
  }, [])

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <button
        className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
        onClick={onClose}
      >
        <LiaTimesSolid size={24} />
      </button>

      <h3 className='text-lg font-medium text-center py-2 mb-4'>
        Assign Shipment "{shipment.tracking_id}" to Astro
      </h3>

      <div className='w-fit flex flex-row divide-x search__group h-[2.5rem] border items-stretch text-dark-primary rounded-xl mx-auto overflow-hidden'>
        <div className='flex items-center w-64'>
          <input
            type='text'
            placeholder='Search for captain or astronaut'
            className='bg-white h-full w-full p-2 placeholder:text-g-600 text-sm '
            onChange={({ target }) => setRiderSearchedVal(target.value)}
          />
        </div>
        <div className='bg-[#E5E4E5] p-1 flex items-center justify-center w-[2.85rem]'>
          <BiSearch size={18} color='#333333' />
        </div>
      </div>

      <div className='overflow-y-auto min-h-40 max-h-60'>
        {!riders ? (
          <div className='w-full flex items-center justify-center'>
            <Loader page={false} size='md' />
          </div>
        ) : (
          <ul>
            {riders.map(rider => (
              <li
                className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                  checkSelected === rider?.id ? 'bg-main-hover' : 'bg-white'
                }`}
                onClick={handleSelectRider}
              >
                <span className='block w-4 h-4'>
                  <Truck width={16} h={16} />
                </span>
                <p className={`truncate w-[calc(100%-1rem)] ${checkSelected === rider?.id}`}>{rider.name}</p>
                {checkSelected === rider?.id && (
                  <span className='pointer-events-none ml-auto flex items-center'>
                    <MdCheck size={14} color='#fe6802' />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className='w-full flex justify-end mt-12'>
        <button
          className={`btn min-w-32 btn-rounded ${
            disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
          }`}
          disabled={disabled}
          onClick={handleAssign}
        >
          {loading2 ? <CircularProgress size={24} color='inherit' /> : 'Assign'}
        </button>
      </div>
    </Modal>
  )
}
