import React, { useEffect, useState } from 'react'
import { FiCopy } from 'react-icons/fi'

export default function CopyText ({ text }) {
  const [showCopied, setShowCopied] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowCopied(false)
    }, 3000)
  }, [showCopied])

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setShowCopied(true)
    }, 500)
  }

  return (
    <div className='relative'>
      <button
        className='hover:scale-110 active:scale-95 transition-all'
        onClick={handleCopy}
      >
        <FiCopy size={14} color='#FF4D00' />
      </button>
      <span
        className={`bg-[#20be79e6] text-white py-1.5 px-2 text-xs rounded-md absolute bottom-full -left-1/2 origin-bottom-left duration-100 ${
          showCopied ? 'scale-1 opacity-100' : 'scale-0 opacity-0'
        }`}
      >
        Copied!
      </span>
    </div>
  )
}
