import { useEffect, useMemo, useState } from 'react'
import FilterButton from '../../components/globals/filter/FilterButton'
import Page from '../../containers/Page'
import TotalCard from '../../components/dashboard/TotalCard'
import BookingsCard from '../../components/dashboard/BookingsCard'
import ShipmentStatuses from '../../components/dashboard/ShipmentStatuses'
import ShipmentsBarChart from '../../components/dashboard/ShipmentsBarChart'
import ShipmentTypePieChart from '../../components/dashboard/ShipmentTypePieChart'
import OrderStatus from '../../components/dashboard/OrderStatusCard'
import RecentOrders from '../../components/dashboard/RecentOrders'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboard } from '../../slices/dashboardSlice'
import { Loader } from '../../components/globals'
import { fetchOrders } from '../../slices/ordersSlice'
import DashboardFilter from '../../components/dashboard/DashboardFilter'
import { fetchBranches } from '../../slices/orgsSlice'
import { initializeDateRange } from '../../utils'

export default function CustomerSupportDashboard ({ metaTitle }) {
  const dashboardData = useSelector(state => state.dashboard)

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const getQueryParams = filter => {
    const query = {}

    for (let key in filter) {
      if (key === 'start_month' || key === 'end_month') {
        if (filter[key]) {
          query[key] = filter[key].value
        }
      } else if (filter[key]) {
        query[key] = filter[key]
      }
    }

    return new URLSearchParams(query).toString()
  }

  const [dateRange, setDateRange] = useState(initializeDateRange())

  useEffect(() => {
    dispatch(fetchOrders())
    dispatch(fetchBranches())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formatPayment = item => {
    switch (item) {
      case 'Transfer':
        return 'TRF'
      case 'Cash':
        return 'CASH'
      case 'POS':
        return 'POS'
      case 'Pay later':
        return 'PAY_LATER'
      default:
        break
    }
  }

  const formatType = item => {
    switch (item) {
      case 'Local':
        return 'LC'
      case 'International':
        return `IN`
      default:
        return ''
    }
  }

  const formatTpl = item => {
    switch (item) {
      case 'FedEx':
        return 'FIE'
      case 'RedStar Express':
        return 'RSE'
      default:
        return item
    }
  }

  useEffect(() => {
    if (dashboardData.filter) {
      const cardFilterParams = {
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date,
        branch_name: dashboardData.filter.branch_name,
        shipmentType: formatType(dashboardData.filter.shipmentType),
        payment_method: formatPayment(dashboardData.filter.payment),
        tpl_service: formatTpl(dashboardData?.filter.tpl)
      }
      dispatch(fetchDashboard(`?${getQueryParams(cardFilterParams)}`))
    } else {
      dispatch(fetchDashboard())
    }
  }, [dashboardData.filter])

  const showFilter = () => {
    setIsFilterOpen(true)
  }

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  const totals = useMemo(() => {
    if (dashboardData.data) {
      return [
        {
          title: 'Total shipments',
          amount: dashboardData?.data?.shipments?.total_shipments
        },
        {
          title: 'Total Number of Customers',
          amount: dashboardData.data.total_customers
        },
        {
          title: 'Total Number of Partners',
          amount: dashboardData.data.total_partners
        }
      ]
    }
  }, [dashboardData.data])

  const customerBookings = useMemo(() => {
    if (dashboardData.data) {
      return {
        branch_bookings:
          dashboardData.data.customer_bookings_data?.branch_booking,
        bookings: dashboardData.data.customer_bookings_data?.booking,
        total_bookings:
          dashboardData.data.customer_bookings_data?.total_customer_bookings
      }
    }
  }, [dashboardData.data])

  const partnerBookings = useMemo(() => {
    if (dashboardData.data) {
      return {
        branch_bookings:
          dashboardData.data.partner_bookings_data?.branch_booking,
        bookings: dashboardData.data.partner_bookings_data?.booking,
        total_bookings:
          dashboardData.data.partner_bookings_data?.total_partner_bookings
      }
    }
  }, [dashboardData.data])

  const shipmentsStatus = useMemo(() => {
    if (dashboardData?.data) {
      return {
        pending: dashboardData?.data
          ? dashboardData?.data?.shipments?.pending
          : '--',
        in_transit: dashboardData?.data
          ? dashboardData?.data?.shipments['in-transit']
          : '--',
        out_for_delivery: dashboardData?.data
          ? dashboardData?.data?.shipments['out-for-delivery']
          : '--',
        exception: dashboardData?.data
          ? dashboardData?.data?.shipments.exception
          : '--',
        delivered: dashboardData?.data
          ? dashboardData?.data.shipments?.delivered
          : '--',
        voided: dashboardData?.data
          ? dashboardData.data?.shipments?.voided
          : '--',
        available_for_pickup_by_customer: dashboardData?.data
          ? dashboardData.data.shipments['available-for-pickup-by-customer']
          : '--'
      }
    }
  }, [dashboardData.data])

  const shipmentsTypes = useMemo(() => {
    if (dashboardData.data) {
      return {
        LC: dashboardData.data.shipments?.local_shipments,
        IN: dashboardData.data.shipments?.international_shipments
      }
    }
  }, [dashboardData.data])

  const orderStat = useMemo(() => {
    if (dashboardData.data) {
      return dashboardData.data.order_stat
    }
  }, [dashboardData.data])

  const shipmentsChartData = useMemo(() => {
    if (dashboardData.data) {
      return {
        total_shipments: dashboardData.data.shipments.total_shipments,
        monthly_shipments: dashboardData.data.shipments.monthly_shipments
      }
    }
  }, [dashboardData.data])

  const isLoading = useMemo(() => {
    return (
      loading ||
      !totals ||
      !customerBookings ||
      !partnerBookings ||
      !shipmentsStatus ||
      !shipmentsTypes ||
      !orderStat ||
      !shipmentsChartData
    )
  }, [
    customerBookings,
    loading,
    orderStat,
    partnerBookings,
    shipmentsChartData,
    shipmentsStatus,
    shipmentsTypes,
    totals
  ])

  const onApplyFilter = () => setLoading(true)

  const closeFilter = () => setIsFilterOpen(false)

  const onFilterDelete = () => setLoading(true)

  return (
    <Page metaTitle={metaTitle} noPadding>
      <Page.Header title={'Dashboard'}>
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isFilterOpen && (
          <DashboardFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            onApplyFilter={onApplyFilter}
            isBranchSpecific={false}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        <DashboardFilter.Description onDelete={onFilterDelete} />
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className='flex flex-col gap-4 pt-4'>
              <div className='grid grid-cols-12 gap-4'>
                {totals.map((total, id) => (
                  <div className='col-span-12 md:col-span-4' key={id}>
                    <TotalCard title={total.title} total={total.amount} />
                  </div>
                ))}
              </div>
              <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 md:col-span-4'>
                  <BookingsCard type='customer' data={customerBookings} />
                </div>
                <div className='col-span-12 md:col-span-4'>
                  <BookingsCard type='partner' data={partnerBookings} />
                </div>
                <div className='col-span-12 md:col-span-4'>
                  <ShipmentStatuses data={shipmentsStatus} />
                </div>
              </div>

              <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 lg:col-span-6'>
                  <ShipmentsBarChart data={shipmentsChartData} />
                </div>
                <div className='col-span-12 lg:col-span-6 grid grid-cols-2 gap-4'>
                  <div className='col-span-2 md:col-span-1'>
                    <ShipmentTypePieChart data={shipmentsTypes} />
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <OrderStatus data={orderStat} />
                  </div>
                </div>
              </div>

              <div>
                <RecentOrders />
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}
