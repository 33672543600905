import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError } from '../utils'

const initialState = {
  dataExceptions: []
}

export const fetchExceptions = createAsyncThunk(
  'exceptions/fetch',
  async () => {
    const response = await manifestApi.getExceptions()

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return response.data
  }
)

const exceptionsSlice = createSlice({
  name: 'exceptions',
  initialState,
  reducers: {
    // updateData(state, { payload }) {
    //   state.data = payload;
    // },
  },
  extraReducers: builder => {
    builder.addCase(fetchExceptions.fulfilled, (state, action) => {
      state.dataExceptions = action?.payload?.payload
    })
  }
})

// export const manifestActions = manifestSlice.actions

export default exceptionsSlice.reducer
