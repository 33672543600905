import { SuccessPopup } from '../globals'

export default function PaymentSuccessful ({
  isOpen,
  onClose,
  amount,
  agentName
}) {
  return (
    <SuccessPopup
      isOpen={isOpen}
      title='Payment Successful!'
      description={`You have successfully made a payment of
                  ${amount.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })} for the commission earned
                  by ${agentName}.`}
      onClose={onClose}
    />
  )
}
