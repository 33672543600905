import bookingClient from './clients/bookingClient'

const getBanks = async () => {
  const response = await bookingClient.get(`salesforce/banks/`)

  return response
}

const resolveAccountNumber = async body => {
  const response = await bookingClient.post(`salesforce/resolve_banks/`, body)

  return response
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBanks,
  resolveAccountNumber
}
