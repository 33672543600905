import { useState } from 'react'
import noDataIcon from '../../assets/no-data.webp'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import Checkbox from './Checkbox'
import Unselect from './Unselect'
import { twMerge } from 'tailwind-merge'
import Button from './Button'

function OrganizationTable ({
  id,
  headers,
  data,
  rowAction,
  emptyDataText,
  emptyDataAction = null,
  emptyDataActionText,
  withCheckbox,
  withMenu,
  onSelectRow,
  rowMenuItems
}) {
  // const data = []
  const [selectedAll, setSelectedAll] = useState(false)
  const [checkedList, setCheckedList] = useState({})

  const toggleSelectAll = () => {
    const newValue = !selectedAll
    setSelectedAll(newValue)
    if (newValue) {
      selectAll()
    } else {
      deSelectAll()
    }
  }

  const toggleItemCheck = (value, s_n) => {
    const list = { ...checkedList }
    if (value) {
      list[s_n] = true
    } else {
      delete list[s_n]
    }

    setCheckedList(list)
    onSelectRow(list)
  }

  const selectAll = () => {
    const list = data.reduce((acc, item) => {
      return { ...acc, [item.s_n]: true }
    }, {})
    setCheckedList(list)
    onSelectRow(list)
  }

  const deSelectAll = () => {
    setCheckedList({})
    onSelectRow({})
  }

  return (
    <div>
      <>
        <div className='data-table-wrapper'>
          {data && (
            <>
              {data.length > 0 && (
                <table id={id} className='table--hover table--striped'>
                  <thead>
                    <tr>
                      {withCheckbox && (
                        <th>
                          {Object.keys(checkedList).length && !selectedAll ? (
                            <Unselect onClick={deSelectAll} />
                          ) : (
                            <Checkbox
                              checked={selectedAll}
                              onChange={toggleSelectAll}
                            />
                          )}
                        </th>
                      )}
                      {headers.map((item, index) => (
                        <th key={index}>{item}</th>
                      ))}
                      {withMenu && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, rowIndex) => (
                      <tr key={rowIndex} onClick={() => rowAction(row)}>
                        {withCheckbox && (
                          <td>
                            <span onClick={e => e.stopPropagation()}>
                              <Checkbox
                                checked={!!checkedList[row.s_n]}
                                onChange={value =>
                                  toggleItemCheck(value, row.s_n)
                                }
                              />
                            </span>
                          </td>
                        )}
                        {headers.map((column, columnIndex) => (
                          <td key={columnIndex} className='capitalize'>
                            {row[column] || '—'}{' '}
                          </td>
                        ))}
                        {withMenu && (
                          <td>
                            <OrganizationTable.RowMenu
                              row={row}
                              menutItems={rowMenuItems}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!data.length && (
                <div className='py-14 w-full flex flex-col gap-2 items-center justify-center mx-auto'>
                  <img
                    src={noDataIcon}
                    className='w-40 h-40 object-contain'
                    alt='no data icon'
                  />
                  <p className='no_data_description_text'>{emptyDataText}</p>
                  {emptyDataAction && (
                    <div className='mt-4'>
                      <Button
                        text={emptyDataActionText}
                        onClick={emptyDataAction}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </>
      {/* // )} */}
    </div>
  )
}

export default OrganizationTable

OrganizationTable.RowMenu = function TableRowMenu ({ row, menutItems }) {
  return (
    <>
      <div>
        <div className='dropdown dropdown-end text-sm'>
          <div tabIndex={0} role='button'>
            <button
              className='flex items-center bg-transparent hover:bg-g-100 focus:bg-g-100 rounded-full p-1.5'
              onClick={e => e.stopPropagation()}
            >
              <HiOutlineDotsVertical size={16} />
            </button>
          </div>
          <div className='dropdown-content z-[1] menu mt-2 bg-white rounded-md w-52 shadow-lg ring-1 ring-black/5 text-sm py-2'>
            {menutItems.map((item, ind) => {
              return (
                <div key={ind}>
                  <button
                    className={twMerge(
                      `flex w-full items-center gap-2 py-1.5 px-3 ${
                        item.color === 'danger'
                          ? 'hover:bg-error/20 hover:text-error'
                          : 'hover:bg-main-hover hover:text-main-primary-2'
                      }`
                    )}
                    onClick={e => {
                      e.stopPropagation()
                      item.action(row)
                    }}
                  >
                    <item.icon size={16} />
                    {item.name}
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
