import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError } from '../utils'

const initialState = {
  data: null,
  error: null
}

export const fetchPackageDimensions = createAsyncThunk(
  'packageDimensions/fetch',
  async () => {
    const response = await ordersApi.getPackageDimensions()

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      type: 'success',
      data: response.data.payload
    }
  }
)

const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPackageDimensions.fulfilled, (state, action) => {
      if (action.payload.type === 'success') {
        state.data = action.payload.data?.sort((a, b) => a.weight - b.weight)
        state.error = null
      } else {
        state.error = action.payload.error
        state.data = null
      }
    })
  }
})

export const dimensionsActions = dimensionsSlice.actions

export default dimensionsSlice.reducer
