import { useMemo, useState } from 'react'
import Filter from '../../globals/filter/Filter'
import useCountry from '../../../hooks/useCountry'
import nigerianStates from '../../../fixtures/states'
import { State } from 'country-state-city'

export default function ExternalOrganizationFilter ({
  isOpen,
  onClose,
  filter,
  setFilter
}) {
  const [selectedFilter, setSelectedFilter] = useState(filter)

  const Country = useCountry()

  const countryOptions = useMemo(() => {
    return Country.getAllCountries().map((country, index) => {
      return { name: country.name, value: country.isoCode }
    })
  }, [Country])

  const stateOptions = useMemo(() => {
    let states = []
    if (selectedFilter.code !== 'NG') {
      states = State.getStatesOfCountry(selectedFilter.code)
    } else {
      states = nigerianStates
    }
    return states.map(state => ({
      name: state.name,
      value: state.isoCode
    }))
  }, [selectedFilter.code])

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  const applyFilters = () => {
    setFilter(selectedFilter)
    onClose()
  }

  const onCountryChange = value => {
    setSelectedFilter(state => ({ ...state, code: value }))
  }

  const onStateChange = value => {
    setSelectedFilter(state => ({ ...state, state: value }))
  }

  return (
    <Filter isOpen={isOpen} onCancel={onClose}>
      <Filter.SelectDropdown
        title='Country'
        value={selectedFilter.code}
        placeholder='Select country'
        options={countryOptions}
        onChange={onCountryChange}
      />
      <Filter.SelectDropdown
        title='State'
        value={selectedFilter.state}
        placeholder='Select state'
        options={stateOptions}
        onChange={onStateChange}
      />

      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />
    </Filter>
  )
}
