import { IoMdAdd } from 'react-icons/io'
import OrderItem from './OrderItem'
import { FieldArray, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { scrollToTarget } from '../../utils'

export default function OrderItems ({
  container,
  shipmentType,
  packageType,
  courier,
  defaultItemsOpen = true
}) {
  const { values, errors } = useFormikContext()

  const totalItemsValue = useMemo(() => {
    return values.items.reduce((acc, curr) => {
      if (curr.price && curr.quantity) {
        return acc + Number(curr.price) * Number(curr.quantity)
      }
      return acc
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.items)])

  const totalItemsWeight = useMemo(() => {
    return values.items.reduce((acc, curr) => {
      if (curr.weight && curr.quantity) {
        return acc + Number(curr.weight) * Number(curr.quantity)
      }
      return acc
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.items)])

  const defaultItem = (() => {
    const item = {
      name: '',
      unitMeasurement: '',
      quantity: '',
      price: ''
    }

    if (shipmentType === 'IN') {
      item.hsCode = ''
      item.manufacturerCountry = ''
      item.weight = ''
    }

    return item
  })()

  return (
    <section id='items-section'>
      <FieldArray name='items'>
        {arrayHelpers => {
          const onAdd = () => {
            arrayHelpers.push(defaultItem)
            scrollToTarget('.items--last-item', container)
          }

          return (
            <div>
              <div className='flex items-center justify-between bg-gray-300 p-2 md:p-4 w-full'>
                <h1 className='font-bold text-xl text-left'>Items</h1>
                <div className='flex items-center justify-end gap-2'>
                  {shipmentType === 'IN' && (
                    <>
                      <span className='text-sm'>
                        {totalItemsWeight.toLocaleString()}kg
                      </span>
                      •
                    </>
                  )}
                  <span className='text-sm'>
                    {totalItemsValue.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN'
                    })}
                  </span>
                  <button
                    onClick={onAdd}
                    type='button'
                    className='btn btn-sm btn-ghost text-dark-primary gap-1 items-center'
                  >
                    <IoMdAdd size={16} color='#333333' />
                    <span className='hidden md:block'>Add</span>
                  </button>
                </div>
              </div>

              <div className='px-4 md:px-8'>
                {values.items.map((item, index) => {
                  const itemsError = errors.items ? errors.items[index] : null

                  return (
                    <OrderItem
                      key={index}
                      index={index}
                      item={item}
                      isLast={index === values.items.length - 1}
                      onAdd={onAdd}
                      onRemove={() => arrayHelpers.remove(index)}
                      shipmentType={shipmentType}
                      courier={courier}
                      defaultOpen={defaultItemsOpen || !!itemsError}
                      packageType={packageType}
                    />
                  )
                })}
              </div>
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
