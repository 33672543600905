import { CircularProgress } from '@mui/material'
import Modal from 'react-modal'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ConfirmSaveOrder ({
  isOpen,
  onConfirm,
  onDecline,
  type = 'new',
  onClose,
  isSaving
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div className='bg-white flex flex-col items-center gap-5'>
        <div className='text-center'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            Save & Exit
          </h3>
          <p>
            Do you want to {type === 'new' ? 'save' : 'save changes to'} this
            order before exiting the flow?
          </p>
        </div>
        <div className='flex w-full justify-between items-center'>
          <button className='btn btn-primary' onClick={onConfirm}>
            {isSaving ? (
              <>
                Saving
                <CircularProgress
                  style={{ marginLeft: '0.5rem' }}
                  size={18}
                  color='inherit'
                />
              </>
            ) : (
              'Yes, save'
            )}
          </button>
          <button className='btn btn-secondary' onClick={onDecline}>
            No, don't save
          </button>
        </div>
      </div>
    </Modal>
  )
}
