import { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../globals/Table'
import Tabs from '../../globals/Tabs'
import { IoMdAdd } from 'react-icons/io'
import { MdOutlineFileDownload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEcommerce } from '../../../slices/customersSlice'
import Pagination from '../../globals/pagination/ServerPagination'
import SearchResultsDescription from '../../globals/Search/SearchResultsDescription'
import FilterTag from '../../globals/filter/FilterTag'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import { AiOutlineDelete } from 'react-icons/ai'
import ConfirmDelete from '../../orders/confirmDelete'
import customerApi from '../../../api/customer'
import useToast from '../../../hooks/useToast'
import { HiOutlinePencil } from 'react-icons/hi2'

const ecommerceHeader = [
  'Contact Name',
  'Company Name',
  'Phone',
  'Email',
  'State',
  'Country'
]

export default function Ecommerce ({
  tabs,
  onSelectTab,
  activeTab,
  queryParams,
  setQueryParams,
  searchBy,
  searchValue,
  serverSearch,
  onCloseServerSearch,
  filterTags,
  onFilterDelete,
  rowAction,
  onEdit
}) {
  const ecommerce = useSelector(state => state.customers.ecommerce)

  const [serializedData, setSerializedData] = useState(null)
  const [checkedList, setCheckedList] = useState([])
  const [deletePrompt, setDeletePrompt] = useState({
    isOpen: false
  })
  const [isDeleting, setDeleting] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const loadData = useCallback(() => {
    dispatch(fetchEcommerce(queryParams))
  }, [dispatch, queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadData()
  }, [loadData])

  const ecommerceData = useMemo(() => {
    if (serializedData) {
      let results = serializedData
      if (searchValue) {
        results = serializedData.filter(customer =>
          customer[searchBy].toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      return results.map(customer => ({
        s_n: customer.s_n,
        id: customer.id,
        'Contact Name': customer.full_name,
        Phone: customer.phone_number,
        Email: customer.email,
        State: customer.state_name || customer.state,
        Country: customer.country
      }))
    } else return null
  }, [searchBy, searchValue, serializedData])

  const onPage = params => {
    setQueryParams(state => ({ ...state, ...params }))
  }

  const handleCreate = () => {
    navigate(
      `${ROUTES.ORGANIZATION.EXTERNAL.CREATE_NEW_EXTERNAL.path}?t=${activeTab.name}&action=new`
    )
  }

  const handleExport = () => {}

  const handleSelectRow = list => {
    const customersList = []

    for (let s_n in list) {
      // eslint-disable-next-line eqeqeq
      let existingCustomer = checkedList.find(item => item.s_n == s_n)
      if (existingCustomer) {
        customersList.push(existingCustomer)
        continue
      }

      // eslint-disable-next-line eqeqeq
      let customer = serializedData.find(item => item.s_n == s_n)
      customersList.push(customer)
    }

    setCheckedList(customersList)
  }

  const openDeletePrompt = (type, customer_id) => {
    setDeletePrompt({
      isOpen: true,
      type,
      customer_ids:
        type === 'single'
          ? [customer_id]
          : [...checkedList.map(customer => customer.id)]
    })
  }

  const closeDeletePrompt = () => {
    setDeletePrompt({
      isOpen: false,
      type: '',
      customer_ids: []
    })
  }

  const handleDelete = async () => {
    const deleteCustomer = async id => {
      const response = await customerApi.deleteCustomer(id)

      return response
    }

    const onComplete = (type, status) => {
      if (status === 'success') {
        toast(`Customer${type === 'multiple' ? 's' : ''} deleted`, 'success')
      } else {
        toast(
          `Unable to delete customer${type === 'multiple' ? 's' : ''}`,
          'error'
        )
      }
      setDeleting(false)
      closeDeletePrompt()
      loadData()
    }

    setDeleting(true)

    if (deletePrompt.type === 'single') {
      const response = await deleteCustomer(deletePrompt.customer_ids[0])
      if (!response.ok) {
        return onComplete('single', 'error')
      }
    } else if (deletePrompt.type === 'multiple') {
      let i = 0
      while (i < deletePrompt.customer_ids.length) {
        const response = await deleteCustomer(deletePrompt.customer_ids[i])
        if (!response.ok) {
          return onComplete('multiple', 'error')
        } else {
          i++
        }
      }
    }

    onComplete(deletePrompt.type, 'success')
  }

  const rowMenuItems = [
    {
      name: 'Edit',
      icon: HiOutlinePencil,
      action: row => onEdit(row.id),
      color: 'primary'
    },
    {
      name: 'Delete',
      icon: AiOutlineDelete,
      action: row => openDeletePrompt('single', row.id),
      color: 'danger'
    }
  ]

  return (
    <>
      {deletePrompt.isOpen && (
        <ConfirmDelete
          isOpen={deletePrompt.isOpen}
          text={`Are you sure you want to delete ${
            deletePrompt.type === 'single'
              ? 'this customer?'
              : 'the selected customers?'
          }`}
          onConfirm={handleDelete}
          onCancel={closeDeletePrompt}
          isDeleting={isDeleting}
        />
      )}
      <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
        <div className='flex items-center gap-2 lg:gap-3'>
          {serverSearch && (
            <SearchResultsDescription
              searchState={serverSearch}
              onClose={onCloseServerSearch}
            />
          )}
          {!!filterTags.length && (
            <div className='flex items-center gap-2 flex-wrap'>
              <p className='text-base font-medium text-dark-primary'>Filter:</p>
              {filterTags.map(({ name, value }, id) => (
                <FilterTag
                  key={id}
                  name={name}
                  value={value}
                  onDelete={onFilterDelete}
                />
              ))}
            </div>
          )}
          <div className='flex lg:hidden ml-auto'>
            <Pagination
              tableId='ecommerce-table'
              pageSize={ecommerce.meta?.page_size}
              totalCount={ecommerce.meta?.count}
              data={ecommerce.data}
              setSerializedData={setSerializedData}
              onPage={onPage}
              page={ecommerce.meta?.page}
            />
          </div>
        </div>
        <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
          <Tabs items={tabs} onSelectTab={onSelectTab} active={activeTab} />
          <div className='flex items-center gap-3 ml-auto'>
            <div className='hidden lg:flex'>
              <Pagination
                tableId='ecommerce-table'
                pageSize={ecommerce.meta?.page_size}
                totalCount={ecommerce.meta?.count}
                data={ecommerce.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={ecommerce.meta?.page}
              />
            </div>
            <button
              onClick={handleExport}
              disabled
              className='btn bg-g-400 text-dark-primary disabled'
            >
              <MdOutlineFileDownload
                size={18}
                color='#333333'
                className='mr-1'
              />
              Export
            </button>
            <button onClick={handleCreate} className='btn btn-primary'>
              <IoMdAdd size={18} color='#ffffff' className='mr-1' />
              Create New
            </button>
          </div>
        </div>
      </div>
      {checkedList.length ? (
        <div className='mb-2'>
          <div className='flex gap-2 items-center text-sm'>
            <p>Selected {checkedList.length} items</p>•
            <button
              className='btn-icon text-error hover:bg-error/20 hover:text-error'
              onClick={() => openDeletePrompt('multiple')}
            >
              <AiOutlineDelete size={16} />
            </button>
          </div>
        </div>
      ) : null}
      <Table
        id='ecommerce-table'
        headers={ecommerceHeader}
        data={ecommerceData}
        rowAction={rowAction}
        withCheckbox
        onSelectRow={handleSelectRow}
        withMenu
        rowMenuItems={rowMenuItems}
        emptyDataText='No customer found'
        emptyDataAction={handleCreate}
        emptyDataActionText='Create New'
      />
    </>
  )
}
