import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import useToast from '../../../hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as User } from '../../../assets/assign-user.svg'
import { ReactComponent as Print } from '../../../assets/print.svg'
import { HiOutlinePencil } from 'react-icons/hi2'
import { ReactComponent as Truck } from '../../../assets/truck.svg'
import { ReactComponent as Bike } from '../../../assets/bike.svg'
import { IoIosSearch } from 'react-icons/io'
import manifestApi from '../../../api/manifest'

export const DoorStepDropdown = ({ sn, id, manifestId, gotoPrint }) => {
  const navigate = useNavigate()
  const ridersData = useSelector(state => state?.riders?.riders)
  const toast = useToast()
  const [toggleMore, setToggleMore] = useState(0)
  const [assignee, setAssignee] = useState(false)
  const [selected, setSelected] = useState(false)
  const [riderId, setRiderId] = useState('')
  const [riderName, setRiderName] = useState('')

  const handleAssign = async () => {
    const result = await manifestApi.assignManifest(manifestId, {
      manifest_type: 'TM',
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
    } else {
      toast(result?.data?.message, 'error')
    }
  }

  return (
    <Popover className='relative'>
      <PopoverButton>
        <BsThreeDotsVertical
          className='cursor-pointer -z-10'
          onClick={() => {
            setToggleMore(id + 1)
          }}
        />
      </PopoverButton>
      {toggleMore === sn && (
        <PopoverPanel className='absolute -left-40 mt-2 -translate-x-1/2 transform'>
          <div className='overflow-hidden shadow-lg w-[300px] h-[150px] bg-white'>
            <span
              className='flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]'
              onClick={() => navigate(`/manifest/create-doorstep`)}
            >
              <User />
              <p>Create Door-step manifest</p>
            </span>

            <span
              className='flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]'
              onClick={gotoPrint}
            >
              <Print />
              <p>Print</p>
            </span>
            <span className='flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]'>
              <HiOutlinePencil size={16} />
              <p>Edit manifest</p>
            </span>
          </div>
        </PopoverPanel>
      )}
    </Popover>
  )
}
