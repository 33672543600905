import Filter from '../globals/filter/Filter'
import { useMemo, useState } from 'react'
import DateRangeModal from '../globals/filter/DateRangeModal'

const status_options = [
  {
    name: 'Unpaid',
    value: 'unpaid'
  },
  {
    name: 'Paid',
    value: 'paid'
  }
]

const date_type_options = [
  {
    name: 'Order date',
    value: 'order_date'
  },
  {
    name: 'Paid Date',
    value: 'paid_date'
  }
]

const date_options = [
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Last 7 days', value: 'last_7_days' },
  { name: 'Last 30 days', value: 'last_30_days' },
  { name: 'Range', value: 'range' }
]

export const SalesforceAgentCommissionsFilter = ({
  isOpen,
  onClose,
  filter,
  setFilter,
  dateRange,
  setDateRange
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filter)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)

  const onStatusChange = value => {
    setSelectedFilter(state => ({ ...state, status: value }))
  }

  const onDateTypeChange = value => {
    setSelectedFilter(state => ({ ...state, date_type: value }))
  }

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setSelectedFilter(state => ({ ...state, date: value }))
  }

  const cancelDateRange = () => {
    setSelectedFilter(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const applyFilters = () => {
    setFilter(selectedFilter)
    setDateRange(selectedDateRange)
    onClose()
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  return (
    <Filter.Body isOpen={isOpen} onClose={onClose} onCancel={onClose}>
      <Filter.Radio
        title='Status'
        name='status'
        value={selectedFilter.status}
        options={status_options}
        onChange={onStatusChange}
      />
      <Filter.SelectDropdown
        title='Date Type'
        name='date_type'
        placeholder='Select date type'
        value={selectedFilter.date_type}
        options={date_type_options}
        onChange={onDateTypeChange}
      />
      <Filter.SelectDropdown
        title='Date range'
        value={selectedFilter.date}
        placeholder='Select date range'
        options={date_options}
        onChange={onSelectDate}
        disabled={!selectedFilter.date_type}
      />

      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />

      <DateRangeModal
        isOpen={isDateModalOpen}
        initialDateRange={dateRange}
        onCancel={cancelDateRange}
        onSave={onSaveDateRange}
      />
    </Filter.Body>
  )
}
