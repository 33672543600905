export default function InvoicesIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.625 3.3125V27.6875H24.375V9.5L24.0938 9.21875L18.4688 3.59375L18.1875 3.3125H5.625ZM7.5 5.1875H16.875V10.8125H22.5V25.8125H7.5V5.1875ZM18.75 6.5L21.1875 8.9375H18.75V6.5ZM9.375 12.6875V14.5625H20.625V12.6875H9.375ZM9.375 17.375V19.25H15.9375V17.375H9.375ZM17.8125 17.375V19.25H20.625V17.375H17.8125ZM9.375 21.125V23H15.9375V21.125H9.375ZM17.8125 21.125V23H20.625V21.125H17.8125Z'
        fill={variant === 'primary' ? '#fe6802' : '#333333'}
      />
    </svg>
  )
}
