import React, { useEffect, useMemo, useState } from 'react'
import FilterButton from '../../../components/globals/filter/FilterButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MdOutlineFileDownload } from 'react-icons/md'
import { FiPlus } from 'react-icons/fi'
import Page from '../../../containers/Page'
import Search from '../../../components/globals/Search/Search'
import OrganizationTable from '../../../components/globals/OrganizationTable.jsx'
import OrganizationFilter from '../../../components/organization/OrganizationFilter'
import DeleteModal from '../../../components/organization/DeleteModal.jsx'
import {
  fetchBranches,
  fetchAreas,
  fetchRegions
} from '../../../slices/orgsSlice.js'
import { HiOutlinePencil } from 'react-icons/hi2'
import { AiOutlineDelete } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import EmployeeDetailsModal from '../../../components/organization/internal/EmployeeDetailsModal'
import InternalTabs from '../../../components/organization/internal/InternalTabs'
import ROUTES from '../../../constants/routes.js'
import accountsApi from '../../../api/accounts.js'
import orgsApi from '../../../api/orgs.js'
import ROLES from '../../../constants/roles.js'
import * as XLSX from 'xlsx'
import { Button } from '../../../components/index.jsx'
import Table from '../../../components/globals/Table.jsx'
import useCountry from '../../../hooks/useCountry'
import { GenderLookup, EmploymentType } from '../../../constants/index.js'
import { Loader } from '../../../components/globals/index.js'

function InternalOrganization ({ metaTitle }) {
  const [serializedData, setSerializedData] = useState(null)
  const orgsData = useSelector(state => state.orgs)

  const [searchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('id')
  const [activeTab, setActiveTab] = useState('Regions')
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [employeeList, setEmployeeList] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState([])

  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50,
    type: activeTab.value,
    order_state: searchParams.get('order_state') ?? '',
    branch: ''
  })
  const [employeeDetailsModal, setEmployeeDetailsModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState({})
  const [deletePrompt, setDeletePrompt] = useState({
    isOpen: false
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false)
  const showFilter = () => {
    setIsFilterOpen(true)
  }
  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }
  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))

    setSearchValue('')
  }

  const [loadingState, setLoadingState] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      await Promise.all([
        dispatch(fetchBranches()),
        dispatch(fetchAreas()),
        dispatch(fetchRegions())
      ])

      setLoading(false)
    }

    fetchData()
  }, [dispatch])

  useEffect(() => {
    const getAccounts = async () => {
      const response = await accountsApi.listAccounts()
      setEmployeeList(response?.data?.payload)
    }
    getAccounts()
  }, [])

  useEffect(() => {
    if (orgsData.data) {
      setLoading(false)
    }
  }, [orgsData.data])
  useEffect(() => {
    if (orgsData.filter) {
      const fetchData = async () => {
        try {
          const regionFilter = {
            name: orgsData.filter?.region || '',
            country: orgsData.filter?.country || ''
          }

          const branchFilter = {
            name: orgsData.filter?.branch_name,
            state: orgsData.filter?.state || ''
          }

          const employeeFilter = {
            branch: orgsData.filter?.branch_name || '',
            status: orgsData.filter?.status || '',
            role: orgsData.filter?.role || '',
            gender: GenderLookup[orgsData.filter?.gender] || '',
            employment_type: orgsData.employmentType || ''
          }

          let fetchAction

          switch (activeTab) {
            case 'Regions':
              fetchAction = dispatch(fetchRegions(regionFilter))
              break
            case 'Branches':
              fetchAction = dispatch(fetchBranches(branchFilter))
              break
            case 'Employees':
              fetchAction = accountsApi.listAccounts(employeeFilter)
              break
            default:
              break
          }
          if (fetchAction) {
            await fetchAction
          } else {
            await Promise.all([
              dispatch(fetchRegions()),
              dispatch(fetchBranches())
            ])
          }

          setLoading(false)
        } catch (error) {
          console.error('Error fetching data:', error)
          setLoading(false)
        }
      }

      // Call fetchData when orgsData.filter or activeTab changes
      fetchData()
    }
  }, [orgsData.filter])

  const isLoading = useMemo(() => loading, [loading])

  const branches = useMemo(() => orgsData?.branches, [orgsData?.branches])

  const areas = useMemo(() => orgsData?.areas, [orgsData])

  const regions = useMemo(() => orgsData?.regions, [orgsData?.regions])

  const employeesHeader = [
    'Employee ID',
    'Full Name',
    'Email',
    'Branch',
    'Role'
  ]
  const branchHeader = ['ID', 'Branch', 'City', 'State', 'Branch Supervisor']
  const areaHeader = ['Area', 'Branch', 'City', 'State', 'Area Manager']
  const regionHeader = ['Region', 'Country', 'State', 'Regional Manager']

  function convertToSingular (word) {
    if (word.endsWith('es')) {
      return word.slice(0, -2)
    }
    if (word.endsWith('s')) {
      return word.slice(0, -1)
    }
    return word
  }

  const findBranchById = id => {
    if (branches?.length) {
      return branches?.find(item => item.id === id)
    }
  }

  const getRoleNamesString = roleIds => {
    if (Array.isArray(roleIds)) {
      return roleIds?.map(id => ROLES[id]?.name || '--').join(', ')
    }
    return '--'
  }

  const employeesData = useMemo(() => {
    if (employeeList) {
      return employeeList?.map(item => {
        const branch = findBranchById(item.branch)
        const lastDashIndex = item.user.lastIndexOf('-')
        const employeeId = item.user.substring(lastDashIndex + 5)

        return {
          s_n: item.user,
          'Employee ID': employeeId,
          'Full Name': `${item.first_name} ${item.last_name}`,
          Email: item?.user_details?.email,
          Branch: branch?.name || '--',
          Role: getRoleNamesString(item.roles),
          others: { ...item }
        }
      })
    }
    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList, branches])

  const branchData = useMemo(() => {
    if (branches) {
      return branches?.map(item => {
        return {
          s_n: item.id,
          ID: item.id,
          Branch: item.name,
          City: item.city || '--',
          State: item.state || '--',
          'Branch Supervisor':
            item?.branch_manager &&
            Object?.entries(item?.branch_manager)?.length !== 0
              ? `${item?.branch_manager.first_name} ${item?.branch_manager.last_name}`
              : '--',
          others: item
        }
      })
    }
  }, [branches])

  const areaData = useMemo(() => {
    if (areas?.length) {
      return areas?.map(item => {
        return {
          Area: item.name || '--',
          Branch: item.branches?.map(item => item.name).join(', '),
          City: item.city || '--',
          'Area Manager':
            `${item.area_manager?.first_name} ${item?.area_manager?.last_name}` ||
            '--',
          others: item
        }
      })
    }
  }, [areas])

  const regionData = useMemo(() => {
    if (regions) {
      return regions?.map(item => {
        return {
          Region: item.name || '--',
          Country: item.country || '--',
          // Area: item.areas?.map(item => item.name).join(', '),
          State: 'Onipanu',
          'Regional Manager': `${item.regional_manager.first_name} ${item.regional_manager.last_name}`,
          others: item
        }
      })
    }
  }, [regions])

  const exportInvoices = () => {
    const createHeader = () => {
      switch (activeTab) {
        case 'Employees':
          return employeesHeader
        case 'Branches':
          return branchHeader
        case 'Areas':
          return areaHeader
        case 'Regions':
          return regionHeader
        default:
          break
      }
    }
    const createRows = () => {
      switch (activeTab) {
        case 'Employees':
          return employeesData.map(({ others, ...rest }) => rest)
        case 'Branches':
          return branchData.map(({ others, ...rest }) => rest)
        case 'Areas':
          return areaData.map(({ others, ...rest }) => rest)
        case 'Regions':
          return regionData.map(({ others, ...rest }) => rest)
        default:
          break
      }
    }
    const orgs_header = createHeader('orgs')
    const orgs_rows = createRows('orgs')

    // generate workbook
    const workbook = XLSX.utils.book_new()

    // generate worksheets
    const orgs_sheet = XLSX.utils.json_to_sheet(orgs_rows)

    XLSX.utils.book_append_sheet(workbook, orgs_sheet, `${activeTab} Data`)

    // fix headers
    XLSX.utils.sheet_add_aoa(orgs_sheet, [orgs_header], {
      origin: 'A1'
    })

    // column width
    const max_width = orgs_rows.reduce((w, r) => Math.max(w, r.Sender?.length))

    orgs_sheet['!cols'] = [{ wch: max_width }]

    XLSX.writeFile(workbook, `${activeTab}_Data.xlsx`, {
      compression: true
    })
  }

  const setTab = e => {
    setActiveTab(e)
  }

  const allTabs = [
    {
      id: 'regions',
      name: 'Regions',
      type: 'r'
    },
    {
      id: 'branches',
      name: 'Branches',
      type: 'tm'
    },
    {
      id: 'employees',
      name: 'Employees',
      type: 'em'
    }
    // {
    //   id: 'areas',
    //   name: 'Areas',
    //   type: 'a'
    // },
  ]

  const onApplyFilter = () => setLoading(true)
  const closeFilter = () => setIsFilterOpen(false)
  const onFilterDelete = () => setLoading(false)

  const handleTableAction = val => {
    if (activeTab === 'Employees') {
      setEmployeeDetailsModal(true)
      setSelectedEmployee(val)
    }
    return
  }

  const handleSelectRow = list => {
    const formatList = Object.entries(list).map(([key, value]) => ({
      id: key,
      isActive: value
    }))
    setCheckedList(formatList)
  }

  const onCreate = () => {
    navigate(
      `${ROUTES.ORGANIZATION.INTERNAL.CREATE_NEW_INTERNAL.path}?t=${activeTab}`
    )
  }

  const rowMenuItems = [
    {
      name: 'Edit',
      icon: HiOutlinePencil,
      action: row => handleEditAction(row),
      color: 'primary'
    },
    {
      name: `Delete ${convertToSingular(activeTab).toLowerCase()}`,
      icon: AiOutlineDelete,
      action: row => handleDeleteAction(row),
      color: 'danger'
    }
  ]

  const handleEditAction = row => {
    setSelectedEmployee(row)
    navigate(
      `${ROUTES.ORGANIZATION.INTERNAL.CREATE_NEW_INTERNAL.path}?t=${activeTab}&editId=${row.others.id}`
    )
  }

  const handleDeleteAction = val => {
    setSelectedEmployee(val)
    setShowDelete(true)
  }

  const openDeletePrompt = (type, customer_id) => {
    setDeletePrompt({
      isOpen: true,
      type,
      customer_ids:
        type === 'single'
          ? [customer_id]
          : [...checkedList.map(customer => customer.id)]
    })
  }

  const shouldShowButtonSection = () => {
    if (activeTab === 'Employees' && employeesData.length > 0) {
      return true
    }
    if (activeTab === 'Branches' && branchData?.length > 0) {
      return true
    }
    if (activeTab === 'Regions' && regionData?.length > 0) {
      return true
    }
    return false
  }

  const setDeleteText = () => {
    switch (activeTab) {
      case 'Employees':
        return {
          title: 'Delete employee',
          subtitle: `Are you sure you want to delete ${selectedEmployee['Full Name']} ? This action cannot be undone, all values associated with this field will be lost.`
        }
      case 'Branches':
        return {
          title: 'Delete branch',
          subtitle: `Are you sure you want to delete ${selectedEmployee.Branch} ? This action cannot be undone, all values associated with this field will be lost.`
        }
      case 'Regions':
        return {
          title: 'Delete region',
          subtitle: `Are you sure you want to delete the ${selectedEmployee.Region} ? This action cannot be undone, all values associated with this field will be lost.`
        }

      default:
        return
    }
  }

  const deleteAction = async () => {
    setLoadingState(true)
    try {
      switch (activeTab) {
        case 'Employees':
          await accountsApi.deleteAccount(selectedEmployee.others.id)
          setEmployeeList(prevList =>
            prevList.filter(emp => emp.id !== selectedEmployee.others.id)
          )
          break
        case 'Branches':
          await orgsApi.deleteBranch(selectedEmployee.others.id)
          dispatch(fetchBranches())
          break
        case 'Regions':
          await orgsApi.deleteRegion(selectedEmployee.others.id)
          dispatch(fetchRegions())
          break
        default:
          break
      }
      setLoadingState(false)
      setShowDelete(false)
    } catch (error) {
      setLoadingState(false)
    }
  }

  return (
    <Page metaTitle={metaTitle} noPadding>
      {showDelete && (
        <DeleteModal
          isOpen={showDelete}
          selectedEmployee={selectedEmployee}
          title={setDeleteText().title}
          subtitle={setDeleteText().subtitle}
          btnText='Delete'
          close={() => setShowDelete(false)}
          handleAction={deleteAction}
          loading={loadingState}
        />
      )}
      {isFilterOpen && (
        <OrganizationFilter
          isOpen={isFilterOpen}
          onClose={closeFilter}
          onApplyFilter={onApplyFilter}
          isBranchSpecific={false}
          activeTab={activeTab}
        />
      )}
      {employeeDetailsModal && (
        <EmployeeDetailsModal
          isOpen={employeeDetailsModal}
          onClose={() => setEmployeeDetailsModal(false)}
          details={selectedEmployee}
          activeTab={'Employees'}
          edit={() => handleEditAction(selectedEmployee)}
        />
      )}
      <Page.Header title={'Organization'}>
        {/* <Search
          value={searchValue}
          searchBy={searchBy}
          inputPlaceHolder='Search...'
          onSearchOptionChange={option => setSearchBy(option)}
          handleSearch={onSearchChange}
          allowServerSearch
        /> */}
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <OrganizationFilter.Description
        onDelete={onFilterDelete}
        activeTab={
          activeTab === 'Employees' ? '' : convertToSingular(activeTab)
        }
      />
      <Page.Body>
        <div className={` ${!shouldShowButtonSection ? 'mt-0' : 'mt-20'} `}>
          {shouldShowButtonSection() && (
            <div className='flex w-full justify-end align-center mr-[17px] mb-5'>
              <button
                onClick={exportInvoices}
                className='btn bg-[#E8E8E8] border-[1.5px]  bg-transparent text-dark-primary mr-7'
              >
                <MdOutlineFileDownload
                  size={20}
                  color='#333333'
                  className='mr-1'
                />
                Export
              </button>
              <Button
                onClick={onCreate}
                bgColor='#FF4D00'
                text={`Create New ${
                  activeTab === 'Employees'
                    ? 'User'
                    : convertToSingular(activeTab)
                }`}
                color='#FFFFFF'
                size='text-sm'
                borderRadius='10px'
                icon={<FiPlus />}
              />
            </div>
          )}
        </div>

        <div>
          <div className='mb-5'>
            <InternalTabs
              handleClick={setTab}
              activeTab={activeTab}
              allTabs={allTabs}
            />
          </div>
          {checkedList?.length ? (
            <div className='mb-2'>
              <div className='flex gap-2 items-center text-sm'>
                <p>Selected {checkedList?.length} items</p>•
                <button
                  className='btn-icon text-error hover:bg-error/20 hover:text-error'
                  onClick={() => openDeletePrompt('multiple')}
                >
                  <AiOutlineDelete size={16} />
                </button>
              </div>
            </div>
          ) : null}

          {isLoading ? (
            <Loader />
          ) : (
            <div>
              {activeTab === 'Employees' && (
                <div>
                  <OrganizationTable
                    id={'employees-table'}
                    headers={employeesHeader}
                    data={employeesData}
                    rowAction={handleTableAction}
                    onSelectRow={handleSelectRow}
                    withMenu
                    withCheckbox
                    rowMenuItems={rowMenuItems}
                    emptyDataText='No employee found'
                    emptyDataAction={onCreate}
                    emptyDataActionText='Create New Employee'
                  />
                </div>
              )}
              {activeTab === 'Branches' && (
                <OrganizationTable
                  id={'branch-table'}
                  headers={branchHeader}
                  data={branchData}
                  rowAction={handleTableAction}
                  onSelectRow={handleSelectRow}
                  withMenu
                  withCheckbox
                  rowMenuItems={rowMenuItems}
                  emptyDataText='No branch found'
                  emptyDataAction={onCreate}
                  emptyDataActionText='Create New Branch'
                />
              )}
              {activeTab === 'Regions' && (
                <div>
                  <OrganizationTable
                    id={'region-table'}
                    headers={regionHeader}
                    data={regionData}
                    rowAction={handleTableAction}
                    onSelectRow={handleSelectRow}
                    withMenu
                    withCheckbox
                    rowMenuItems={rowMenuItems}
                    emptyDataText='No region found'
                    emptyDataAction={onCreate}
                    emptyDataActionText='Create New Region'
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Page.Body>
    </Page>
  )
}

export default InternalOrganization
