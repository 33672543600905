import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function AppForm ({
  onSubmit,
  children,
  padding,
  id,
  className
}) {
  return (
    <form
      className={twMerge(
        'flex flex-col w-full pb-4 justify-center space-y-6',
        padding ? `px-${padding}` : '',
        className
      )}
      onSubmit={onSubmit}
      id={id}
    >
      {children}
    </form>
  )
}
