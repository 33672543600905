import cookies from '../utils/cookies'
import bookingClient from './clients/bookingClient'
import orgClient from './clients/orgClient'

const endpoint = 'auth/'

const login = async body => {
  const response = await bookingClient.post(`${endpoint}login/`, body)
  return response
}

const logout = async () => {
  const response = await bookingClient.post(`${endpoint}logout/`)
  return response
}

const logoutAll = async () => {
  const response = await bookingClient.post(`${endpoint}logoutall/`)
  return response
}

const getAccountData = async id => {
  const response = await orgClient.get(`iam/accounts/${id}/account_info/`)
  return response
}

const changePassword = async body => {
  const response = await bookingClient.post(
    `${endpoint}users/change_password/`,
    body
  )
  return response
}

const createAccessToken = async () => {
  const response = await orgClient.post('o/token/', {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    grant_type: 'client_credentials',
    redirect_uri: window.location.origin
  })
  return response
}

const refreshToken = async () => {
  const response = await bookingClient.post(`${endpoint}refresh-token/`, {
    refresh_token: cookies.get('refreshToken')
  })
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  logoutAll,
  getAccountData,
  createAccessToken,
  changePassword,
  refreshToken
}
