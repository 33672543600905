export default function InternalOrgIcon ({
  variant = 'secondary',
  ...restProps
}) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.25 4.25H15C8.7868 4.25 3.75 9.2868 3.75 15.5C3.75 21.7133 8.7868 26.75 15 26.75H16.25M21.25 10.5L26.25 15.5M26.25 15.5L21.25 20.5M26.25 15.5H11.25'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='1.83333'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
