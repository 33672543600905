import orgClient from './clients/orgClient'

const listSpecializations = async () => {
  const response = await orgClient.get('/specializations/specializations/')
  return response
}

const listBranches = async id => {
  const response = await orgClient.get(`/organisations/${id}/branches/`)
  return response
}

const listSystems = async () => {
  const response = await orgClient.get('/systems/')
  return response
}

const listRoles = async () => {
  const response = await orgClient.get('iam/roles')
  return response
}

const listGroups = async () => {
  const response = await orgClient.get('iam/groups')
  return response
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  listSpecializations,
  listBranches,
  listSystems,
  listRoles,
  listGroups
}
