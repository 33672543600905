import React, { useRef, useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { Field, useFormikContext } from 'formik'

const Suggestions = ({ id, suggestions, onSelect }) => {
  return (
    <div
      id={id}
      className='z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 absolute mt-24 max-h-64 overflow-y-auto'
    >
      {suggestions?.map((item, index) => (
        <div id='dropdownDivider' key={index}>
          <ul
            className='py-2 text-sm text-gray-700 dark:text-gray-200'
            aria-labelledby='dropdownDividerButton'
          >
            <li className='cursor-pointer' onClick={() => onSelect(item)}>
              <p className='block px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                {item.description}
              </p>
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

const MultiSelectDropdown = ({ id, options, selectedValues, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, isOpen, () => setIsOpen(false))

  const handleCheckboxChange = value => {
    onChange(
      selectedValues?.includes(value)
        ? selectedValues.filter(val => val !== value)
        : [...selectedValues, value]
    )
  }

  return (
    <div className='relative' ref={dropdownRef}>
      <button
        type='button'
        className='w-full px-4 py-3 input border border-gray-300 rounded-lg text-left'
        onClick={() => setIsOpen(prev => !prev)}
      >
        {selectedValues?.length > 0
          ? selectedValues.join(', ')
          : 'Select options'}
      </button>
      {isOpen && (
        <div className='z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full absolute mt-2 max-h-64 overflow-y-auto'>
          {options?.map((option, index) => (
            <div
              key={index}
              className='flex items-center p-2 hover:bg-gray-100'
            >
              <input
                type='checkbox'
                id={`option-${index}`}
                value={option}
                checked={selectedValues?.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                className='mr-4 ml-2 border-2 border-solid border-[#333333] rounded-sm'
              />
              <label htmlFor={`option-${index}`}>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const AppFormField = ({
  name,
  title,
  type,
  placeholder,
  auto,
  step = '0',
  showNaira = false,
  handleOnChange = () => {},
  required,
  phonecode,
  value,
  toggleVisibility,
  checked,
  withSuggestions,
  suggestions,
  onSelectSuggestion,
  onCloseSuggestions,
  noLabel = false,
  options, // New prop for multi-select options
  selectedValues, // New prop for selected values in multi-select
  onChangeSelectedValues, // New prop for handling multi-select changes
  ...restProps
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const formikContext = useFormikContext()
  const fieldContainer = useRef(null)

  useOnClickOutside(fieldContainer, !!suggestions?.length, () => {
    onCloseSuggestions()
  })

  if (formikContext) {
    return (
      <div className='flex flex-col w-full gap-2 relative' ref={fieldContainer}>
        <div className='form-control w-full'>
          {type !== 'checkbox' && !noLabel && (
            <label className='label'>
              <span className='label-text'>{title}</span>
            </label>
          )}
          {type === 'multi-select' ? (
            <MultiSelectDropdown
              id={`${name}-multi-select`}
              options={options}
              selectedValues={selectedValues}
              onChange={onChangeSelectedValues}
            />
          ) : (
            <Field name={name}>
              {({ field, form, meta }) => {
                return (
                  <>
                    {phonecode ? (
                      <div className='flex input px-0 divide-x rounded-lg border border-[#CBCBCB]'>
                        <span className='h-full flex items-center justify-center min-w-[5rem] w-fit px-1 text-sm'>
                          {phonecode}
                        </span>
                        <div className='w-[calc(100%_-_5rem)]'>
                          <input
                            className='bg-transparent border-none rounded-r-lg outline-0 w-full text-sm'
                            step={step}
                            placeholder={placeholder || title}
                            {...field}
                            id={name}
                            type={type}
                            onChange={e => {
                              field.onChange(e)
                              handleOnChange(e.target.value, e.target.name)
                            }}
                            autoComplete={auto}
                            onWheel={e => e.target.blur()}
                            {...restProps}
                          />
                        </div>
                      </div>
                    ) : type === 'password' && toggleVisibility ? (
                      <div className='relative'>
                        <input
                          className='input w-full'
                          step={step}
                          placeholder={placeholder || title}
                          {...field}
                          id={name}
                          type={passwordVisible ? 'text' : 'password'}
                          autoComplete='current-password'
                          onChange={e => {
                            field.onChange(e)
                            handleOnChange(e.target.value, e.target.name)
                          }}
                          onWheel={e => e.target.blur()}
                          {...restProps}
                        />
                        <span
                          className='absolute top-1/2 -translate-y-1/2 right-6'
                          role='button'
                          onClick={() => setPasswordVisible(state => !state)}
                        >
                          {passwordVisible ? (
                            <BsEye size={22} />
                          ) : (
                            <BsEyeSlash size={22} />
                          )}
                        </span>
                      </div>
                    ) : type === 'checkbox' ? (
                      <label className='label cursor-pointer'>
                        <span className='label-text mr-4'>{title}</span>
                        <input
                          className='checkbox checkbox-primary'
                          {...field}
                          id={name}
                          type={type}
                          onChange={e => {
                            field.onChange(e)
                            handleOnChange(e.target.value, e.target.name)
                          }}
                          {...restProps}
                        />
                      </label>
                    ) : (
                      <input
                        className='input w-full'
                        step={step}
                        placeholder={placeholder || title}
                        {...field}
                        id={name}
                        type={type}
                        onChange={e => {
                          field.onChange(e)
                          handleOnChange(e.target.value, e.target.name)
                        }}
                        onWheel={e => e.target.blur()}
                        {...restProps}
                      />
                    )}

                    {meta.touched && meta.error && (
                      <p className='text-red-500 text-xs'>{meta.error}</p>
                    )}
                    {showNaira && (
                      <p className='text-primary self-end mt-1'>
                        {(field.value * 1 || 0).toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </p>
                    )}
                  </>
                )
              }}
            </Field>
          )}
        </div>
        {withSuggestions && (
          <Suggestions
            id={`${name}-suggestions-dropdown`}
            suggestions={suggestions}
            onSelect={onSelectSuggestion}
          />
        )}
      </div>
    )
  }

  return (
    <div className='flex flex-col w-full gap-2 relative' ref={fieldContainer}>
      <div className='form-control w-full'>
        {type !== 'checkbox' && (
          <label className='label'>
            <span className='label-text'>{title}</span>
          </label>
        )}

        {type === 'checkbox' ? (
          <MultiSelectDropdown
            id={`${name}-multi-select`}
            options={options}
            selectedValues={selectedValues}
            onChange={onChangeSelectedValues}
          />
        ) : phonecode ? (
          <div className='flex input px-0 divide-x'>
            <span className='h-full flex items-center justify-center min-w-[5rem] w-fit px-1'>
              {phonecode}
            </span>
            <input
              placeholder={placeholder || title}
              onChange={e => {
                handleOnChange(e.target.value, e.target.name)
              }}
              id={name}
              name={name}
              type={type}
              value={value}
              autoComplete={auto}
              className='bg-transparent border-none rounded-r-lg outline-0 w-full'
              step={step}
              onWheel={e => e.target.blur()}
              required={required}
              {...restProps}
            />
          </div>
        ) : type === 'password' && toggleVisibility ? (
          <div className='relative'>
            <input
              placeholder={placeholder || title}
              onChange={e => {
                handleOnChange(e.target.value, e.target.name)
              }}
              id={name}
              name={name}
              type={passwordVisible ? 'text' : 'password'}
              value={value}
              autoComplete={auto}
              className='input w-full'
              step={step}
              onWheel={e => e.target.blur()}
              required={required}
              {...restProps}
            />
            <span
              className='absolute top-1/2 -translate-y-1/2 right-6'
              role='button'
              onClick={() => setPasswordVisible(state => !state)}
            >
              {passwordVisible ? <BsEye size={22} /> : <BsEyeSlash size={22} />}
            </span>
          </div>
        ) : type === 'checkbox' ? (
          <label className='label cursor-pointer'>
            <span className='label-text mr-4'>{title}</span>
            <input
              placeholder={placeholder || title}
              onChange={e => {
                handleOnChange(e.target.value, e.target.name)
              }}
              id={name}
              name={name}
              type={type}
              value={value}
              autoComplete={auto}
              className='checkbox checkbox-primary'
              step={step}
              onWheel={e => e.target.blur()}
              required={required}
              {...restProps}
            />
          </label>
        ) : (
          <input
            placeholder={placeholder || title}
            onChange={e => {
              handleOnChange(e.target.value, e.target.name)
            }}
            id={name}
            name={name}
            type={type}
            value={value}
            autoComplete={auto}
            className='input w-full'
            step={step}
            onWheel={e => e.target.blur()}
            required={required}
            {...restProps}
          />
        )}

        {showNaira && (
          <p className='text-primary self-end mt-1'>
            {(value * 1 || 0).toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </p>
        )}
      </div>

      {withSuggestions && (
        <Suggestions
          id={`${name}-suggestions-dropdown`}
          suggestions={suggestions}
          onSelect={onSelectSuggestion}
        />
      )}
    </div>
  )
}

export default AppFormField
