import ReactModal from 'react-modal'
import { IoMdClose } from 'react-icons/io'
import React from 'react'
import success from '../../assets/success.png'

const styles = {
  content: {
    maxWidth: '751px',
    margin: 'auto',
    height: '666px',
    maxHeight: '90%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

function SuccessModal ({ isOpen, onClose, title }) {
  return (
    <ReactModal style={styles} isOpen={isOpen}>
      <div className='flex justify-end' onClick={onClose}>
        <IoMdClose size={26} />
      </div>
      <div className='text-center mt-16 pt-10'>
        <div className='mb-7 flex justify-center'>
          <img src={success} alt='success' className='w-80' />
        </div>
        <h3 className='text-2xl font-semibold mb-3'>Successful!</h3>
        <p>{title}</p>
      </div>
    </ReactModal>
  )
}

export default SuccessModal
