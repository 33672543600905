import { getUrlQueryString } from '../utils'
import bookingClient from './clients/bookingClient'

const endpoint = 'customers/'

const getCustomers = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(
    search_params ? `${endpoint}?${search_params}` : endpoint
  )
  return response
}

const getPartners = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(
    search_params
      ? `${endpoint}partners/?${search_params}`
      : `${endpoint}partners/`
  )
  return response
}

const getECommerce = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(
    search_params
      ? `${endpoint}ecommerce_list/?${search_params}`
      : `${endpoint}ecommerce_list/`
  )

  return response
}

const createCustomer = async body => {
  const response = bookingClient.post(endpoint, body)
  return response
}

const readCustomer = async id => {
  const response = bookingClient.get(`${endpoint}${id}/`)
  return response
}

const updateCustomer = async (id, body) => {
  const response = bookingClient.put(`${endpoint}${id}/`, body)
  return response
}

const patchInvoice = async (id, body) => {
  const response = bookingClient.patch(`${endpoint}${id}/`, body)
  return response
}

const deleteCustomer = async id => {
  const response = bookingClient.delete(`${endpoint}${id}/`)
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  readCustomer,
  getCustomers,
  getPartners,
  getECommerce,
  createCustomer,
  updateCustomer,
  patchInvoice,
  deleteCustomer
}
