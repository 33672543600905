import React, { useEffect, useMemo, useRef, useState } from 'react'
import PackageFormQuote from '../../components/quotes/PackageFormQuote'

import Hero from '../../assets/hero.png'
import DocumentFormQuote from '../../components/quotes/DocumentFormQuote'
import logo from '../../assets/logo.png'
import { Loader } from '../../components/globals'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { QuoteType } from '../../components'
import { headerShadowOnScroll } from '../../utils/animate'
import CustomerTabs, {
  customerTabs
} from '../../components/customers/CustomerTabs'
import { fetchCategories } from '../../slices/categoriesSlice'
import { fetchDeliveryLocations } from '../../slices/locationsSlice'
import { twMerge } from 'tailwind-merge'
import { fetchAddons } from '../../slices/addonsSlice'
import { fetchPackageDimensions } from '../../slices/dimensionsSlice'

const PublicQuote = ({ metaTitle }) => {
  useDocumentTitle(`${metaTitle} | AAJ Transport Pro`)

  const [quoteType, setQuoteType] = useState('Package')
  const addonsStore = useSelector(state => state.addons)
  const categoriesStore = useSelector(state => state.categories)
  const locationsStore = useSelector(state => state.locations)
  const [shipmentType, setShipmentType] = useState('')
  const [state, setState] = useState('set-type')
  const [searchParams] = useSearchParams()
  const isIframe = searchParams.get('req') === 'iframe'
  const [activeTab, setActiveTab] = useState(customerTabs[0])

  const dispatch = useDispatch()

  const stickyHeaderRef = useRef(null)

  const isLoading = useMemo(() => {
    return !addonsStore.data || !categoriesStore.data || !locationsStore.data
  }, [addonsStore.data, categoriesStore.data, locationsStore.data])

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchDeliveryLocations())
    dispatch(fetchAddons())
    dispatch(fetchPackageDimensions())

    if (stickyHeaderRef.current) {
      headerShadowOnScroll(stickyHeaderRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shipmentType === 'LC') {
      setQuoteType('Package')
    }
  }, [shipmentType])

  const onShipmentType = type => {
    setShipmentType(type)
    setState('form')
  }

  const onBack = () => {
    setState('set-type')
  }

  if (isLoading) return <Loader />

  return (
    <div className='relative h-screen overflow-y-auto bg-white'>
      <div className='flex flex-col md:flex-row w-full md:items-start justify-center md:justify-between'>
        <div className='w-full lg:w-[72%]'>
          <nav
            className='navbar bg-white fixed z-10 top-0'
            ref={stickyHeaderRef}
          >
            <div className='w-full max-w-7xl mx-auto px-4 lg:px-12 flex items-center justify-between relative'>
              <div className='navbar-start shrink-0 w-fit'>
                {!isIframe && (
                  <NavLink to='/' className='cursor-pointer'>
                    <img
                      src={logo}
                      alt='logo'
                      className='w-12 h-auto lg:w-16 lg:h-auto'
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </nav>

          <div className='w-full md:pl-16 px-4 mt-14'>
            <h1 className='text-4xl font-bold text-center mb-10 sticky top-4 z-20'>
              Get Quote
            </h1>

            <div className='relative mb-8 flex items-center justify-center w-full max-w-3xl mx-auto'>
              {state === 'form' && (
                <button
                  className='btn btn-primary btn-sm absolute left-0 top-1/2 -translate-y-1/2'
                  onClick={onBack}
                >
                  Back
                </button>
              )}
              <ul className='steps  z-0'>
                <li
                  className={`${
                    state === 'set-type' ? 'step step-primary' : 'step'
                  }`}
                ></li>
                <li
                  className={`${
                    state === 'form' ? 'step step-primary' : 'step'
                  }`}
                ></li>
              </ul>
            </div>

            {state === 'set-type' && (
              <QuoteType onShipmentType={onShipmentType} />
            )}

            {state === 'form' && (
              <div className='rounded-lg border border-neutral pt-10'>
                <div className='flex w-full flex-col items-center gap-5 px-4 md:px-8'>
                  <CustomerTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  <div className='flex w-full flex-wrap items-center justify-start md:justify-center md:gap-3 lg:gap-10'>
                    {shipmentType === 'IN' ? (
                      [
                        { name: 'Package', code: 'Package' },
                        { name: 'Document', code: 'Document' },
                        { name: 'Fish/Snail', code: 'Fish Shipment' }
                      ].map((tab, id) => (
                        <button
                          key={id}
                          onClick={() => setQuoteType(tab.code)}
                          className={twMerge(
                            'flex items-center justify-center px-4 md:px-9 py-3 rounded-lg',
                            quoteType === tab.code
                              ? 'text-white bg-[#FE6806]'
                              : 'text-black hover:bg-[#FE6806]/20 transition-all'
                          )}
                        >
                          {tab.name}
                        </button>
                      ))
                    ) : (
                      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
                        Shipment Info
                      </h2>
                    )}
                  </div>
                </div>

                {quoteType === 'Package' && (
                  <PackageFormQuote
                    addons={addonsStore.data}
                    categories={categoriesStore.data}
                    locations={locationsStore.data}
                    type={shipmentType}
                    customer={activeTab.name}
                    allowCreateOrder={false}
                  />
                )}

                {quoteType === 'Document' && (
                  <DocumentFormQuote
                    categories={categoriesStore.data}
                    customer={activeTab.name}
                    allowCreateOrder={false}
                  />
                )}

                {quoteType === 'Fish Shipment' && (
                  <PackageFormQuote
                    addons={addonsStore.data}
                    categories={categoriesStore.data}
                    fishShipment
                    customer={activeTab.name}
                    allowCreateOrder={false}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <img
          src={Hero}
          alt='hero'
          className='hidden lg:block w-[30%] object-contain aspect-square fixed right-0 z-10'
        />
      </div>
    </div>
  )
}

export default PublicQuote
