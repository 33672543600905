import React from 'react'
import Page from '../../../containers/Page'
import ROUTES from '../../../constants/routes'
import { useLocation } from 'react-router-dom'
import AreaForm from './AreaForm'
import BranchForm from './BranchForm'
import EmployeeForm from './EmployeeForm'
import RegionForm from './RegionForm'

const breadCrumbs = [
  {
    name: 'Organization',
    path: '',
    disbled: true
  },
  {
    name: 'Internal',
    path: ROUTES.ORGANIZATION.INTERNAL.path
  },
  {
    name: 'Create New',
    path: ROUTES.ORGANIZATION.INTERNAL.CREATE_NEW_INTERNAL.path,
    disabled: true
  }
]

function CreateNewInternalOrgs ({ metaTitle }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tab = queryParams.get('t')
  const editId = queryParams.get('editId')

  function convertToSingular (word) {
    switch (word) {
      case 'Employees':
        return word?.slice(0, -1)
      case 'Branches':
        return word?.slice(0, -2)
      case 'Areas':
      case 'Regions':
        return word?.slice(0, -1)
      default:
        return word
    }
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title={
          editId
            ? `Edit ${convertToSingular(tab)}`
            : `Create New ${convertToSingular(tab)}`
        }
        backRoute={ROUTES.ORGANIZATION.INTERNAL.path}
        breadCrumbs={breadCrumbs}
      />

      <Page.Body>
        <div>
          {tab === 'Employees' && <EmployeeForm editId={editId} />}
          {tab === 'Branches' && <BranchForm editId={editId} />}
          {tab === 'Regions' && <RegionForm editId={editId} />}
          {tab === 'Areas' && <AreaForm editId={editId} />}
        </div>
      </Page.Body>
    </Page>
  )
}

export default CreateNewInternalOrgs
