import React, { useState, useEffect, useMemo } from 'react'
import AppForm from '../../../components/globals/Form/AppForm.jsx'
import AppFormField from '../../../components/globals/Form/AppFormField.jsx'
import AppFormSelectField from '../../../components/globals/Form/AppFormSelectField.jsx'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { fetchBranches, fetchRegions } from '../../../slices/orgsSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import nigerianStates from '../../../fixtures/states.js'
import useCountry from '../../../hooks/useCountry.js'
import accountsApi from '../../../api/accounts.js'
import orgsApi from '../../../api/orgs.js'
import { useNavigate } from 'react-router-dom'
import useToast from '../../../hooks/useToast.js'
import SuccessModal from '../../../components/organization/SuccessModal.jsx'

const validationSchema = Yup.object().shape({
  post_code: Yup.string().required('Post code is required'),
  phone_no: Yup.string().required('Phone no is required'),
  address: Yup.string().required('Address is required'),
  name: Yup.string().required('Name is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  manager: Yup.string().required('Branch Manager is required'),
  country: Yup.string().required('Country is required'),
  zone: Yup.string().required('Zone is required'),
  region: Yup.string().required('Region is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
})

function BranchForm ({ editId }) {
  const [successModal, setSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const orgs = useSelector(state => state.orgs)

  const toast = useToast()
  const [employeeList, setEmployeeList] = useState([])

  const userData = useSelector(state => state.auth.user)

  useEffect(() => {
    dispatch(fetchBranches())
    dispatch(fetchRegions())
  }, [dispatch])

  const Country = useCountry()
  const countries = Country.getAllCountries()

  const [initialValues, setInitialValues] = useState({
    post_code: '',
    phone_no: '',
    address: '',
    state: '',
    city: '',
    manager: '',
    country: '',
    email: '',
    name: '',
    zone: '',
    region: ''
  })

  const branches = useMemo(() => orgs.branches, [orgs.branches])
  const regions = useMemo(() => orgs.regions, [orgs.regions])
  const states = nigerianStates?.map(item => item.name)

  const zones = [
    { id: 1, name: 'Lagos' },
    { id: 2, name: 'South West' },
    { id: 3, name: 'South East' },
    { id: 4, name: 'North' }
  ]

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (editId) {
        try {
          const response = await orgsApi.getSingleBranch(editId)
          const branch = response.data

          setInitialValues({
            post_code: branch.post_code,
            phone_no: branch.phone_number,
            address: branch.address,
            state: branch.state,
            city: branch.city,
            manager: branch.manager,
            country: Country.getAllCountries().find(
              country => country.name === branch.country
            ).isoCode,
            email: branch.email,
            name: branch.name,
            zone: branch.zone,
            region: branch.region
          })
        } catch (error) {
          console.error('Failed to fetch branch:', error)
        }
      }
    }

    fetchData()
  }, [editId])

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)
    setLoading(true)

    const payload = {
      organization: userData.organisation.oid,
      name: values.name,
      address: values.address,
      country: countries.find(item => item.isoCode === values.country).name,
      city: values.city,
      state: values.state,
      post_code: values.post_code,
      phone_number: `${values.phone_no}`,
      email: values.email,
      manager: values.manager,
      country_code: values.country,
      region: values.region,
      zone: parseInt(values.zone, 10),
      region: Number(values.region)
    }
    console.log(payload)

    let response
    if (editId) {
      response = await orgsApi.editBranch(editId, payload)
    } else {
      response = await orgsApi.createBranch(payload)
    }
    setLoading(false)

    if (!response.ok) {
      const apiError = response.error || 'Unable to create branch'
      toast(apiError)
      return
    }
    setSuccessModal(true)
  }

  const handleSuccessModalAction = () => {
    setSuccessModal(false)
    navigate('/organization/internal')
  }
  useEffect(() => {
    const getAccounts = async () => {
      const response = await accountsApi.listAccounts()
      setEmployeeList(response.data.payload)
    }
    getAccounts()
  }, [])

  return (
    <div>
      {successModal && (
        <SuccessModal
          isOpen={successModal}
          title='Branch created successfully'
          onClose={handleSuccessModalAction}
        />
      )}

      <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
        <h1 className='text-xl font-semibold mb-8'>Branch Details</h1>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {formik => {
            const { values, isSubmitting, isValid, handleSubmit } = formik

            return (
              <AppForm id='branch_details_form' padding='0'>
                <div className='flex flex-row gap-6 '>
                  <AppFormField name='name' title='Name' />
                  <AppFormField
                    name='address'
                    title='Address'
                    placeholder='Address'
                  />
                </div>

                <div className='flex flex-row gap-6 '>
                  <AppFormSelectField name='manager' title='Branch Manager'>
                    <option value=''>Select branch manager</option>
                    {employeeList?.map((emp, ind) => (
                      <option key={ind} value={emp.id}>
                        {emp.first_name} {emp.last_name}
                      </option>
                    ))}
                  </AppFormSelectField>
                  <AppFormSelectField name='state' title='State'>
                    <option value=''>Select State</option>
                    {states?.map((state, ind) => (
                      <option key={ind} value={state}>
                        {state}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>
                <div className='flex flex-row gap-6 '>
                  <AppFormField name='city' title='City' placeholder='City' />
                  <AppFormSelectField name='region' title='Region'>
                    <option value=''>Select Region</option>
                    {regions?.map((region, ind) => (
                      <option key={ind} value={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>
                <div className='flex flex-row gap-6 '>
                  <AppFormField
                    name='post_code'
                    title='Post code'
                    placeholder='110101'
                  />
                  <AppFormField name='phone_no' title='Phone Number' />
                </div>
                <div className='flex flex-row gap-6 '>
                  <AppFormSelectField name='country' title='Country'>
                    <option value=''>Select country</option>
                    {countries?.map((country, index) => (
                      <option key={index} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                  <AppFormField
                    name='email'
                    title='Email'
                    placeholder='Email'
                    type='email'
                  />
                </div>
                <div className='flex flex-row w-1/2'>
                  <AppFormSelectField name='zone' title='Zone'>
                    <option value=''>Select zone</option>
                    {zones?.map((zone, index) => (
                      <option key={index} value={zone.id}>
                        {zone.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>

                <div className='flex items-center justify-end'>
                  <button
                    className={`btn ${
                      isValid ? 'btn-primary' : 'btn-neutral btn-disabled'
                    }`}
                    disabled={!isValid || isSubmitting}
                    type='button' // Changed from 'submit' to 'button'
                    onClick={handleSubmit} // Trigger Formik submit
                  >
                    {loading ? (
                      <CircularProgress size={28} color='inherit' />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </AppForm>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default BranchForm
