import React, { useMemo, useState, useEffect } from 'react'
import {
  AppForm,
  AppFormField,
  AppFormSelectField
} from '../../../components/index.jsx'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import useCountry from '../../../hooks/useCountry'
import orgsApi from '../../../api/orgs'
import useToast from '../../../hooks/useToast'
import { useNavigate } from 'react-router-dom'
import nigerianStates from '../../../fixtures/states.js'
import SuccessModal from '../../../components/organization/SuccessModal.jsx'
import accountsApi from '../../../api/accounts.js'
import {
  fetchBranches,
  fetchAreas,
  fetchRegions
} from '../../../slices/orgsSlice.js'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  region: Yup.string().required('Region is required'),
  manager: Yup.string().required('Area Manager is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  branch: Yup.string().required('branch is required')
})

function AreaForm () {
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const orgs = useSelector(state => state.orgs)
  const [employeeList, setEmployeeList] = useState([])
  const Country = useCountry()
  const countries = Country.getAllCountries()

  const dispatch = useDispatch()

  const toast = useToast()
  const navigate = useNavigate()

  const branches = useMemo(() => orgs.branches, [orgs.branches])

  const regions = useMemo(() => orgs?.regions, [orgs?.regions])

  const states = nigerianStates.map(item => item.name)

  const [selectedBranches, setSelectedBranches] = useState([])
  const userData = useSelector(state => state.auth.user)

  useEffect(() => {
    dispatch(fetchBranches())
    dispatch(fetchAreas())
    dispatch(fetchRegions())
  }, [])
  useEffect(() => {
    const getAccounts = async () => {
      const response = await accountsApi.listAccounts()
      setEmployeeList(response.data.payload)
    }
    getAccounts()
  }, [])

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)
    setLoading(true)
    const payload = {
      name: values.name,
      region: parseInt(values.region, 10),
      state: values.state,
      manager: values.manager,
      city: values.city,
      country: countries.find(item => item.isoCode === values.country).name,
      organization: userData.organisation.oid,
      branch: values.branch
    }

    const response = await orgsApi.createArea(payload)
    setLoading(false)
    if (!response.ok) {
      const apiError = response.error || 'Unable to create area'
      toast(apiError)
      return
    }
    setSuccessModal(true)
  }

  const handleSuccessModalAction = () => {
    setSuccessModal(false)
    navigate('/organization/internal')
  }

  return (
    <div>
      {successModal && (
        <SuccessModal
          isOpen={successModal}
          title='You have successfully created an Area'
          onClose={handleSuccessModalAction}
        />
      )}
      <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
        <h1 className='text-xl font-semibold mb-8'>Area Details</h1>
        <Formik
          initialValues={{
            name: '',
            region: '',
            manager: '',
            state: '',
            city: '',
            country: '',
            branch: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {formik => {
            const { values, isSubmitting, isValid, handleSubmit } = formik
            return (
              <AppForm id='personal_details_form' padding='0'>
                <div className='flex flex-row gap-6'>
                  <AppFormField name='name' title='Name' />
                  <AppFormSelectField name='country' title='Country'>
                    <option value=''>Select Country</option>
                    {Country.getAllCountries().map((country, index) => (
                      <option key={index} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>
                <div className='flex flex-row gap-6'>
                  <AppFormSelectField name='state' title='State'>
                    <option value=''>Select State</option>
                    {states?.map((state, ind) => (
                      <option key={ind} value={state}>
                        {state}
                      </option>
                    ))}
                  </AppFormSelectField>
                  <AppFormField name='city' title='City' placeholder='City' />
                </div>
                <div className='flex flex-row gap-6'>
                  <AppFormSelectField name='branch' title='Branch'>
                    <option value=''>Select branch</option>
                    {branches?.map((branch, ind) => (
                      <option key={ind} value={branch.name}>
                        {branch.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                  <AppFormSelectField name='region' title='Region'>
                    <option value=''>Select region </option>
                    {regions?.map((region, ind) => (
                      <option key={ind} value={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>
                <div className='flex flex-row w-1/2'>
                  <AppFormSelectField name='manager' title='Area Manager'>
                    <option value=''>Select area manager</option>
                    {employeeList?.map((emp, ind) => (
                      <option key={ind} value={emp.id}>
                        {emp.first_name} {emp.last_name}
                      </option>
                    ))}
                  </AppFormSelectField>
                </div>
                <div className='flex items-center justify-end'>
                  <button
                    className={`btn ${
                      isValid ? 'btn-primary' : 'btn-neutral btn-disabled'
                    }`}
                    disabled={!isValid || isSubmitting}
                    type='button'
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <CircularProgress size={28} color='inherit' />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </AppForm>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default AreaForm
