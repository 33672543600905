import { LocalizationProvider } from '@mui/x-date-pickers'
import Modal from 'react-modal'
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { useState } from 'react'
import { resolveDate } from '../../../helpers/queryByDate'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '700px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 70
  }
}

export default function DateRangeModal ({
  isOpen,
  initialDateRange,
  onCancel,
  onSave
}) {
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange)

  const onCancelDateRange = () => {
    setSelectedDateRange(initialDateRange)
    onCancel()
  }

  const onStartDateChange = newValue => {
    setSelectedDateRange(state => [newValue, state[1]])
  }
  const onEndDateChange = newValue => {
    setSelectedDateRange(state => [state[0], newValue])
  }

  const handleSave = () => {
    onSave(selectedDateRange)
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='Date Range Picker'
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='flex flex-col w-full justify-center'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='flex flex-col md:flex-row'>
            <div>
              <div className='mr-6 p-3 border gray-300 rounded'>
                <div className='flex gap-2 items-center'>
                  <CalendarIcon />
                  Start ({resolveDate(selectedDateRange[0].format())})
                </div>
              </div>
              <DateCalendar
                value={selectedDateRange[0]}
                onChange={onStartDateChange}
              />
            </div>
            <div>
              <div className='ml-6 p-3 border gray-300 rounded'>
                <div className='flex gap-2 items-center'>
                  <CalendarIcon />
                  End ({resolveDate(selectedDateRange[1].format())})
                </div>
              </div>
              <DateCalendar
                value={selectedDateRange[1]}
                onChange={onEndDateChange}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
      <div className='flex justify-end gap-2'>
        <button
          className='btn btn-outline text-gray-800'
          onClick={onCancelDateRange}
        >
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  )
}
