import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io'
import 'react-phone-number-input/style.css'
import { Loader } from '../globals'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomers } from '../../slices/customersSlice'
import { HiMapPin } from 'react-icons/hi2'
import ROUTES from '../../constants/routes'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import CustomerPreview from './CustomerPreview'
import CustomerTabs from './CustomerTabs'
import MultipieceCustomerPrompt from '../orders/newOrder/multipiece/MultipieceCustomerPrompt'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import AddressBookRegulars from './AddressBookRegulars'
import AddressBookPartners from './AddressBookPartners'
import AddressBookEcommerce from './AdressBookEcommerce'
import useCountry from '../../hooks/useCountry'
import NewCustomer from "../orders/newOrder/NewCustomer"

export default function AddressBook () {
  const [activeTab, setActiveTab] = useState({ name: 'Regulars', id: 0 })
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPreviewCustomer, setPreviewCustomer] = useState({
    state: false,
    customer: null
  })
  const [isEditCustomer, setEditCustomer] = useState({
    state: false,
    customer: null
  })
  const [isMultipieceCustomerPrompt, setMultipieceCustomerPrompt] =
    useState(false)

  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('full_name')
  const [serverSearch, setServerSearch] = useState(null)

  const quoteId = searchParams.get('qt')
  const customerType = searchParams.get('ctm')
  const multipiece = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const Country = useCountry()

  const { layoutContainer } = useContext(AuthLayoutContext)
  const { order, updateOrder, resolvePathname, formatCustomer } =
    useContext(CreateOrderContext)
  const { sender, receiver, type: shipmentType } = order.payload

  const [oldCustomer, setOldCustomer] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [secondPageLoaded, setSecondPageLoaded] = useState(false)
  // const [serializedData, setSerializedData] = useState([])

  const quotes = useSelector(state => state.quotes)

  const dispatch = useDispatch()

  const customerLocation = useMemo(() => {
    if (customerType === 'sender') {
      return { name: 'Nigeria', code: 'NG' }
    }
    if (customerType === 'receiver') {
      if (quoteId) {
        if (quotes.data) {
          const quote = quotes.data.find(({ id }) => id === Number(quoteId))
          if (quote) {
            const country = quote.receiver_country.split(',')
            return { name: country[0].trim(), code: country[1].trim() }
          }
        }
      } else if (shipmentType === 'LC') return { name: 'Nigeria', code: 'NG' }
      else return { name: 'Global', code: 'GLOBAL' }
    }
  }, [customerType, quoteId, quotes.data, shipmentType])

  const searchOptions = useMemo(() => {
    const options = [
      {
        name: 'Name',
        value: 'full_name'
      }
    ]
    if (activeTab.id !== 0) {
      options.push({
        name: 'Company Name',
        value: 'business_name'
      })
    }

    return options
  }, [activeTab])

  useEffect(() => {
    if (customerLocation && customerLocation.code !== 'GLOBAL') {
      const country = Country.getAllCountries().find(
        country => country.name === customerLocation.name
      )
      if (country) {
        setQueryParams(state => ({ ...state, code: country.isoCode }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerLocation])

  useEffect(() => {
    if (!customerType) {
      setSearchParams('ctm', 'sender')
    } else if (customerType === 'receiver' && !sender) {
      navigateWithParams({
        pathname: resolvePathname(
          ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
        ),
        search: {
          ctm: 'sender'
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerType, sender])

  useEffect(() => {
    if (secondPageLoaded && layoutContainer) {
      layoutContainer.scrollTo({ top: 0 })
    }
  }, [layoutContainer, secondPageLoaded])

  useEffect(() => {
    if (secondPageLoaded) {
      setSecondPageLoaded(false)
    }
  }, [secondPageLoaded])

  const onSelectTab = tab => {
    setActiveTab(tab)
    setQueryParams(state => ({ ...state, page: 1 }))
  }

  const saveCustomer = (customer, data) => {
    const formattedCustomer = formatCustomer(data, 'new')

    updateOrder({
      [customer]: formattedCustomer
    })
  }

  const navigateWithParams = useNavigateWithParams()

  const closeMultipieceCustomerPrompt = () => {
    setMultipieceCustomerPrompt(false)
  }

  const handleOnClickCustomer = item => {
    if (multipiece) {
      if (customerType === billTo) {
        setMultipieceCustomerPrompt(true)
        return
      }
    }

    setPreviewCustomer({ state: true, customer: item })
  }

  const onPreviewCustomerClose = () => {
    setPreviewCustomer({ state: false, customer: null })
  }

  const handleSender = () => {
    setTimeout(() => {
      setShowLoader(false)
      setSecondPageLoaded(true)
      navigateWithParams({
        pathname: resolvePathname(
          ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
        ),
        search: {
          ctm: 'receiver'
        }
      })
    }, 1000)
  }

  const handleReceiver = () => {
    navigateWithParams({
      pathname: resolvePathname(ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path),
      search: {
        ctm: null
      }
    })
  }

  const handleCustomerPreviewProceed = customer => {
    setShowLoader(true)
    onPreviewCustomerClose()

    if (customerType === 'sender') {
      saveCustomer(customerType, customer)
      handleSender()
    }

    if (customerType === 'receiver') {
      saveCustomer(customerType, customer)
      handleReceiver()
    }
  }

  const onEditCustomer = customer => {
    setPreviewCustomer({ state: false, customer: null })
    setEditCustomer({ state: true, customer })
    setOldCustomer(false)
  }

  const handleOnSearch = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleOnServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const handleCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const onNewCustomer = customer => {
    dispatch(fetchCustomers())
    saveCustomer(customerType, customer)

    setOldCustomer(true)
    if (isEditCustomer.state) {
      setEditCustomer({
        state: false,
        customer: null
      })
    }

    if (customerType === 'sender') {
      setTimeout(() => {
        setSecondPageLoaded(true)
      }, 1000)
    }
  }

  const onCloseNewCustomer = () => {
    setOldCustomer(true)
    if (isEditCustomer.state) {
      setEditCustomer({
        state: false,
        customer: null
      })
    }
  }

  const handleMultipieceCustomerPromptProceed = () => {
    closeMultipieceCustomerPrompt()
    setShowLoader(true)

    if (customerType === 'sender') {
      handleSender()
    }

    if (customerType === 'receiver') {
      handleReceiver()
    }
  }

  if (showLoader) return <Loader />

  return (
    <div className='flex flex-col items-center pb-8'>
      {isPreviewCustomer.state && (
        <CustomerPreview
          isOpen={isPreviewCustomer.state}
          onClose={onPreviewCustomerClose}
          customer={isPreviewCustomer.customer}
          type={customerType}
          allowEdit={activeTab.id !== 1}
          onEdit={onEditCustomer}
          onProceed={handleCustomerPreviewProceed}
        />
      )}
      {isMultipieceCustomerPrompt && (
        <MultipieceCustomerPrompt
          isOpen={isMultipieceCustomerPrompt}
          customer={customerType}
          onClose={closeMultipieceCustomerPrompt}
          onProceed={handleMultipieceCustomerPromptProceed}
        />
      )}
      <div className='rounded-lg border md:px-4 w-full max-w-3xl bg-white'>
        <div>
          {oldCustomer ? (
            <div className='text-left flex gap-6 p-4 flex-col'>
              <div id='customers-list'>
                <h1 className='text-[#FF4D00] text-xl mb-4'>
                  Address Book
                  <span className='text-sm text-black inline-flex py-1 px-1.5 bg-neutral rounded-lg ml-3 select-none gap-1'>
                    <HiMapPin size={16} />
                    {customerLocation.name}
                  </span>
                </h1>

                <div className='flex flex-wrap flex-row justify-between items-center mb-4'>
                  <CustomerTabs
                    activeTab={activeTab}
                    onSelectTab={onSelectTab}
                  />

                  <div className='ml-auto'>
                    <button
                      className='p-2 rounded-full md:rounded-lg bg-transparent hover:bg-gray-100 cursor-pointer transition-all flex items-center gap-1 text-primary'
                      onClick={() => setOldCustomer(false)}
                    >
                      <span className='hidden sm:inline-block'>
                        New customer
                      </span>

                      <IoMdAdd size={18} color='#fe6802' />
                    </button>
                  </div>
                </div>

                {activeTab.id === 0 && (
                  <AddressBookRegulars
                    activeTab={activeTab}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    searchBy={searchBy}
                    searchValue={searchValue}
                    onSearch={handleOnSearch}
                    serverSearch={serverSearch}
                    onServerSearch={handleOnServerSearch}
                    onCloseServerSearch={handleCloseServerSearch}
                    onClickCustomer={handleOnClickCustomer}
                    customerType={customerType}
                    sender={sender}
                    receiver={receiver}
                  />
                )}
                {activeTab.id === 1 && (
                  <AddressBookPartners
                    activeTab={activeTab}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    searchBy={searchBy}
                    setSearchBy={setSearchBy}
                    searchValue={searchValue}
                    onSearch={handleOnSearch}
                    serverSearch={serverSearch}
                    onServerSearch={handleOnServerSearch}
                    onCloseServerSearch={handleCloseServerSearch}
                    onClickCustomer={handleOnClickCustomer}
                    customerType={customerType}
                    sender={sender}
                    receiver={receiver}
                  />
                )}
                {activeTab.id === 2 && (
                  <AddressBookEcommerce
                    activeTab={activeTab}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    searchBy={searchBy}
                    setSearchBy={setSearchBy}
                    searchValue={searchValue}
                    onSearch={handleOnSearch}
                    serverSearch={serverSearch}
                    onServerSearch={handleOnServerSearch}
                    onCloseServerSearch={handleCloseServerSearch}
                    onClickCustomer={handleOnClickCustomer}
                    customerType={customerType}
                    sender={sender}
                    receiver={receiver}
                  />
                )}
              </div>
            </div>
          ) : (
            <NewCustomer
              customerType={customerType}
              shipmentType={shipmentType}
              onNewCustomer={onNewCustomer}
              onCloseNewCustomer={onCloseNewCustomer}
              customer={isEditCustomer.customer}
              isEdit={isEditCustomer.state}
            />
          )}
        </div>
      </div>
    </div>
  )
}
