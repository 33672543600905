import React, { useState, useEffect, useMemo, useContext } from 'react'
import Modal from 'react-modal'
import ordersApi from '../../api/orders'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import TempQuote from '../quotes/TempQuote'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { City, State } from 'country-state-city'
import useToast from '../../hooks/useToast'
import { parseError, scrollToTarget } from '../../utils'
import { IoMdAdd, IoMdClose } from 'react-icons/io'
import SelectPackaging from '../orders/newOrder/SelectPackaging'
import _ from 'lodash'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import useCountry from '../../hooks/useCountry'
import { Loader } from '../globals'

const customStyles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '850px',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const DocumentFormQuote = ({ categories, allowCreateOrder, customer }) => {
  // const { userLocation } = useContext(AppContext)
  const Country = useCountry()

  const layoutContext = useContext(AuthLayoutContext)

  const [formData, setFormData] = useState({
    sender_country_code: 'NG',
    sender_state: '',
    sender_city: '',
    receiver_country_code: 'US',
    receiver_state: '',
    receiver_city: '',
    type: 'IN',
    tpl_service: '',
    package_insurance: 'FR',
    packages: {
      type: 'document',
      itemsValue: '',
      packages: [
        {
          unitMeasurement: 'KGS',
          actualWeight: '',
          predefinedDimension: ''
        }
      ]
    }
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [quoteData, setQuoteData] = useState()
  const [cityNotListed, setCityNotListed] = useState({
    sender: false,
    receiver: false
  })
  const [stateNotListed, setStateNotListed] = useState({
    sender: false,
    receiver: false
  })

  const [packagingErrors, setPackagingErrors] = useState(null)

  const toast = useToast()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const senderStates = useMemo(() => {
    const states = State.getStatesOfCountry(formData.sender_country_code)
    const state = states[0]
    // states.find(({ name }) => name === userLocation?.state?.long_name) ||

    setFormData(prevState => ({ ...prevState, sender_state: state.isoCode }))
    return states
  }, [formData.sender_country_code])

  const senderCities = useMemo(() => {
    if (
      !stateNotListed.sender &&
      formData.sender_country_code &&
      formData.sender_state
    ) {
      const cities = City.getCitiesOfState(
        formData.sender_country_code,
        formData.sender_state
      )
      const city = cities[0]
      // cities.find(({ name }) => name === userLocation?.city?.long_name) ||

      setFormData(state => ({ ...state, sender_city: city.name }))
      return cities
    }
    return []
  }, [
    formData.sender_country_code,
    formData.sender_state,
    stateNotListed.sender
  ])

  const receiverStates = useMemo(() => {
    const states = State.getStatesOfCountry(formData.receiver_country_code)
    setFormData(state => ({ ...state, receiver_state: states[0].isoCode }))
    return states
  }, [formData.receiver_country_code])

  const receiverCities = useMemo(() => {
    if (
      !stateNotListed.receiver &&
      formData.receiver_country_code &&
      formData.receiver_state
    ) {
      const cities = City.getCitiesOfState(
        formData.receiver_country_code,
        formData.receiver_state
      )
      setFormData(state => ({ ...state, receiver_city: cities[0]?.name }))
      return cities
    }
    return []
  }, [
    formData.receiver_country_code,
    formData.receiver_state,
    stateNotListed.receiver
  ])

  const addPackage = () => {
    setFormData(state => ({
      ...state,
      packages: {
        ...state.packages,
        packages: [
          ...state.packages.packages,
          {
            unitMeasurement: 'KGS',
            actualWeight: '',
            predefinedDimension: ''
          }
        ]
      }
    }))

    scrollToTarget('packages-footer', layoutContext?.layoutContainer)
  }

  const removePackage = id => {
    setFormData(state => {
      return {
        ...state,
        packages: {
          ...state.packages,
          packages: state.packages.packages.filter(
            (item, item_id) => item_id !== id
          )
        }
      }
    })
  }

  const handleFieldChange = (value, name, category = null, id = null) => {
    switch (category) {
      case 'itemsValue':
        setFormData(state => {
          const newData = { ...state }
          newData.packages.itemsValue = value ? Number(value) : ''
          return newData
        })
        break
      case 'packages':
        setFormData(state => {
          const newData = { ...state }
          if (name === 'predefinedDimension') {
            delete newData.packages.packages[id].packageDimension
          }
          newData.packages.packages[id][name] = value ? Number(value) : ''
          return newData
        })
        break
      default:
        setFormData(state => ({ ...state, [name]: value }))
        break
    }
  }

  const handlePackageDimension = (dimension, index) => {
    setFormData(state => {
      const packages = [...state.packages.packages]
      delete packages[index].predefinedDimension
      packages[index].packageDimension = dimension
      return {
        ...state,
        packages: {
          ...state.packages,
          packages
        }
      }
    })
  }

  const handleOnSubmit = async e => {
    e.preventDefault()

    setPackagingErrors(null)
    const packagingErrors = []

    for (let i = 0; i < formData.packages.packages.length; i++) {
      const item = formData.packages.packages[i]
      const isDimension = !!(
        item.predefinedDimension ||
        (item.packageDimension?.length &&
          item.packageDimension?.width &&
          item.packageDimension?.height &&
          item.packageDimension?.weight)
      )
      if (!isDimension) {
        packagingErrors.push(`${i}`)
      }
    }

    if (packagingErrors.length) {
      setPackagingErrors(packagingErrors)
      setTimeout(() => {
        scrollToTarget(
          '.packaging-error.block',
          layoutContext?.layoutContainer,
          150
        )
      }, 500)
      return
    }

    setIsModalOpen(true)
    setLoading(true)

    const payload = {
      sender: {
        address: {
          country: Country.getCountryByCode(formData.sender_country_code).name,
          country_code: formData.sender_country_code,
          state_or_province_code: formData.sender_state,
          city: formData.sender_city
        }
      },
      receiver: {
        address: {
          country: Country.getCountryByCode(formData.receiver_country_code)
            .name,
          country_code: formData.receiver_country_code,
          state_or_province_code: formData.receiver_state,
          city: formData.receiver_city
        }
      },
      receiver_country_code: formData.receiver_country_code,
      tpl_service: formData.tpl_service,
      packages: {
        ..._.cloneDeep(formData.packages),
        itemsValue: formData.packages.itemsValue
      },
      type: formData.type,
      package_insurance: formData.package_insurance,
      fish_shipment: false
    }

    payload.packages.packages = payload.packages.packages.map(item => {
      if (item.packageDimension) {
        ;['height', 'length', 'width', 'weight'].forEach(property => {
          item.packageDimension[property] = Number(
            item.packageDimension[property]
          )
        })
        return item
      }
      return item
    })

    if (payload.package_insurance === 'PM') {
      payload.insured_value = Number(formData.insured_value)
    }

    if (customer === 'Partners') {
      payload.partner = true
    }

    if (customer === 'E-commerce') {
      payload.shipa_or_ecommerce = true
    }

    const response = await ordersApi.generateQuote(payload)

    if (!response.ok) {
      setLoading(false)
      setIsModalOpen(false)
      setError(true)
      const apiError = parseError(response)
      if (apiError) {
        if (apiError.data.status_code >= 500) {
          toast(apiError.data.message || apiError.data.detail.message, 'error')
        } else {
          toast(apiError.data.error_detail, 'error')
        }
      }

      return
    }

    setError(false)
    setQuoteData(response.data[0].payload)

    setLoading(false)
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={customStyles}
        appElement={document.getElementById('root')}
      >
        {loading ? (
          <Loader />
        ) : error ? null : (
          <TempQuote
            data={quoteData}
            onClose={() => setIsModalOpen(false)}
            setLoading={setLoading}
            formData={formData}
            allowCreateOrder={allowCreateOrder}
            customer={customer}
          />
        )}
      </Modal>
      <div className='py-10 w-full md:py-14 flex justify-center flex-col'>
        <div className='bg-white'>
          <AppForm
            onSubmit={handleOnSubmit}
            padding='0'
            id='document_form_quote'
          >
            <div className='px-4 md:px-8 space-y-6'>
              <AppFormField
                title='Declared Value'
                name='itemsValue'
                type='number'
                showNaira
                min='1'
                max='200000'
                value={formData.packages.itemsValue}
                handleOnChange={(val, name) =>
                  handleFieldChange(val, name, 'itemsValue')
                }
                required
              />

              <div className='gap-6 flex items-center'>
                <AppFormSelectField
                  name='tpl_service'
                  title='Carrier'
                  required
                  handleOnChange={handleFieldChange}
                  value={formData.tpl_service}
                >
                  <option value=''>Select</option>
                  <option value='DHL'>Express by DHL</option>
                  <option value='FIE'>Economy by FedEx</option>
                  <option value='FIP' disabled>
                    Priority by FedEx
                  </option>
                  <option value='UPS'>Economy by UPS</option>
                </AppFormSelectField>
              </div>

              <AppFormSelectField
                title='Package Insurance'
                name='package_insurance'
                value={formData.package_insurance}
                handleOnChange={handleFieldChange}
                required
              >
                <option value=''>Select</option>
                <option value='FR'>Free</option>
                {/* <option value='SD'>Standard</option> */}
                <option value='PM'>Premium</option>
              </AppFormSelectField>
              {formData.package_insurance === 'PM' && (
                <AppFormField
                  name='insured_value'
                  title='Insured Value'
                  type='number'
                  showNaira
                  value={formData.insured_value}
                  handleOnChange={handleFieldChange}
                  required
                />
              )}
            </div>

            <div className='flex flex-col md:flex-row gap-6 px-4 md:px-8 py-10'>
              <div className='w-full'>
                <h4 className='mb-4 text-xl'>Origin</h4>
                <div className='space-y-6 w-full'>
                  {/* SENDER STATE */}
                  <AppFormSelectField
                    name='sender_country_code'
                    title='Country'
                    handleOnChange={handleFieldChange}
                    value={formData.sender_country_code}
                    required
                    disabled
                  >
                    {Country.getAllCountries().map((country, index) => (
                      <option
                        key={index}
                        value={country.isoCode}
                        disabled={
                          formData.receiver_country_code === country.isoCode
                        }
                      >
                        {country.name}
                      </option>
                    ))}
                  </AppFormSelectField>

                  <div>
                    {stateNotListed.sender ? (
                      <AppFormField
                        name='sender_state'
                        title='State'
                        handleOnChange={handleFieldChange}
                        value={formData.sender_state}
                      />
                    ) : (
                      <AppFormSelectField
                        name='sender_state'
                        title='State'
                        handleOnChange={handleFieldChange}
                        value={formData.sender_state}
                      >
                        {senderStates.map((state, index) => (
                          <option key={index} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                      </AppFormSelectField>
                    )}
                    <button
                      className='text-info hover:text-secondary transition-all text-sm py-2 mt-1'
                      onClick={() =>
                        setStateNotListed(state => ({
                          ...state,
                          sender: !stateNotListed.sender
                        }))
                      }
                      type='button'
                    >
                      {stateNotListed.sender
                        ? 'Select State'
                        : 'State not listed?'}
                    </button>
                  </div>
                  <div>
                    {cityNotListed.sender ? (
                      <AppFormField
                        name='sender_city'
                        title='City'
                        handleOnChange={handleFieldChange}
                        value={formData.sender_city}
                      />
                    ) : (
                      <AppFormSelectField
                        name='sender_city'
                        title='City'
                        handleOnChange={handleFieldChange}
                        value={formData.sender_city}
                      >
                        {senderCities.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </AppFormSelectField>
                    )}
                    <button
                      className='text-info hover:text-secondary transition-all text-sm py-2 mt-1'
                      onClick={() =>
                        setCityNotListed(state => ({
                          ...state,
                          sender: !cityNotListed.sender
                        }))
                      }
                      type='button'
                    >
                      {cityNotListed.sender
                        ? 'Select City'
                        : 'City not listed?'}
                    </button>
                  </div>
                </div>
              </div>

              {/* DIVIDER */}
              <div className='hidden md:divider md:divider-horizontal'></div>

              {/* RECEIVER COUNTRY */}
              <div className='w-full'>
                <h4 className='mb-4 text-xl'>Destination</h4>
                <div className='space-y-6'>
                  <AppFormSelectField
                    name='receiver_country_code'
                    title='Country'
                    handleOnChange={handleFieldChange}
                    value={formData.receiver_country_code}
                    required
                  >
                    {Country.getAllCountries().map((country, index) => (
                      <option
                        key={index}
                        value={country.isoCode}
                        disabled={
                          formData.sender_country_code === country.isoCode
                        }
                      >
                        {country.name}
                      </option>
                    ))}
                  </AppFormSelectField>

                  <div>
                    {stateNotListed.receiver ? (
                      <AppFormField
                        name='receiver_state'
                        title='State'
                        handleOnChange={handleFieldChange}
                        value={formData.receiver_state}
                      />
                    ) : (
                      <AppFormSelectField
                        name='receiver_state'
                        title='State'
                        handleOnChange={handleFieldChange}
                        value={formData.receiver_state}
                      >
                        {receiverStates.map((state, index) => (
                          <option key={index} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                      </AppFormSelectField>
                    )}
                    <button
                      className='text-info hover:text-secondary transition-all text-sm py-2 mt-1'
                      onClick={() =>
                        setStateNotListed(state => ({
                          ...state,
                          receiver: !stateNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {stateNotListed.receiver
                        ? 'Select State'
                        : 'State not listed?'}
                    </button>
                  </div>
                  <div>
                    {cityNotListed.receiver ? (
                      <AppFormField
                        name='receiver_city'
                        title='City'
                        handleOnChange={handleFieldChange}
                        value={formData.receiver_city}
                      />
                    ) : (
                      <AppFormSelectField
                        name='receiver_city'
                        title='City'
                        handleOnChange={handleFieldChange}
                        value={formData.receiver_city}
                      >
                        {receiverCities.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </AppFormSelectField>
                    )}
                    <button
                      className='text-info hover:text-secondary transition-all text-sm py-2 mt-1'
                      onClick={() =>
                        setCityNotListed(state => ({
                          ...state,
                          receiver: !cityNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {cityNotListed.receiver
                        ? 'Select City'
                        : 'City not listed?'}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 mt-10 w-full'>
              <h3 className='text-white'>Packages</h3>
              <button
                onClick={addPackage}
                type='button'
                className='btn btn-sm btn-ghost text-white gap-2 items-center'
              >
                <IoMdAdd size={18} />{' '}
                <span className='hidden md:block'>Add</span>
              </button>
            </div>

            {formData.packages.packages.map((packageItem, index) => (
              <div
                key={index}
                className={`flex flex-col ${
                  index === formData.packages.packages.length - 1 ? 'pb-10' : ''
                }`}
              >
                <div className='flex flex-row gap-2 justify-between items-start sm:items-center px-4 md:px-8'>
                  <h3>Item ({index + 1})</h3>
                  <div className='gap-4 flex items-center justify-end'>
                    {index === formData.packages.packages.length - 1 &&
                      formData.packages.packages.length !== 1 && (
                        <button
                          onClick={addPackage}
                          type='button'
                          className='btn btn-sm gap-2 items-center'
                        >
                          <IoMdAdd size={18} color='black' />{' '}
                          <span className='hidden md:block'>Add</span>
                        </button>
                      )}
                    {index !== 0 && (
                      <button
                        onClick={() => removePackage(index)}
                        type='button'
                        className='btn btn-sm gap-2 items-center'
                      >
                        <IoMdClose size={18} color='black' />{' '}
                        <span className='hidden md:block'>Remove</span>
                      </button>
                    )}
                  </div>
                </div>
                <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8 border-gray-200'>
                  <div className='w-full'>
                    <AppFormField
                      name='actualWeight'
                      title='Actual Weight (kg)'
                      type='number'
                      step='0.01'
                      min='0.01'
                      value={packageItem.actualWeight}
                      handleOnChange={(val, name) =>
                        handleFieldChange(val, name, 'packages', index)
                      }
                      required
                    />
                  </div>
                  <div className='w-full'>
                    <label className='label'>
                      <span className='label-text'>Packaging</span>
                    </label>
                    <SelectPackaging
                      value={`${
                        packageItem.predefinedDimension
                          ? packageItem.predefinedDimension
                          : packageItem.packageDimension?.length &&
                            packageItem.packageDimension?.width &&
                            packageItem.packageDimension?.height &&
                            packageItem.packageDimension?.weight
                          ? 'custom'
                          : ''
                      }`}
                      onSelectPredefined={val =>
                        handleFieldChange(
                          val,
                          'predefinedDimension',
                          'packages',
                          index
                        )
                      }
                      dimension={packageItem.packageDimension}
                      handlePackageDimension={dimension =>
                        handlePackageDimension(dimension, index)
                      }
                      shipmentType={'IN'}
                    />
                    <p
                      className={`packaging-error text-red-500 text-sm ${
                        packagingErrors?.find(pkgId => Number(pkgId) === index)
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      Select a packaging
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div id='packages-footer' />

            <button
              type='submit'
              value='Get Quote'
              className='btn btn-lg text-white btn-primary mx-8'
              form='document_form_quote'
            >
              Submit
            </button>
          </AppForm>
        </div>
      </div>
    </>
  )
}

export default DocumentFormQuote
