import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authApi from '../api/auth'
import cookies from '../utils/cookies'
import store from '../store'
import { parseError, setAuthCredsToCookies } from '../utils'

const handleRefreshToken = async () => {
  store.dispatch(
    authActions.updateAuthCreds({
      isLoading: true,
      isSuccess: false,
      isError: false
    })
  )
  const response = await authApi.refreshToken()

  if (!response.ok) {
    store.dispatch(
      authActions.updateAuthCreds({
        isLoading: false,
        isSuccess: false,
        isError: true
      })
    )
    store.dispatch(authActions.logout())
    return
  }

  store.dispatch(
    authActions.updateAuthCreds({
      isLoading: false,
      isSuccess: true,
      isError: false
    })
  )
  setAuthCredsToCookies(response.data)
  handleSessionTimeout()
}

const handleSessionTimeout = () => {
  const expireAt = cookies.get('expireAt')

  const now = new Date().getTime()
  const session = expireAt - now

  if (session > 1000) {
    setTimeout(() => {
      handleRefreshToken()
    }, session)
  }
}

const isTokenValid = () => {
  const token = cookies.get('authToken')
  if (token) {
    handleSessionTimeout()
    return true
  }

  cookies.remove('user')
  cookies.remove('accessToken')
  cookies.remove('refreshToken')
  cookies.remove('expireAt')

  return false
}

const initialState = {
  isLoggedIn: isTokenValid(),
  user: cookies.get('userData'),
  authCreds: {
    isLoading: false,
    isSuccess: false,
    isError: false
  }
}

export const fetchAccountData = createAsyncThunk('auth/user', async () => {
  const userId = cookies.get('userId')

  if (!userId) {
    return {
      type: 'error',
      error: 'User ID not found'
    }
  }

  const response = await authApi.getAccountData(userId)

  if (!response.ok) {
    const apiError = parseError(response)
    const payload = { type: 'error' }
    if (apiError) {
      payload.error = apiError
    }
    return payload
  }

  return response.data
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login (state, action) {
      setAuthCredsToCookies(action.payload)

      window.location.reload()
    },
    logout (state) {
      const logoutFromServer = async () => {
        state.authCreds.isLoading = true
        await authApi.logout()
        cookies.remove('accessToken')
        cookies.remove('authToken')
        cookies.remove('refreshToken')
        cookies.remove('expireAt')
        cookies.remove('userData')

        window.location.reload()
      }
      logoutFromServer()
    },
    updateAuthCreds (state, action) {
      state.authCreds = action.payload
    },
    cleanupAuthCreds (state) {
      state.authCreds = {
        isLoading: false,
        isSuccess: false,
        isError: false
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAccountData.fulfilled, (state, action) => {
      if (action.payload.type !== 'error') {
        cookies.set('userData', JSON.stringify(action.payload.payload))
        state.user = action.payload.payload
      }
    })
  }
})

export const authActions = authSlice.actions

export default authSlice.reducer
