export default function ShipmentsIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.625 12.2492L9.375 5.76172'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.25 20.5013V10.5013C26.2496 10.0629 26.1338 9.63235 25.9144 9.2528C25.695 8.87324 25.3797 8.55805 25 8.33884L16.25 3.33884C15.87 3.11942 15.4388 3.00391 15 3.00391C14.5612 3.00391 14.13 3.11942 13.75 3.33884L5 8.33884C4.62033 8.55805 4.30498 8.87324 4.08558 9.2528C3.86618 9.63235 3.75045 10.0629 3.75 10.5013V20.5013C3.75045 20.9398 3.86618 21.3703 4.08558 21.7499C4.30498 22.1294 4.62033 22.4446 5 22.6638L13.75 27.6638C14.13 27.8833 14.5612 27.9988 15 27.9988C15.4388 27.9988 15.87 27.8833 16.25 27.6638L25 22.6638C25.3797 22.4446 25.695 22.1294 25.9144 21.7499C26.1338 21.3703 26.2496 20.9398 26.25 20.5013Z'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.0874 9.19922L14.9999 15.5117L25.9124 9.19922'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 28.1V15.5'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
