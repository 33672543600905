import { twMerge } from 'tailwind-merge'

export const customerTabs = [
  {
    name: 'Regulars',
    id: 0,
    disabled: false
  },
  {
    name: 'Partners',
    id: 1,
    disabled: false
  },
  {
    name: 'E-commerce',
    id: 2,
    disabled: false
  }
]

export default function CustomerTabs ({ activeTab, onSelectTab }) {
  return (
    <div
      role='tablist'
      className='tabs tabs-boxed w-fit gap-4 bg-white border border-primary'
    >
      {customerTabs.map((tab, id) => (
        <span
          role='tab'
          className={twMerge(
            'tab transition-all md:h-fit md:py-1.5 md:px-2',
            activeTab.id === tab.id ? 'tab-active' : '',
            tab.disabled
              ? 'opacity-50 hover:opacity-50 cursor-not-allowed'
              : 'opacity-100'
          )}
          key={id}
          onClick={() => !tab.disabled && onSelectTab(tab)}
        >
          {tab.name}
        </span>
      ))}
    </div>
  )
}
