import React, { useEffect, useMemo, useState } from 'react'
import FilterButton from '../../../components/globals/filter/FilterButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Page from '../../../containers/Page'
import Search from '../../../components/globals/Search/Search'
import ROUTES from '../../../constants/routes'
import Regulars from '../../../components/organization/external/Regulars'
import Partners from '../../../components/organization/external/Partners'
import Ecommerce from '../../../components/organization/external/Ecommerce'
import ExternalOrganizationFilter from '../../../components/organization/external/ExternalOrganizationFilter'
import CustomerDetailsModal from './CustomerDetailsModal'
import useCountry from '../../../hooks/useCountry'

const tabs = [
  {
    name: 'Regulars',
    value: 'regulars'
  },
  {
    name: 'Partners',
    value: 'partners'
  },
  {
    name: 'E-commerce',
    value: 'e-commerce'
  }
]

const breadCrumbs = [
  {
    name: 'Organization',
    path: '',
    disabled: true
  },
  {
    name: 'External',
    path: ROUTES.ORGANIZATION.EXTERNAL.path
  }
]

export default function ExternalOrganization ({ metaTitle }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('full_name')
  const [serverSearch, setServerSearch] = useState(null)
  const [activeTab, setActiveTab] = useState(
    searchParams.get('active')
      ? tabs.find(tab => tab.name === searchParams.get('active'))
      : tabs[0]
  )
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    code: '',
    state: ''
  })
  const [filterTags, setFilterTags] = useState([])
  const [activeCustomer, setActiveCustomer] = useState({
    isOpen: false,
    id: ''
  })

  const navigate = useNavigate()
  const Country = useCountry()

  const searchOptions = useMemo(() => {
    const options = [
      {
        name: 'Contact Name',
        value: 'full_name'
      }
    ]
    if (activeTab.value !== 'regulars') {
      options.push({
        name: 'Company Name',
        value: 'business_name'
      })
    }

    return options
  }, [activeTab.value])

  const searchInputPlaceHolder = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (searchBy) {
      case 'full_name':
        return 'Enter customer name'
      case 'business_name':
        return 'Enter company name'
    }
  }, [searchBy])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'code') {
          params.code = filter[key]
          let tag = {
            name: 'Country',
            value: Country.getCountryByCode(filter[key]).name
          }
          tags.push(tag)
        } else if (key === 'state') {
          params.state = filter[key]
          let tag = {
            name: key,
            value: filter[key]
          }
          tags.push(tag)
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const showFilter = () => {
    setIsFilterOpen(true)
  }
  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }
  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const onSelectTab = tab => {
    setActiveTab(tab)
    searchParams.set('active', tab.name)
    setSearchParams(searchParams)
    setQueryParams(state => ({ ...state, page: 1 }))
  }

  const closeFilter = () => setIsFilterOpen(false)

  const onFilterDelete = key => {
    key = key === 'Country' ? 'code' : key
    setFilter(state => ({
      ...state,
      [key]: ''
    }))

    let query = { ...queryParams }
    delete query[key]

    setQueryParams(query)
  }

  const handleSelectRow = data => {
    setActiveCustomer({ isOpen: true, id: data.id })
  }

  const handleCustomerEdit = customer_id => {
    navigate(
      `${ROUTES.ORGANIZATION.EXTERNAL.CREATE_NEW_EXTERNAL.path}?t=${activeTab.name}&action=edit&id=${customer_id}`
    )
  }

  return (
    <Page metaTitle={metaTitle} noPadding>
      <Page.Header title='Organization' breadCrumbs={breadCrumbs}>
        <Search
          value={searchValue}
          multiple
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch
          onServerSearch={handleServerSearch}
        />
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isFilterOpen && (
          <ExternalOrganizationFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            filter={filter}
            setFilter={setFilter}
          />
        )}
        {activeCustomer.isOpen && (
          <CustomerDetailsModal
            isOpen={activeCustomer.isOpen}
            customerId={activeCustomer.id}
            onClose={() => setActiveCustomer({ isOpen: false, data: null })}
            onEdit={handleCustomerEdit}
          />
        )}

        {activeTab.name === 'Regulars' && (
          <Regulars
            tabs={tabs}
            activeTab={activeTab}
            onSelectTab={onSelectTab}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            searchBy={searchBy}
            searchValue={searchValue}
            serverSearch={serverSearch}
            onCloseServerSearch={onCloseServerSearch}
            filterTags={filterTags}
            onFilterDelete={onFilterDelete}
            rowAction={handleSelectRow}
            onEdit={handleCustomerEdit}
          />
        )}
        {activeTab.name === 'Partners' && (
          <Partners
            tabs={tabs}
            activeTab={activeTab}
            onSelectTab={onSelectTab}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            serverSearch={serverSearch}
            onCloseServerSearch={onCloseServerSearch}
            filterTags={filterTags}
            onFilterDelete={onFilterDelete}
            rowAction={handleSelectRow}
            onEdit={handleCustomerEdit}
          />
        )}
        {activeTab.name === 'E-commerce' && (
          <Ecommerce
            tabs={tabs}
            activeTab={activeTab}
            onSelectTab={onSelectTab}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            serverSearch={serverSearch}
            onCloseServerSearch={onCloseServerSearch}
            filterTags={filterTags}
            onFilterDelete={onFilterDelete}
            rowAction={handleSelectRow}
            onEdit={handleCustomerEdit}
          />
        )}
      </Page.Body>
    </Page>
  )
}
