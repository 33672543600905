import React, { useMemo, useState, useEffect } from 'react'
import AppForm from '../../../components/globals/Form/AppForm.jsx'
import AppFormField from '../../../components/globals/Form/AppFormField.jsx'
import AppFormSelectField from '../../../components/globals/Form/AppFormSelectField.jsx'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import nigerianStates from '../../../fixtures/states.js'
import useCountry from '../../../hooks/useCountry.js'
import { fetchBranches } from '../../../slices/orgsSlice.js'
import orgsApi from '../../../api/orgs.js'
import useToast from '../../../hooks/useToast.js'
import SuccessModal from '../../../components/organization/SuccessModal.jsx'
import { useNavigate } from 'react-router-dom'
import accountsApi from '../../../api/accounts.js'

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Region Name is required')
    .min(2, 'Region Name must be at least 2 characters'),
  state: Yup.string().required('State is required'),
  manager: Yup.string().required('Region Manager is required'),
  country: Yup.string().required('Country is required')
})

function RegionForm ({ editId }) {
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [employeeList, setEmployeeList] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: '',
    state: '',
    manager: '',
    country: ''
  })

  const orgs = useSelector(state => state.orgs)
  const Country = useCountry()
  const dispatch = useDispatch()
  const toast = useToast()
  const navigate = useNavigate()
  const states = nigerianStates.map(item => item.name)
  const userData = useSelector(state => state.auth.user)

  useEffect(() => {
    dispatch(fetchBranches())
  }, [dispatch])

  useEffect(() => {
    const getAccounts = async () => {
      const response = await accountsApi.listAccounts()
      setEmployeeList(response.data.payload)
    }
    getAccounts()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (editId) {
        try {
          const response = await orgsApi.getSingleRegion(editId)
          const region = response.data

          setInitialValues({
            name: region.name,
            state: '',
            manager: region.manager,
            country: Country.getAllCountries().find(
              country => country.name === region.country
            ).isoCode
          })
        } catch (error) {
          console.error('Failed to fetch region:', error)
        }
      }
    }

    fetchData()
  }, [editId])

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)
    setLoading(true)

    const payload = {
      name: values.name,
      state: values.state,
      manager: values.manager,
      country: Country.getAllCountries().find(
        item => item.isoCode === values.country
      ).name,
      organization: userData.organisation.oid
    }

    let response

    if (editId) {
      response = await orgsApi.editRegion(editId, payload)
    } else {
      response = await orgsApi.createRegion(payload)
    }

    setLoading(false)
    if (!response.ok) {
      const apiError = response.error || 'Unable to create area'
      toast(apiError)
      return
    }
    setSuccessModal(true)
  }

  const handleSuccessModalAction = () => {
    setSuccessModal(false)
    navigate('/organization/internal')
  }

  return (
    <div className='bg-white w-full px-7 pb-[50px] pt-8 mt-8'>
      {successModal && (
        <SuccessModal
          isOpen={successModal}
          title='Region created successfully'
          onClose={handleSuccessModalAction}
        />
      )}
      <h1 className='text-xl font-semibold mb-8'>Region Details</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize // Important for updating initial values when they change
        onSubmit={handleFormSubmit}
      >
        {formik => {
          const { values, isSubmitting, isValid, handleSubmit } = formik
          return (
            <AppForm id='region_form' padding='0'>
              <div className='flex flex-row gap-6'>
                <AppFormField
                  name='name'
                  title='Region Name'
                  placeholder='Region Name'
                />
                <AppFormSelectField name='country' title='Country'>
                  <option value=''>Select Country</option>
                  {Country.getAllCountries().map((country, index) => (
                    <option key={index} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </AppFormSelectField>
              </div>
              <div className='flex flex-row gap-6'>
                <AppFormSelectField name='state' title='State'>
                  <option value=''>Select state</option>
                  {states?.map((state, ind) => (
                    <option key={ind} value={state}>
                      {state}
                    </option>
                  ))}
                </AppFormSelectField>
                <AppFormSelectField name='manager' title='Branch Manager'>
                  <option value=''>Select branch manager</option>
                  {employeeList?.map((emp, ind) => (
                    <option key={ind} value={emp.id}>
                      {emp.first_name} {emp.last_name}
                    </option>
                  ))}
                </AppFormSelectField>
              </div>

              <div className='flex items-center justify-end'>
                <button
                  className={`btn ${
                    isValid ? 'btn-primary' : 'btn-neutral btn-disabled'
                  }`}
                  disabled={!isValid || isSubmitting}
                  type='button'
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <CircularProgress size={28} color='inherit' />
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </AppForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default RegionForm
