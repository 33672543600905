import Modal from 'react-modal'
import * as yup from 'yup'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import { useState } from 'react'
import { SiMinutemailer } from 'react-icons/si'
import ordersApi from '../../api/orders'
import useToast from '../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'

const customStyles = {
  content: {
    inset: '16px',
    height: 'fit-content',
    margin: 'auto',
    maxWidth: '672px',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please provide a valid email address')
    .required('Email address is required')
})

export default function SendQuote ({ isOpen, onClose, id }) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const handleSubmit = async values => {
    setLoading(true)
    const response = await ordersApi.sendQuoteViaEmail(id, values)

    if (!response.ok) {
    } else {
      toast(`Quote successfully sent to ${values.email}!`)
      setTimeout(onClose, 2000)
    }
    setLoading(false)
  }

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div className='w-full max-w-2xl m-auto'>
        <h2 className='text-lg md:text-xl font-bold text-center mb-4'>
          Send Quote to Customer via Email
        </h2>

        <div className='bg-white'>
          <Formik
            initialValues={{
              email: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => (
              <AppForm onSubmit={handleSubmit} id='send-quote-form'>
                <AppFormField
                  title='Customer Email'
                  name='email'
                  type='email'
                />
                <div className='pt-3'>
                  <button
                    type='submit'
                    className='btn btn-primary text-white w-28'
                    form='send-quote-form'
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size={28}
                        style={{ color: '#ffffff' }}
                      />
                    ) : (
                      <>
                        Send
                        <SiMinutemailer size={18} className='ml-2' />
                      </>
                    )}
                  </button>
                </div>
              </AppForm>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
