import React, { useMemo, useRef, useState, useEffect } from 'react'
import { ReactComponent as Caret } from '../../../assets/dropdown-caret.svg'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { MdCheck } from 'react-icons/md'

const Dropdown = ({ show, options, selected, onSelect, footer }) => {
  return (
    <div
      className={twMerge(
        'shadow-lg py-4 absolute bg-white origin-top top-full max-h-[300px] overflow-y-auto border transition-all z-10 w-60',
        show ? 'scale-y-1' : 'scale-y-0'
      )}
    >
      <ul className='divide-y'>
        {options.map((option, id) => (
          <li
            tabIndex={0}
            key={id}
            onClick={() => onSelect(option.value)}
            className={`py-2 pl-4 pr-4 bg-white hover:bg-g-100 transition-all text-dark-primary cursor-pointer w-full relative`}
          >
            <div>{option.name}</div>
            {/* eslint-disable-next-line eqeqeq */}
            {selected == option.value && (
              <span className='absolute top-2.5 right-4'>
                <MdCheck size={14} color={'#fe6802'} />
              </span>
            )}
          </li>
        ))}
      </ul>

      {footer && (
        <div className='mt-4 border-t border-[#F2F2F2] pt-2'>
          <div
            className={`py-2 px-4 ${
              // eslint-disable-next-line eqeqeq
              selected == footer.value
                ? 'bg-[#FFF6D7]'
                : 'bg-white hover:bg-g-100'
            } transition-all text-dark-primary cursor-pointer`}
            onClick={() => onSelect(footer.value)}
          >
            {footer.name}
          </div>
        </div>
      )}
    </div>
  )
}

const DimensionInput = ({
  name,
  value,
  onChange,
  placeholder,
  title,
  allowWeight,
  ...restProps
}) => {
  return (
    <div className='text-sm flex flex-col'>
      <label className='label text-xs' htmlFor={name}>
        {title}
      </label>
      <input
        type='number'
        name={name}
        placeholder={placeholder}
        id={name}
        step='0.01'
        min='0.01'
        value={value}
        onChange={onChange}
        onWheel={e => e.target.blur()}
        required
        {...restProps}
        className={`w-full p-0.5 border border-gray-200 focus:border-gray-200 focus-visible:outline-0 rounded-[4px] text-sm mt-auto ${
          name === 'weight'
            ? allowWeight
              ? 'bg-white'
              : 'bg-gray-100 cursor-not-allowed'
            : 'bg-white'
        }`}
      />
    </div>
  )
}

const CustomPackaging = ({ values, handlePackageDimension, shipmentType }) => {
  const [allowWeight, setAllowWeight] = useState(false)
  const [_dimension, set_Dimension] = useState(values)

  const toggleAllowWeight = e => {
    e.stopPropagation()
    setAllowWeight(state => !state)
  }

  const handleChange = ({ target }) => {
    set_Dimension(state => ({
      ...state,
      [target.name]: `${target.value}` ? Number(target.value) : target.value
    }))
  }

  useEffect(() => {
    if (!allowWeight) {
      if (_dimension?.length && _dimension?.width && _dimension?.height) {
        const rawValue =
          (Number(_dimension.length) *
            Number(_dimension.width) *
            Number(_dimension.height)) /
          5000
        const weight = Number(rawValue.toFixed(2))
        set_Dimension(state => ({
          ...state,
          weight,
          price: shipmentType === 'IN' ? 3000 : 0
        }))
      } else {
        set_Dimension(state => ({ ...state, weight: '', price: '' }))
      }
    }
  }, [
    _dimension.length,
    _dimension.width,
    _dimension.height,
    allowWeight,
    shipmentType
  ])

  useEffect(() => {
    if (allowWeight) {
      if (_dimension?.weight) {
        const price = _dimension.weight <= 2 ? 1500 : 4000
        set_Dimension(state => ({ ...state, price }))
      }
    }
  }, [_dimension?.weight, allowWeight])

  useEffect(() => {
    handlePackageDimension(_dimension)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_dimension])

  return (
    <div className='pb-2 flex flex-col gap-2'>
      <div className='flex w-full justify-end'>
        <label
          className='text-xs flex items-center gap-2 cursor-pointer'
          htmlFor='allow_weight'
        >
          Allow weight{' '}
          <input
            type='checkbox'
            name='allow_weight'
            className='radio radio-sm radio-primary rounded-md'
            value={allowWeight}
            checked={allowWeight}
            onChange={toggleAllowWeight}
            id='allow_weight'
          />
        </label>
      </div>
      <div className='grid grid-cols-4 gap-1'>
        <DimensionInput
          name='length'
          value={values?.length}
          onChange={handleChange}
          placeholder='Length'
          title='Length (cm)'
        />
        <DimensionInput
          name='width'
          value={values?.width}
          onChange={handleChange}
          placeholder='Width'
          title='Width (cm)'
        />
        <DimensionInput
          name='height'
          value={values?.height}
          onChange={handleChange}
          placeholder='Height'
          title='Height (cm)'
        />
        <DimensionInput
          name='weight'
          value={values?.weight}
          onChange={handleChange}
          placeholder='Weight'
          title='Weight (kg)'
          readOnly={!allowWeight}
          allowWeight={allowWeight}
        />
      </div>
      <div className='flex items-center justify-end text-right w-full'>
        <span className='text-sm'>
          Price:{' '}
          <span className='inline-block min-w-[4.5rem]'>
            {(_dimension?.price || 0).toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </span>
      </div>
    </div>
  )
}

export default function SelectPackaging ({
  value,
  onTouch = () => {},
  onSelectPredefined,
  dimension = {
    length: null,
    width: null,
    height: null,
    weight: null,
    price: null
  },
  handlePackageDimension,
  shipmentType
}) {
  const { data: predefinedDimensions } = useSelector(state => state.dimensions)

  const [show, setShow] = useState(false)

  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setShow(prevState => {
      if (prevState) onTouch()
      return !prevState
    })
  }

  useOnClickOutside(dropdownRef, show, toggleDropdown)

  const handleSelect = val => {
    if (val !== 'custom') {
      onSelectPredefined(val)
      toggleDropdown()
    }
  }

  const options = useMemo(() => {
    if (predefinedDimensions) {
      return [
        {
          name: (
            <Disclosure>
              <DisclosureButton>Custom Packaging</DisclosureButton>
              <Transition
                enter='transition origin-top duration-150 ease-out'
                enterFrom='transform scale-y-0 opacity-0'
                enterTo='transform scale-y-100 opacity-100'
                leave='transition origin-top duration-100 ease-out'
                leaveFrom='transform scale-y-100 opacity-100'
                leaveTo='transform scale-y-0 opacity-0'
              >
                <DisclosurePanel className='text-gray-500'>
                  <CustomPackaging
                    values={dimension}
                    handlePackageDimension={handlePackageDimension}
                    shipmentType={shipmentType}
                  />
                </DisclosurePanel>
              </Transition>
            </Disclosure>
          ),
          value: 'custom'
        },
        ...predefinedDimensions.map(dmsn => {
          return {
            name: (
              <div className='flex flex-col w-full'>
                <span className='font-medium'>{dmsn.name}</span>
                <em className='text-sm'>
                  {dmsn.length} x {dmsn.height} x {dmsn.width} cm ({dmsn.weight}{' '}
                  kg)
                </em>
              </div>
            ),
            value: dmsn.id
          }
        })
      ]
    }
    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, predefinedDimensions])

  return (
    <div className='w-full flex items-center relative' ref={dropdownRef}>
      <div
        className={`flex items-center justify-between gap-2 border border-[#CBCBCB] w-full py-2 px-4 rounded-md text-sm ${
          value ? 'text-inherit' : 'text-[#6b7281]'
        } cursor-pointer [3rem]`}
        onClick={toggleDropdown}
      >
        <span>
          {value ? (
            value === 'custom' ? (
              <div className='flex flex-col w-full'>
                <span className='font-medium'>Custom Packaging</span>
                <em className='text-sm'>
                  {dimension?.length} x {dimension?.height} x {dimension?.width}{' '}
                  cm ({dimension?.weight} kg)
                </em>
              </div>
            ) : (
              // eslint-disable-next-line eqeqeq
              options.find(opt => opt.value == value)?.name
            )
          ) : (
            'Select Packaging'
          )}
        </span>
        <Caret />
      </div>
      <Dropdown
        show={show}
        options={options}
        selected={value}
        onSelect={handleSelect}
        width='w-full'
      />
    </div>
  )
}
