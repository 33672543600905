import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { useMemo } from 'react'

export default function ShipmentStatuses ({ data }) {
  const statuses = useMemo(
    () => [
      {
        name: 'Pending',
        value: data.pending,
        id: 0
      },
      {
        name: 'In transit',
        value: data.in_transit,
        id: 2
      },
      {
        name: 'Out for delivery',
        value: data.out_for_delivery,
        id: 3
      },
      {
        name: 'Delivered',
        value: data.delivered,
        id: 4
      },
      {
        name: 'Exception',
        value: data.exception,
        id: 5
      },
      {
        name: 'Available for pickup',
        value: data.available_for_pickup_by_customer,
        id: 6
      },
      {
        name: 'Voided',
        value: data.voided,
        id: 7
      }
    ],
    [data]
  )

  return (
    <div className='bg-white rounded-xl shadow-md-right h-full'>
      <div className='py-4 px-4 border-b border-[#D4D1D1]'>
        <h6 className='font-semibold text-base'>Shipments Status</h6>
      </div>
      <div className='py-2 px-4 text-sm flex flex-col gap-3'>
        {statuses.map((status, id) => (
          <div key={id} className='flex w-full items-center justify-between'>
            <Link
              to={`${ROUTES.SHIPMENTS.path}?status=${status.id}`}
              className='hover:underline hover:text-primary'
            >
              <span className='opacity-70'>{status.name}</span>
            </Link>
            <span className='font-medium'>
              {status?.value?.toLocaleString()}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
