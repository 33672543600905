import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import noDataIcon from '../../../assets/no-data.webp'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Check } from '../../../assets/check.svg'
import { LiaTimesSolid } from 'react-icons/lia'
import { capitalizeFirstLetter } from '../../../utils'
import { fetchAllManifestShipments } from '../../../slices/allInManifestShipments'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export const CreateInternationalCenterModal = ({
  centerModal,
  setCenterModal,
  createManifest,
  setDestId,
  allHubs
}) => {
  const dispatch = useDispatch()
  const shipmentsStore = useSelector(state => state?.allShipments)
  const userData = useSelector(state => state.auth.user)
  const [disabled, setDisabled] = useState(true)
  const [typeCenter, setTypeCenter] = useState('')
  const [pageCount, setPageCount] = useState(1)
  const [openView, setOpenView] = useState(false)
  const [hubsList, setHubsList] = useState([])

  useEffect(() => {
    const cloneHubs = allHubs?.filter(hub => hub?.category === 'GATEWAY_HUB')
    setHubsList(cloneHubs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pageCount === 0) {
      setPageCount(1)
    }
  }, [pageCount])

  const handleIncrement = () => {
    if (pageCount >= 1) {
      setPageCount(pageCount + 1)
    }
  }

  const handleDecrement = () => {
    if (pageCount >= 1) {
      setPageCount(pageCount - 1)
    }
  }

  useEffect(() => {
    dispatch(
      fetchAllManifestShipments({
        page_size: 50,
        branch: userData?.branch?.name,
        order_type: 'IN',
        status: 1,
        in_manifest: 'False',
        page: pageCount,
        tpl: typeCenter
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageCount, typeCenter])

  return (
    <div>
      <Modal
        isOpen={centerModal}
        onRequestClose={setCenterModal}
        style={styles}
        appElement={document.getElementById('root')}
      >
       <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
          onClick={() => {
            if (typeCenter !== '') {
              setTypeCenter('')
              setOpenView(false)
              setDisabled(true)
            } else {
              setCenterModal(false)
              setTypeCenter('')
              setDestId('')
              setDisabled(true)
              setOpenView(false)
            }
          }}
        >
          <LiaTimesSolid size={24} />
        </button>

        {typeCenter !== '' && (
          <>
            <div className='w-full text-xl flex items-center justify-center'>
              <div>Avalaible shipments</div>
            </div>

            {shipmentsStore?.count !== null && shipmentsStore?.count > 1 && (
              <div className='w-full text-sm flex items-center justify-center mt-4 space-x-3'>
                <span className='cursor-pointer' onClick={handleDecrement}>
                  Previous
                </span>
                <span className='cursor-pointer' onClick={handleIncrement}>
                  Next
                </span>
              </div>
            )}

            {!shipmentsStore?.data ? (
              <div className='flex flex-col justify-center items-center mt-4'>
                <CircularProgress size={24} color='inherit' />
              </div>
            ) : shipmentsStore?.data?.length === 0 ? (
              <div className='w-full flex justify-center items-center'>
                <div className='pt-14 w-full flex flex-col items-center justify-center'>
                  <img
                    src={noDataIcon}
                    className='w-20 h-20 object-contain'
                    alt='no data icon'
                  />
                  <p className='text-gray-500 text-left my-2 font-semibold text-sm'>
                    No shipment found
                  </p>
                </div>
              </div>
            ) : (
              <div className='w-full py-4 px-4 flex flex-col overflow-y-scroll'>
                {shipmentsStore?.data?.map((item, id) => (
                  <div className='flex space-x-4 p-1' key={id}>
                    <Check /> <div>{item?.tracking_id}</div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        <div className='w-full flex flex-col justify-around items-center px-14'>
          {!openView && (
            <>
              <div>TPL Services</div>
              <select
                name='period'
                className='w-3/4 h-[40px] mt-4'
                onChange={e => {
                  setTypeCenter(e.target.value)
                  setOpenView(true)
                  setDisabled(false)
                  setDisabled(false)
                }}
              >
                <option className='bg-white' value={''}>
                  {' '}
                  Select TPL
                </option>
                {['UPS', 'DHL', 'FDX', 'AMX']?.map((tpl, id) => (
                  <option className='bg-white' value={tpl} key={id}>
                    {capitalizeFirstLetter(tpl)}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>

        <div className='w-full flex justify-center'>
          {shipmentsStore?.data?.length !== 0 && (
            <>
              {typeCenter !== '' && (
                <select
                  name='period'
                  className='w-3/4 h-[40px] mt-4'
                  onChange={e => {
                    setDisabled(false)
                    setDestId(e.target.value)
                  }}
                >
                  <option className='bg-white' value={''}>
                    {' '}
                    Select destinations
                  </option>
                  {hubsList?.map((hub, id) => (
                    <option className='bg-white' value={hub?.id} key={id}>
                      {capitalizeFirstLetter(hub?.name)}, Gate way hub
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </div>

        <div className='w-full flex justify-end mt-12'>
          {typeCenter !== '' && (
            <button
              className={`w-[150px] h-[50px] btn ${
                disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
              } btn-rounded`}
              disabled={disabled}
              onClick={() => {
                createManifest(typeCenter)
                setCenterModal(false)
                setTypeCenter('')
                setOpenView(false)
              }}
            >
              Proceed
            </button>
          )}
        </div>
      </Modal>
    </div>
  )
}
