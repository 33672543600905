import axios from 'axios'
import cookies from '../../utils/cookies'

const hmsClient = axios.create({
  baseURL: `${process.env.REACT_APP_HMS_CLIENT_API_BASE_URL}`,
  timeout: process.env.REACT_APP_API_TIMEOUT
})

hmsClient.interceptors.request.use(
  function (config) {
    const authToken = cookies.get('authToken')
    if (!authToken) return

    config.headers.Authorization = 'Token ' + authToken
    return config
  },
  error => {
    return error
  }
)

hmsClient.interceptors.response.use(
  response => {
    response.ok = true
    return response
  },
  error => {
    if (error.response) {
      error.response.ok = false
    }

    return error
  }
)

export default hmsClient
