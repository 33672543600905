import React, { useContext, useEffect, useMemo } from 'react'
import packageImage from '../../assets/package.webp'
import document from '../../assets/document.webp'
import fishSnail from '../../assets/fish-snail.webp'
import electronics from '../../assets/electronics.svg'
import haulage from '../../assets/haulage.svg'
import { Navigate, useSearchParams, useLocation } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { useSelector } from 'react-redux'
import { Loader } from '../../components/globals'
import { urlQueryStringToObject } from '../../utils'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import { FiCheckCircle } from 'react-icons/fi'

const Item = ({ handleOnClick, image, text, disabled, selected }) => {
  return (
    <button
      className={`bg-[#f2f2f2] px-10 py-16 gap-4 ${
        disabled
          ? 'disabled:opacity-60 disabled:cursor-not-allowed'
          : 'hover:shadow-lg hover:-translate-y-1 transition-all'
      } rounded-lg w-full max-w-64 flex flex-col items-center text-center relative`}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <img
        style={{ width: '128px', height: '128px', objectFit: 'contain' }}
        src={image}
        alt=''
        className='mb-4'
      />
      <p className='uppercase text-xl font-semibold'>{text}</p>
      {selected && (
        <span className='text-main-primary absolute bottom-4 right-4'>
          <FiCheckCircle size={18} />
        </span>
      )}
    </button>
  )
}

export default function PackageType ({ metaTitle }) {
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')
  const location = useLocation()
  const categories = useSelector(state => state.categories)

  const navigateWithParams = useNavigateWithParams()

  const {
    updateActiveStep,
    order: {
      payload: { packages, sender, receiver, category, type: shipmentType }
    },
    updateOrder,
    resolvePathname
  } = useContext(CreateOrderContext)

  const backRoute = useMemo(() => {
    const params = urlQueryStringToObject(location.search)

    params.ctm = 'receiver'

    return `${
      ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
    }?${new URLSearchParams(params).toString()}`
  }, [location.search])

  useEffect(() => {
    updateActiveStep({
      stepId: 3,
      metaTitle,
      back: backRoute
    })
  }, [backRoute, metaTitle, updateActiveStep])

  const types = useMemo(() => {
    if (shipmentType === 'LC') {
      if (categories.data) {
        const images = {
          1: electronics,
          2: packageImage,
          3: haulage
        }

        return categories.data.map(category => ({
          name: category.name,
          id: category.id,
          img: images[category.id],
          action () {
            updateOrder({
              category: category.id
            })
            setTimeout(() => {
              navigateWithParams({
                pathname: resolvePathname(
                  ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path
                )
              })
            }, 500)
          },
          disabled: category.id === 3
        }))
      }
    } else {
      return [
        {
          name: 'Package',
          id: 'regular',
          img: packageImage,
          action () {
            setTimeout(() => {
              navigateWithParams({
                pathname: resolvePathname(
                  ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path
                )
              })
            }, 500)
          },
          disabled: false
        },
        {
          name: 'Document',
          id: 'document',
          img: document,
          action () {
            navigateWithParams({
              pathname: resolvePathname(
                ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path
              )
            })
          },
          disabled: false
        },
        {
          name: 'Fish',
          id: 'fish/snail',
          img: fishSnail,
          action () {
            navigateWithParams({
              pathname: resolvePathname(
                ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path
              )
            })
          },
          disabled: false
        }
      ]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, shipmentType])

  if (!shipmentType) {
    return (
      <Navigate
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
        replace={true}
      />
    )
  }

  if (orderId) {
    const packageRoute =
      packages.type === 'regular'
        ? `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path}`
        : packages.type === 'fish/snail'
        ? `${ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path}`
        : `${ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path}`

    return (
      <Navigate
        to={resolvePathname(`${packageRoute}${location.search}`)}
        replace={true}
      />
    )
  }

  if (!sender) {
    return (
      <Navigate
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender`
        )}
        replace={true}
      />
    )
  }

  if (!receiver) {
    return (
      <Navigate
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver`
        )}
        replace={true}
      />
    )
  }

  return (
    <div className='flex flex-col items-center pb-8'>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
        {shipmentType === 'LC' ? 'Category' : 'Type of Package'}
      </h2>

      <div className='flex flex-row flex-wrap justify-center gap-5 mt-5 lg:mt-10 w-full max-w-4xl bg-white border rounded-xl p-4 lg:p-6 lg:pb-12'>
        {types ? (
          types.map((type, id) => (
            <Item
              key={id}
              handleOnClick={type.action}
              image={type.img}
              text={type.name}
              disabled={type.disabled}
              selected={
                shipmentType === 'LC'
                  ? category === type.id
                  : packages?.type === type.id
              }
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}
