import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUrlQueryString } from '../utils'

export default function useNavigateWithParams () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const createSearchString = searchObj => {
    const params = Object.assign(
      {},
      Object.fromEntries(searchParams.entries()),
      searchObj
    )

    const searchString = getUrlQueryString(params)

    return searchString ? `?${searchString}` : ''
  }

  return (to, options = {}) => {
    navigate(
      {
        ...to,
        search: createSearchString(to.search)
      },
      options
    )
  }
}
