import React, { useContext, useEffect, useMemo } from 'react'
import { AppForm, AppFormField, AppFormSelectField } from '../..'
import { Loader } from '../../globals'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IS_ALL_NUMBERS } from '../../../utils'
import ROUTES from '../../../constants/routes'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import OrderAddons from '../../orders/OrderAddons'
import OrderItems from '../../orders/OrderItems'
import OrderPackages from '../../orders/OrderPackages'
import { Formik } from 'formik'
import * as yup from 'yup'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'

const FishForm = ({ formik, container, onUpdateOrder }) => {
  useEffect(() => {
    onUpdateOrder(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formik.values)])

  return (
    <div className='border border-neutral w-full max-w-3xl rounded-lg'>
      <AppForm
        useFormik={false}
        onSubmit={formik.handleSubmit}
        id='fish-form'
        className='rounded-lg bg-white'
      >
        <section id='package-information'>
          <div className='flex items-center justify-between bg-gray-300 px-4 py-8 rounded-t-lg'>
            <h1 className='font-bold text-xl text-left'>Package information</h1>
          </div>
          <div className='p-4 md:px-8 flex flex-col gap-2'>
            <div>
              <AppFormField
                name='itemsValue'
                title='Declared Value'
                step='0.01'
                type='number'
                showNaira
              />
            </div>
            <div>
              <AppFormSelectField name='exportReason' title='Reason for Export'>
                <option value='default'>Defaut</option>
                <option value='Gift'>Gift</option>
                <option value='Personal Use'>Personal Use</option>
                <option value='Sales'>Sales</option>
                <option value='Repair'>Repair</option>
              </AppFormSelectField>
            </div>

            <div>
              <AppFormSelectField
                name='tpl_service'
                title='Carrier'
                className='select'
              >
                <option value='DHL'>DHL</option>
                <option value='AMX'>
                  Aramex Standard (Food To US/UK/Canada)
                </option>
                <option value='FED'>Fedex</option>
              </AppFormSelectField>
            </div>
          </div>
        </section>

        <OrderPackages container={container} shipmentType='IN' />

        <OrderItems
          container={container}
          shipmentType='IN'
          packageType='fish/snail'
          courier={formik.values.tpl_service}
        />

        <OrderAddons isFish={true} container={container} />

        <div className='p-2 md:p-4 w-full'>
          <button
            className='btn btn-primary w-full text-white'
            type='submit'
            form='fish-form'
          >
            Continue
          </button>
        </div>
      </AppForm>
    </div>
  )
}

export default function FishSnail () {
  const location = useLocation()
  const { layoutContainer } = useContext(AuthLayoutContext)
  const {
    order: {
      payload: { packages, tpl_service }
    },
    updateOrder,
    resolvePathname
  } = useContext(CreateOrderContext)

  const navigate = useNavigate()

  const addonsStore = useSelector(state => state.addons)

  const initialValues = (() => {
    let config = {}

    if (packages && packages.type === 'fish/snail') {
      config = {
        itemsValue: packages.itemsValue,
        tpl_service,
        exportReason: packages.exportReason,
        items: packages.items,
        packages: packages.packages.map(_package => ({
          unitMeasurement: _package.unitMeasurement,
          actualWeight: _package.actualWeight,
          dimension: _package.predefinedDimension
            ? _package.predefinedDimension
            : _package.packageDimension
            ? JSON.stringify(_package.packageDimension)
            : ''
        })),
        addOns: packages.addOns
      }
    } else {
      config = {
        itemsValue: '',
        tpl_service: 'DHL',
        exportReason: 'default',
        items: [
          {
            name: 'Fish/Snail',
            quantity: '',
            price: '',
            unitMeasurement: '',
            hsCode: '',
            manufacturerCountry: '',
            weight: ''
          }
        ],
        addOns: [],
        packages: [
          {
            unitMeasurement: 'KGS',
            actualWeight: '',
            dimension: ''
          }
        ]
      }
    }

    return config
  })()

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        itemsValue: yup
          .number('Declared value must be a number')
          .required('Declared value is required')
          .min(1000, 'Minimum declared value is ₦1,000')
          .max(10000000, 'Declared value cannot exceed ₦100,000,000'),
        tpl_service: yup.string().required('Carrier is required'),
        exportReason: yup.string().required('Export reason is required'),

        items: yup.array().of(
          yup.object().shape({
            name: yup.string().required('Item description is required'),
            quantity: yup
              .number('Quantity must be a number')
              .required('Quantity is required')
              .min(1, 'Minimum quantity is 1')
              .test(
                'is-integer',
                'Quantity must be an integer',
                function (value) {
                  return typeof value === 'number' && Number.isInteger(value)
                }
              ),
            price: yup
              .number('Price must be a number')
              .required('Price is required')
              .min(1, 'Minimum unit value is ₦1')
              .test(
                'is-integer',
                'Unit value must be an integer',
                function (value) {
                  return typeof value === 'number' && Number.isInteger(value)
                }
              )
              .test(
                'is-less-declared-value',
                'Total items value must not exceed declared value',
                function (value) {
                  const totalItemsValue = values.items.reduce(
                    (acc, curr) =>
                      typeof curr.price === 'number' &&
                      typeof curr.quantity === 'number'
                        ? acc + Number(curr.price) * Number(curr.quantity)
                        : acc,
                    0
                  )

                  return totalItemsValue <= values.itemsValue
                }
              ),
            unitMeasurement: yup.string().required('Unit is required'),
            hsCode: yup
              .string()
              .required('HS Code is required')
              .test('is-number', 'HS Code must be number', function (value) {
                if (value) {
                  return IS_ALL_NUMBERS.test(value)
                }
                return true
              }),
            weight: yup
              .number('Weight must be a number')
              .required('Weight is required')
              .min(0.01, 'Minimum item weight is 0.01kg')
              .test(
                'is-less-package-weight',
                'Total items weight must not exceed total packages weight',
                function (value) {
                  const packagesWeight = values.packages.reduce(
                    (acc, curr) =>
                      typeof curr.actualWeight === 'number'
                        ? acc + Number(curr.actualWeight)
                        : acc,
                    0
                  )

                  const itemsWeight = values.items.reduce(
                    (acc, curr) =>
                      typeof curr.weight === 'number' &&
                      typeof curr.quantity === 'number'
                        ? acc + Number(curr.weight) * Number(curr.quantity)
                        : acc,
                    0
                  )

                  return itemsWeight <= packagesWeight
                }
              ),
            manufacturerCountry: yup
              .string()
              .required('Manufacturer country is required')
          })
        ),

        packages: yup.array().of(
          yup.object().shape({
            unitMeasurement: yup
              .string()
              .required('Unit measurement is required'),
            actualWeight: yup
              .number('Actual weight must be a number')
              .required('Actual weight is required')
              .min(0.01, 'Minimum weight is 0.01'),
            dimension: yup
              .string()
              .required('Package dimension is required')
              .test(
                'is-valid',
                'Package dimension is required',
                function (value) {
                  value = value ? JSON.parse(value) : value
                  let isValid
                  if (!value) {
                    isValid = false
                  } else if (typeof value === 'number') {
                    isValid = true
                  } else if (typeof value === 'object') {
                    isValid =
                      value.length &&
                      value.width &&
                      value.height &&
                      value.weight
                  }
                  return isValid
                }
              )
          })
        ),

        addOns: yup.array().of(
          yup.object().shape({
            id: yup
              .number('Addon type is required')
              .required('Addon type is required'),
            quantity: yup
              .number('Quantity must be a number')
              .required('Addon quantity is required')
          })
        )
      })
    )

  const handleUpdateOrder = body => {
    const payload = {
      packages: {
        type: 'fish/snail',
        ...body,
        packages: body.packages.map(
          ({ unitMeasurement, actualWeight, dimension }) => {
            const _package = {
              unitMeasurement,
              actualWeight
            }
            dimension = dimension ? JSON.parse(dimension) : ''
            if (typeof dimension === 'number') {
              _package.predefinedDimension = dimension
            }
            if (typeof dimension === 'object') {
              _package.packageDimension = dimension
            }

            return _package
          }
        )
      },
      tpl_service: body.tpl_service
    }

    delete payload.packages.tpl_service

    updateOrder(payload)
  }

  const handleOnSubmit = () => {
    navigate(
      resolvePathname(
        `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_SECTION.path}${location.search}`
      )
    )
  }

  const isLoading = useMemo(() => !addonsStore.data, [addonsStore.data])

  return (
    <div className='flex flex-col items-center pb-8'>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {formik => (
            <FishForm
              formik={formik}
              container={layoutContainer}
              onUpdateOrder={handleUpdateOrder}
            />
          )}
        </Formik>
      )}
    </div>
  )
}
