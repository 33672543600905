import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import invoicesApi from '../api/invoice'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0,
    page_size: 50
  }
}

export const fetchInvoices = createAsyncThunk(
  'invoices/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await invoicesApi.getInvoices(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    updateData (state, { payload }) {
      state.data = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      const meta = action.payload?.response_data?.meta || {}
      if (meta.next) {
        meta.next = urlQueryStringToObject(meta.next).page
      }
      meta.page = action.payload?.query_params?.page
      meta.page_size = action.payload?.query_params?.page_size
      meta.previous = meta.page > 1 ? meta.page - 1 : 0
      state.meta = Object.assign({}, state.meta, meta)
      state.data = action.payload?.response_data?.payload
    })
  }
})

export const invoicesActions = invoicesSlice.actions

export default invoicesSlice.reducer
