import { useState } from 'react'
import { parseError } from '../utils'
import store from '../store'
import { authActions } from '../slices/authSlice'

const useApi = (apiFunc, params) => {
  const [data, setData] = useState()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const request = async () => {
    setLoading(true)
    const response = await apiFunc(params)
    setLoading(false)

    if (response.status === 401) {
      store.dispatch(authActions.logout())
      return
    }
    if (!response.ok) {
      setError(true)
      const apiError = parseError(response)
      if (apiError) {
        setErrorMessage(apiError.data)
      }
      return
    }

    setError(false)
    setData(response.data)
  }

  return { data, errorMessage, error, loading, request }
}

export default useApi
