export default function ReportsIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.25 26.75H18.75M11.25 26.75V20.5M11.25 26.75H4.5C4.30109 26.75 4.11032 26.671 3.96967 26.5303C3.82902 26.3897 3.75 26.1989 3.75 26V21.25C3.75 21.0511 3.82902 20.8603 3.96967 20.7197C4.11032 20.579 4.30109 20.5 4.5 20.5H11.25M18.75 26.75V11.75M18.75 26.75H25.5C25.6989 26.75 25.8897 26.671 26.0303 26.5303C26.171 26.3897 26.25 26.1989 26.25 26V5C26.25 4.80109 26.171 4.61032 26.0303 4.46967C25.8897 4.32902 25.6989 4.25 25.5 4.25H19.5C19.3011 4.25 19.1103 4.32902 18.9697 4.46967C18.829 4.61032 18.75 4.80109 18.75 5V11.75M11.25 20.5V12.5C11.25 12.3011 11.329 12.1103 11.4697 11.9697C11.6103 11.829 11.8011 11.75 12 11.75H18.75'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='1.5'
      />
    </svg>
  )
}
