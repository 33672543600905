export default function ExternalOrgIcon ({
  variant = 'secondary',
  ...restProps
}) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.75 4.25H15C21.2132 4.25 26.25 9.2868 26.25 15.5C26.25 21.7133 21.2132 26.75 15 26.75H13.75M8.75 10.5L3.75 15.5M3.75 15.5L8.75 20.5M3.75 15.5H18.75'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        stroke-width='1.83333'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
