import store from '../store'
import orgClient from './clients/orgClient'

const getBranches = async (region = null) => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const url = region
      ? `/organisations/${org?.oid}/branches/?region=${region}`
      : `/organisations/${org?.oid}/branches/`
    const response = await orgClient.get(url)

    return response
  }
}

const getAreas = async () => {
  const response = await orgClient.get(`/organisations/areas`)

  return response
}

const getRegions = async () => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.get(`/organisations/${org?.oid}/regions/`)

    return response
  }
}

const getAllSystems = async () => {
  const response = await orgClient.get(`/systems`)

  return response
}

const getRoles = async () => {
  const response = await orgClient.get('/roles')
  return response
}

const createBranch = async payload => {
  const response = await orgClient.post(`/organisations/branches/`, payload)
  return response
}

const createArea = async payload => {
  const response = await orgClient.post(`/organisations/areas/`, payload)
  return response
}

const createRegion = async payload => {
  const response = await orgClient.post(`/organisations/regions/`, payload)
  return response
}

const deleteBranch = async id => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.delete(
      `/organisations/${org?.oid}/branches/${id}/`
    )

    return response
  }
}

const deleteRegion = async id => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.delete(
      `/organisations/${org?.oid}/regions/${id}/`
    )

    return response
  }
}

const getSingleRegion = async id => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.get(
      `/organisations/${org?.oid}/regions/${id}`
    )

    return response
  }
}

const getSingleBranch = async id => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.get(
      `/organisations/${org?.oid}/branches/${id}`
    )

    return response
  }
}

const editRegion = async (id, payload) => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.put(
      `/organisations/${org?.oid}/regions/${id}/`,
      payload
    )
    return response
  }
}

const editBranch = async (id, payload) => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.put(
      `/organisations/${org?.oid}/branches/${id}/`,
      payload
    )
    return response
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getRoles,
  getBranches,
  getAreas,
  getRegions,
  getAllSystems,
  createBranch,
  createArea,
  createRegion,
  deleteBranch,
  deleteRegion,
  getSingleRegion,
  editRegion,
  getSingleBranch,
  editBranch
}
