import { useEffect, useMemo, useState, useCallback } from 'react'
import FilterButton from '../../components/globals/filter/FilterButton'
import Page from '../../containers/Page'
import TotalCard from '../../components/dashboard/TotalCard'
import ShipmentsBarChart from '../../components/dashboard/ShipmentsBarChart'
import ShipmentTypePieChart from '../../components/dashboard/ShipmentTypePieChart'
import OrderStatus from '../../components/dashboard/OrderStatusCard'
import RecentOrders from '../../components/dashboard/RecentOrders'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboard } from '../../slices/dashboardSlice'
import { Loader } from '../../components/globals'
import { fetchOrders } from '../../slices/ordersSlice'
import DashboardFilter from '../../components/dashboard/DashboardFilter'
import CeoBookingCard from '../../components/dashboard/CeoBookingCard'
import { getDate, initializeDateRange } from '../../utils'
import { fetchBranches } from '../../slices/orgsSlice'
const convertToKeyValueArray = obj => {
  if (obj) {
    return obj
      ? Object.entries(obj).map(([key, value]) => ({
          title: key,
          value: value
        }))
      : {}
  }
}
function CooDashboard ({ metaTitle }) {
  const dashboardData = useSelector(state => state.dashboard)
  const dashboardOrdersData = useSelector(state => state.orders)
  const addOnsData = useSelector(state => state.addons.data)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serializedData, setSerializedData] = useState(null)
  const [expandedCardIndex, setExpandedCardIndex] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })

  const dispatch = useDispatch()

  const getQueryParams = filter => {
    const query = {}

    for (let key in filter) {
      if (key === 'start_month' || key === 'end_month') {
        if (filter[key]) {
          query[key] = filter[key].value
        }
      } else if (filter[key]) {
        query[key] = filter[key]
      }
    }

    return new URLSearchParams(query).toString()
  }

  const [dateRange, setDateRange] = useState(initializeDateRange())

  useEffect(() => {
    dispatch(fetchBranches())
    dispatch(fetchOrders())
  }, [])
  const formatPayment = item => {
    switch (item) {
      case 'Transfer':
        return 'TRF'
      case 'Cash':
        return 'CASH'
      case 'POS':
        return 'POS'
      case 'Pay later':
        return 'PAY_LATER'
      default:
        break
    }
  }

  const formatType = item => {
    switch (item) {
      case 'Local':
        return 'LC'
      case 'International':
        return `IN`
      default:
        return ''
    }
  }

  const formatTpl = item => {
    switch (item) {
      case 'FedEx':
        return 'FIE'
      case 'RedStar Express':
        return 'RSE'
      default:
        return item
    }
  }
  const removeEmptyValues = obj => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key]
      }
    }
    return obj
  }

  const showFilter = () => {
    setIsFilterOpen(true)
  }

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  useEffect(() => {
    if (dashboardData.filter) {
      const filterParams = getQueryParams({
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date,
        branch_name: dashboardData.filter.branch_name,
        shipmentType: formatType(dashboardData.filter.shipmentType),
        payment_method: formatPayment(dashboardData.filter.payment),
        tpl_service: formatTpl(dashboardData?.filter.tpl)
      })

      dispatch(fetchDashboard(`?${filterParams}`))
    } else {
      dispatch(fetchDashboard())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData.filter])

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  const totals = useMemo(() => {
    if (dashboardData.data) {
      return [
        {
          title: 'Total shipments created',
          amount: dashboardData?.data?.shipments?.total_shipments
        },
        {
          title: 'Total Number of Customers',
          amount: dashboardData?.data?.total_customers
        },
        {
          title: 'Total Number of Partners',
          amount: dashboardData?.data?.total_partners
        },
        {
          title: 'Total no of e-commerce',
          amount:
            dashboardData?.data?.ecommerce_bookings_data
              ?.total_ecommerce_bookings
        }
      ]
    }
  }, [dashboardData.data])

  const customerBookings = useMemo(() => {
    if (dashboardData.data) {
      return {
        branch_bookings:
          dashboardData.data.customer_bookings_data?.branch_booking,
        employee_bookings:
          dashboardData.data.customer_bookings_data?.employee_booking,
        bookings: dashboardData.data.customer_bookings_data?.booking,
        total_bookings:
          dashboardData?.data.customer_bookings_data?.total_customer_bookings
      }
    }
  }, [dashboardData.data])

  const partnerBookings = useMemo(() => {
    if (dashboardData.data) {
      return {
        branch_bookings:
          dashboardData.data.partner_bookings_data?.branch_booking,
        employee_bookings:
          dashboardData.data.partner_bookings_data?.employee_booking,
        bookings: dashboardData.data.partner_bookings_data?.booking,
        total_bookings:
          dashboardData.data.partner_bookings_data?.total_partner_bookings
      }
    }
  }, [dashboardData.data])

  const shipmentsStatus = useMemo(() => {
    if (dashboardData.data) {
      return {
        pending: dashboardData?.data
          ? dashboardData?.data?.shipments?.pending
          : '--',
        in_transit: dashboardData?.data
          ? dashboardData?.data?.shipments['in-transit']
          : '--',
        out_for_delivery: dashboardData?.data
          ? dashboardData?.data.shipments['out-for-delivery']
          : '--',
        exception: dashboardData?.data
          ? dashboardData.data?.shipments.exception
          : '--',
        delivered: dashboardData?.data
          ? dashboardData.data.shipments.delivered
          : '--',
        voided: dashboardData?.data
          ? dashboardData.data?.shipments.voided
          : '--',
        available_for_pickup_by_customer: dashboardData?.data
          ? dashboardData.data?.shipments['available-for-pickup-by-customer']
          : '--'
      }
    }
  }, [dashboardData.data])

  const shipmentsTypes = useMemo(() => {
    if (dashboardData.data) {
      return {
        LC: dashboardData?.data?.shipments?.local_shipments,
        IN: dashboardData?.data?.shipments?.international_shipments
      }
    }
  }, [dashboardData.data])

  const orderStat = useMemo(() => {
    if (dashboardData.data) {
      return dashboardData?.data?.order_stat
    }
  }, [dashboardData.data])

  const shipmentsChartData = useMemo(() => {
    if (dashboardData.data) {
      return {
        total_shipments: dashboardData?.data?.shipments?.total_shipments,
        monthly_shipments: dashboardData?.data?.shipments.monthly_shipments
      }
    }
  }, [dashboardData.data])

  const isLoading = useMemo(() => {
    return (
      loading ||
      !totals ||
      !customerBookings ||
      !partnerBookings ||
      !shipmentsStatus ||
      !shipmentsTypes ||
      !orderStat ||
      !shipmentsChartData
    )
  }, [
    customerBookings,
    loading,
    orderStat,
    partnerBookings,
    shipmentsChartData,
    shipmentsStatus,
    shipmentsTypes,
    totals
  ])

  const onApplyFilter = () => setLoading(true)

  const closeFilter = () => setIsFilterOpen(false)

  const onFilterDelete = () => {
    dispatch(fetchOrders())
    setLoading(true)
  }

  const analysisData = useMemo(() => {
    const findAndMergeItemsByIds = updates => {
      return updates
        .map(update => {
          const item = addOnsData?.find(item => item.id === update.id)
          if (item) {
            return { ...item, price: update.price, quantity: update.quantity }
          }
          return null
        })
        .filter(mergedItem => mergedItem !== null)
    }

    if (dashboardOrdersData.data) {
      return dashboardOrdersData?.data.map(item => {
        return {
          date: getDate(item.order_date),
          waybillNo: item.tracking_id,
          fullname: item.receiver.contact.name,
          branch: item.branch_name,
          tpl: item.tpl_service,
          type: item.type,
          weight: item.weight,
          addOns: item?.addon_data?.addons?.length
            ? findAndMergeItemsByIds(item?.addon_data?.addons)
                .map(item => `${item.name} (${item.quantity})`)
                .join(',')
            : '--',
          addOnsTotal: item.addon_data?.total_addons_price?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          shippingFee: item.extra_charges?.on_shipping_fee?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          payment: item.payment_method ? item.payment_method : '--',
          insuranceFee: item.insurance?.fee?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          fuel: item.extra_charges?.fuel_surcharge.amount?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          subTotal: item.subtotal?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          tax: item.tax?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          total: item.total?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })
        }
      })
    }
  }, [dashboardOrdersData.data, addOnsData])

  const CeoCardDetails = useMemo(() => {
    if (dashboardData.data) {
      const cardData = dashboardData.data
      return [
        {
          title: 'Regular Customers ',
          amount:
            cardData?.customer_bookings_data?.total_customer_bookings?.toLocaleString(
              'en-NG',
              {
                style: 'currency',
                currency: 'NGN'
              }
            ),
          details: convertToKeyValueArray(
            cardData?.customer_bookings_data?.branch_booking
          )
        },
        {
          title: 'Partners ',
          amount:
            cardData?.partner_bookings_data?.total_customer_bookings?.toLocaleString(
              'en-NG',
              {
                style: 'currency',
                currency: 'NGN'
              }
            ),
          details: convertToKeyValueArray(
            cardData?.partner_bookings_data?.branch_booking
          )?.map(item => {
            return {
              ...item,
              value: item.value
            }
          })
        },
        {
          title: 'E-commerce ',
          amount:
            cardData?.ecommerce_bookings_data?.total_ecommerce_bookings?.toLocaleString(
              'en-NG',
              {
                style: 'currency',
                currency: 'NGN'
              }
            ),
          details: convertToKeyValueArray(
            cardData?.ecommerce_bookings_data?.branch_booking
          )?.map(item => {
            return {
              ...item,
              value: item.value.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })
            }
          })
        },
        {
          title: 'Shipments Status',
          details: [
            {
              title: 'Pending',
              value: cardData?.shipments ? cardData?.shipments?.pending : '--'
            },
            {
              title: 'In transit',
              value: cardData?.shipments
                ? cardData?.shipments['in-transit']
                : '--'
            },
            {
              title: 'Voided',
              value: cardData?.shipments ? cardData?.shipments?.voided : '--'
            },
            {
              title: 'Exception',
              value: cardData?.shipments ? cardData?.shipments?.exception : '--'
            },
            {
              title: 'Out for delivery',
              value: cardData?.shipments
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Ready for pickup',
              value: cardData?.shipments
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Delivered',
              value: cardData?.shipments
                ? cardData?.shipments?.delivered
                : ' --'
            }
          ]
        }
        // {
        //   title: 'Average Delivery Time',
        //   details: [
        //     {
        //       text: 'Abeokuta',
        //       value: 'N 15'
        //     }
        //   ]
        // }
      ]
    }
  }, [dashboardData.data])

  const loadOrders = useCallback(
    () => {
      dispatch(fetchOrders(queryParams))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  )

  useEffect(() => {
    setSerializedData(null)
    loadOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const onPage = params => {
    setSerializedData(null)
    const orderFilterParams = () => {
      if (dashboardData.filter) {
        return {
          branch: dashboardData.filter?.branch_name,
          type: formatType(dashboardData.filter.shipmentType),
          tpl_service: formatTpl(dashboardData.filter.tpl),
          payment_method: formatPayment(dashboardData.filter.payment),
          start_date: dashboardData.filter.start_date,
          end_date:
            dashboardData.filter.date === 'today'
              ? dashboardData.filter.start_date
              : dashboardData.filter.end_date
        }
      }
    }

    setQueryParams(state => {
      return {
        page: state.page,
        page_size: state.page_size,
        ...params,
        ...(dashboardData.filter ? removeEmptyValues(orderFilterParams()) : {})
      }
    })
  }

  const handleToggle = index => {
    setExpandedCardIndex(index === expandedCardIndex ? null : index)
  }

  return (
    <Page metaTitle={metaTitle} noPadding>
      <Page.Header title={'Dashboard'}>
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isFilterOpen && (
          <DashboardFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            onApplyFilter={onApplyFilter}
            isBranchSpecific={false}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        <DashboardFilter.Description onDelete={onFilterDelete} />
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className='flex flex-col gap-4 pt-4'>
              <div className='grid grid-cols-12 gap-4'>
                {totals.map((total, id) => (
                  <div className='col-span-12 md:col-span-3' key={id}>
                    <TotalCard title={total.title} total={total.amount} />
                  </div>
                ))}
              </div>
              {/* <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 md:col-span-4'>
                  <BookingsCard
                    type='customer'
                    data={customerBookings}
                    isBranchSpecific
                  />
                </div>
                <div className='col-span-12 md:col-span-4'>
                  <BookingsCard
                    type='partner'
                    data={partnerBookings}
                    isBranchSpecific
                  />
                </div>
                <div className='col-span-12 md:col-span-4'>
                  <ShipmentStatuses data={shipmentsStatus} />
                </div>
              </div> */}

              <div className='flex flex-col gap-4'>
                <div className='flex flex-wrap -m-2'>
                  {CeoCardDetails?.map((cardDetails, ind) => (
                    <div
                      className='p-2 w-full sm:w-1/2 md:w-1/2 lg:w-1/4'
                      key={ind}
                    >
                      <CeoBookingCard
                        title={cardDetails.title}
                        amount={cardDetails.amount}
                        details={cardDetails.details}
                        isExpanded={expandedCardIndex === ind}
                        onToggle={() => handleToggle(ind)}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className='grid grid-cols-12 gap-4 mb-6 mt-6'>
                <div className='col-span-12 lg:col-span-6'>
                  <ShipmentsBarChart data={shipmentsChartData} />
                </div>
                <div className='col-span-12 lg:col-span-6 grid grid-cols-2 gap-4'>
                  <div className='col-span-2 md:col-span-1'>
                    <ShipmentTypePieChart data={shipmentsTypes} />
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <OrderStatus data={orderStat} />
                  </div>
                </div>
              </div>

              <div>
                <RecentOrders />
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}

export default CooDashboard
