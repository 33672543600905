import React, { useCallback, useEffect } from 'react'
import {
  Navigate
  // useLocation
} from 'react-router-dom'
import AuthLayout from '../containers/AuthLayout'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../components/globals'
import { shipmentSocketActions } from '../slices/shipmentSocketSlice'
import { paymentSocketActions } from '../slices/paymentSocketSlice'
import { fetchAccountData } from '../slices/authSlice'

export default function ProtectedRoute ({ children }) {
  const { isLoggedIn, user } = useSelector(state => state.auth)

  // const { pathname, search } = useLocation()
  // const redirect = encodeURIComponent(`${pathname}${search}`)

  const dispatch = useDispatch()

  const initateSocket = useCallback(async () => {
    dispatch(shipmentSocketActions.connect())
    dispatch(paymentSocketActions.connect())
  }, [dispatch])

  const disconnectSocket = useCallback(async () => {
    dispatch(shipmentSocketActions.disconnect())
    dispatch(paymentSocketActions.disconnect())
  }, [dispatch])

  const fetchUser = useCallback(async () => {
    dispatch(fetchAccountData())
  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn) {
      if (!user) {
        fetchUser()
      }

      initateSocket()
    }

    return () => {
      disconnectSocket()
    }
  }, [disconnectSocket, fetchUser, initateSocket, isLoggedIn, user])

  if (!isLoggedIn)
    return (
      <Navigate
        replace
        to={{
          pathname: '/login'
          // search: `?next=${redirect}`
        }}
      />
    )

  return <AuthLayout>{!user ? <Loader /> : <Outlet />}</AuthLayout>
}
