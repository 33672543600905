import Filter from '../globals/filter/Filter'
import DateRangeModal from '../globals/filter/DateRangeModal'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { statuses } from '../../fixtures/shipmentsStatus'
import { AuthLayoutContext } from '../../containers/AuthLayout'

export default function ShipmentsFiter ({
  isOpen,
  onClose,
  filter,
  setFilter,
  dateRange,
  setDateRange,
  type
}) {
  const { userRole } = useContext(AuthLayoutContext)

  const branches = useSelector(state => state?.orgs?.branches)

  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(filter)
  const categories = useSelector(state => state.categories)

  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)

  const package_type_options = [
    { name: 'Package', value: 'regular' },
    { name: 'Document', value: 'document' },
    { name: 'Fish and Snail', value: 'fish/snail' }
  ]

  const category_options = useMemo(() => {
    return categories.data
      ? categories.data.map(category => ({
          name: category.name,
          value: category.id
        }))
      : []
  }, [categories.data])

  const date_options = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last 7 days', value: 'last_7_days' },
    { name: 'Last 30 days', value: 'last_30_days' },
    { name: 'Range', value: 'range' }
  ]

  const status_options = (() => {
    const options = []
    for (const key in statuses) {
      options.push({
        name: statuses[key].name,
        value: key
      })
    }
    return options
  })()

  const carrier_options = [
    {
      name: 'FedEx (Economy)',
      value: 'FIE'
    },
    {
      name: 'FedEx (Priority)',
      value: 'FPE'
    },
    {
      name: 'UPS',
      value: 'UPS'
    },
    {
      name: 'DHL',
      value: 'DHL'
    },
    {
      name: 'AMX',
      value: 'AMX'
    }
  ]

  const branch_options = useMemo(() => {
    return branches
      ? branches.map(branch => ({ name: branch.name, value: branch.name }))
      : []
  }, [branches])

  const onSelectBranch = value => {
    setSelectedFilter(state => ({ ...state, branch: value }))
  }

  const onSelectPackage = value => {
    setSelectedFilter(state => ({ ...state, package_type: value }))
  }

  const onCarrierChange = value => {
    setSelectedFilter(state => ({ ...state, shipment_type: value }))
  }

  const onSelectCategory = value => {
    setSelectedFilter(state => ({ ...state, category: value }))
  }

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setSelectedFilter(state => ({ ...state, date: value }))
  }
  const onStatusChange = value => {
    setSelectedFilter(state => ({ ...state, status: value }))
  }

  const applyFilters = () => {
    setFilter(selectedFilter)
    setDateRange(selectedDateRange)
    onClose()
  }

  const cancelDateRange = () => {
    setSelectedFilter(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const handleClose = () => {
    setSelectedFilter(filter)
    onClose()
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  return (
    <Filter.Body isOpen={isOpen} onClose={handleClose} onCancel={handleClose}>
       {type === 'LC' ? (
          <Filter.SelectDropdown
            title='Category'
            value={selectedFilter.category}
            placeholder='Select category'
            options={category_options}
            onChange={onSelectCategory}
          />
      ) : (
        <Filter.SelectDropdown
          title='Package Type'
          value={selectedFilter.package_type}
          placeholder='Select Package type'
          options={package_type_options}
          onChange={onSelectPackage}
        />
      )}
      <Filter.Radio
        title='Status'
        name='status'
        value={selectedFilter.status}
        options={status_options}
        onChange={onStatusChange}
      />
      {type !== 'LC' && (
        <Filter.SelectDropdown
          title='Carrier'
          value={selectedFilter.shipment_type}
          placeholder='Select Carrier'
          options={carrier_options}
          onChange={onCarrierChange}
        />
      )}
      {userRole?.domain.index > 0 && (
        <Filter.SelectDropdown
          title='Branch'
          value={selectedFilter.branch}
          placeholder='Select branch'
          options={branch_options}
          onChange={onSelectBranch}
        />
      )}
      <Filter.SelectDropdown
        title='Date'
        value={selectedFilter.date}
        placeholder='Select Date'
        options={date_options}
        onChange={onSelectDate}
      />
      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />

      <DateRangeModal
        isOpen={isDateModalOpen}
        initialDateRange={dateRange}
        onCancel={cancelDateRange}
        onSave={onSaveDateRange}
      />
    </Filter.Body>
  )
}
