import Modal from 'react-modal'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AuthCredsRefreshErrorPrompt ({ isOpen, onClose }) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div className='bg-white flex flex-col items-center gap-5'>
        <div className='text-center'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            Session expired
          </h3>
          <p>
            Your current session has expired. You are required to re-login to
            continue your session.
          </p>
        </div>
        <div className='flex w-full justify-center items-center'>
          <button className='btn btn-primary' onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  )
}
