import { useContext, useEffect, useMemo, useState } from 'react'
import useNavigateWithParams from '../../../hooks/useNavigateWithParams'
import useToast from '../../../hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../../globals'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'
import { fetchHubs } from '../../../slices/hubsSlice'
import { useSearchParams } from 'react-router-dom'
import ordersApi from '../../../api/orders'
import { parseError } from '../../../utils'
import { Formik } from 'formik'
import ROUTES from '../../../constants/routes'
import * as yup from 'yup'
import ToggleSwitch from '../../globals/ToggleSwitch'
import AppForm from '../../globals/Form/AppForm'
import AppFormField from '../../globals/Form/AppFormField'
import AppFormSelectField from '../../globals/Form/AppFormSelectField'

const ShipmentForm = ({ formik, onUpdateOrder, hubs }) => {
  useEffect(() => {
    onUpdateOrder(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formik.values)])

  const toggleSalesForce = value => {
    formik.setFieldValue('is_sales_force', value ? 'enabled' : '')
  }

  const toggleAcknowledgementCopy = value => {
    formik.setFieldValue('get_acknowledgement_copy', value ? 'enabled' : '')
  }

  return (
    <AppForm
      onSubmit={formik.handleSubmit}
      className='flex flex-col w-full bg-white py-8 px-4 rounded-lg justify-center space-y-5 border border-neutral'
      id='shipment-form'
    >
      <div>
        <AppFormField
          name='description'
          title='General Description'
          placeholder='Description'
        />
      </div>
      <div>
        <AppFormSelectField name='type' title='Shipment Type' disabled>
          <option value=''>Select</option>
          <option value='LC'>Local</option>
          <option value='IN'>International</option>
        </AppFormSelectField>
      </div>
      {formik.values.type === 'IN' ? (
        <div>
          <AppFormSelectField name='delivery_type' title='Delivery Type'>
            <option value=''>Select</option>
            <option value='DROP_OFF'>Drop Off</option>
            <option value='SIGNATURE_REQUIRED'>Signature</option>
          </AppFormSelectField>
        </div>
      ) : (
        <div className='form-control w-full'>
          <AppFormSelectField name='delivery_mode' title='Delivery Mode'>
            <option value=''>Select</option>
            <option value='DOOR_STEP'>DOORSTEP</option>
            <option value='PICKUP'>PICKUP</option>
          </AppFormSelectField>
        </div>
      )}

      {formik.values.delivery_mode === 'PICKUP' && (
        <div>
          <AppFormSelectField name='pickup_hub' title='Pickup Hub'>
            <option value=''>Select</option>
            {hubs.map(hub => (
              <option value={hub.id}>{`${
                hub.location.charAt(0).toUpperCase() + hub.location.slice(1)
              } - ${
                hub.name.charAt(0).toUpperCase() + hub.name.slice(1)
              }`}</option>
            ))}
          </AppFormSelectField>
        </div>
      )}

      <div>
        <AppFormSelectField
          name='package_insurance'
          title='Insurance'
          disabled={formik.values.type === 'LC'}
        >
          <option value=''>Select</option>
          {formik.values.type === 'LC' ? (
            <>
              <option value='EI'>Electronics Insurance</option>
              <option value='NE'>Non Electronics Insurance</option>
              <option value='HI' disabled>
                Haulage Insurance
              </option>
            </>
          ) : (
            <>
              <option value='FR'>Free</option>
              {/* <option value='SD'>Standard</option> */}
              <option value='PM'>Premium</option>
            </>
          )}
        </AppFormSelectField>
      </div>
      {formik.values.package_insurance === 'PM' && (
        <div className='form-control w-full'>
          <AppFormField
            name='insured_value'
            title='Insured Value'
            placeholder='Insured Value'
            type='number'
            showNaira
            step='0.01'
          />
        </div>
      )}

      <div className='flex flex-col md:flex-row gap-4 w-full items-start'>
        <div className='flex items-center gap-4 w-full'>
          <label className='label'>
            <span className='label-text'>Saleforce Agent?</span>
          </label>

          <ToggleSwitch
            name='is_sales_force'
            enabled={!!formik.values.is_sales_force}
            onChange={toggleSalesForce}
          />
        </div>
        {formik.values.is_sales_force && (
          <div className='w-full'>
            <AppFormField
              name='salesforce_code'
              placeholder='Salesforce Agent Code'
              title='Salesforce Agent Code'
              noLabel
            />
          </div>
        )}
      </div>

      {formik.values.type === 'LC' && (
        <div className='form-control w-full'>
          <div className='flex items-center gap-4'>
            <label className='label'>
              <span className='label-text'>Acknowledgment Copy</span>
            </label>

            <ToggleSwitch
              enabled={formik.values.get_acknowledgement_copy}
              onChange={toggleAcknowledgementCopy}
            />
          </div>
        </div>
      )}
      <button className='btn btn-primary' type='submit' form='shipment-form'>
        Continue
      </button>
    </AppForm>
  )
}

export default function ShipmentSectionForm () {
  const { order, updateOrder, resolvePathname } = useContext(CreateOrderContext)

  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')

  const navigateWithParams = useNavigateWithParams()

  const dispatch = useDispatch()
  const toast = useToast()

  const hubsStore = useSelector(state => state.hubs)

  const hubs = useMemo(() => {
    let result = []
    if (hubsStore.hubs) {
      result = [...hubsStore.hubs]
      return result.sort((a, b) => {
        if (a.location === b.location) {
          return a.name.localeCompare(b.name)
        }
        return a.location.localeCompare(b.location)
      })
    }
    return result
  }, [hubsStore.hubs])

  const initFormData = () => {
    const config = {
      description: order.payload.description || '',
      type: order.payload.type,
      package_insurance: order.payload.package_insurance || '',
      is_sales_force: order.payload.is_sales_force ? 'enabled' : '',
      get_acknowledgement_copy: order.payload.get_acknowledgement_copy
        ? 'enabled'
        : '',
      ...(order.payload.package_insurance === 'PM' && {
        insured_value: Number(order.payload.insured_value)
      }),
      ...(order.payload.type === 'IN' && {
        delivery_type: order.payload.delivery_type || ''
      }),
      ...(order.payload.type === 'LC' && {
        delivery_mode: order.payload.delivery_mode || '',
        pickup_hub: order.payload.pickup_hub?.id || '',
        category: order.payload.category
      }),
      ...(order.payload.is_sales_force && {
        salesforce_code: order.payload.salesforce_code || ''
      })
    }

    if (order.payload.type === 'LC') {
      // eslint-disable-next-line default-case
      switch (config.category) {
        case 1:
          config.package_insurance = 'EI'
          break
        case 2:
          config.package_insurance = 'NE'
          break
        case 3:
          config.package_insurance = 'HI'
          break
      }
    }

    return config
  }

  const [loading, setLoading] = useState(false)

  const initialValues = initFormData()

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape(
        (() => {
          const config = {
            description: yup.string().required('Description is required'),
            type: yup.string().required('Shipment type is required'),
            package_insurance: yup
              .string()
              .required('Package insurance is required'),
            insured_value: yup
              .number()
              .test(
                'is-required-if-premium',
                'Insured value is required for Premium Package Insurance',
                function (value) {
                  if (values.package_insurance === 'PM') {
                    return !!value
                  }
                  return true
                }
              ),
            delivery_type: yup
              .string()
              .test(
                'is-required-if-IN',
                'Delivery type is required',
                function (value) {
                  if (values.type === 'IN') {
                    return !!value
                  }
                  return true
                }
              ),
            delivery_mode: yup
              .string()
              .test(
                'is-required-if-LC',
                'Delivery mode is required',
                function (value) {
                  if (values.type === 'LC') {
                    return !!value
                  }
                  return true
                }
              ),
            get_acknowledgement_copy: yup.string(),
            is_sales_force: yup.string(),
            salesforce_code: yup
              .string()
              .test(
                'is-required-if-salesforce',
                'Agent code is required if salesforce is enabled',
                function (value) {
                  if (values.is_sales_force) {
                    return !!value
                  }
                  return true
                }
              )
          }

          return config
        })()
      )
    )

  useEffect(() => {
    if (order.payload.type === 'LC') {
      dispatch(fetchHubs())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateOrder = body => {
    const payload = {
      description: body.description,
      type: body.type,
      package_insurance: body.package_insurance,
      is_sales_force: !!body.is_sales_force,
      get_acknowledgement_copy: !!body.get_acknowledgement_copy,
      ...(body.package_insurance === 'PM' && {
        insured_value: body.insured_value
      }),
      ...(body.type === 'IN' && {
        delivery_type: body.delivery_type
      }),
      ...(body.type === 'LC' && {
        delivery_mode: body.delivery_mode,
        ...(body.delivery_mode === 'PICKUP' && {
          pickup_hub: hubs.find(hub => hub.id === Number(body.pickup_hub))
        }),
        category: body.category
      }),
      ...(body.is_sales_force && {
        salesforce_code: body.salesforce_code
      })
    }

    updateOrder(payload)
  }

  const handleOnSubmit = async () => {
    setLoading(true)

    const payload = {
      sender: order.payload.sender,
      receiver: order.payload.receiver,
      partner: order.payload.partner,
      shipa_or_ecommerce: order.payload.shipa_or_ecommerce,
      type: order.payload.type,
      package_insurance: order.payload.package_insurance,
      packages: order.payload.packages,
      tpl_service: order.payload.tpl_service,
      description: order.payload.description,
      served_by: order.payload.served_by,
      branch_name: order.payload.branch_name,
      draft: false,
      is_sales_force: order.payload.is_sales_force,
      get_acknowledgement_copy: order.payload.get_acknowledgement_copy,
      ...(order.payload.type === 'LC' && {
        category: order.payload.category,
        delivery_mode: order.payload.delivery_mode,
        ...(order.payload.delivery_mode === 'PICKUP' && {
          pickup_hub: order.payload.pickup_hub
        })
      }),
      ...(order.payload.type === 'IN' && {
        delivery_type: order.payload.delivery_type
      }),
      ...(order.payload.package_insurance === 'PM' && {
        insured_value: Number(order.payload.insured_value)
      }),
      ...(order.payload.is_sales_force && {
        salesforce_code: order.payload.salesforce_code
      })
    }

    const response =
      orderId && quoteId
        ? await ordersApi.createOrderFromQuote(orderId, quoteId, payload)
        : order.meta.order_id
        ? await ordersApi.updateOrder(order.meta.order_id, payload)
        : await ordersApi.createOrder(payload)

    if (!response.ok) {
      setLoading(false)

      const apiError = parseError(response)
      if (apiError) {
        let message
        if (apiError.status >= 500) {
          if (
            apiError.data.error?.includes(
              'Order matching query does not exist.'
            )
          ) {
            message =
              'Cannot create a new order from this quote. An order already exists.'
          } else {
            message = apiError.data.message
          }
        } else if (apiError.status === 403) {
          message = apiError.data.errors[0].detail
        } else if (apiError.status === 400) {
          if (Array.isArray(apiError.data.detail)) {
            message = apiError.data.detail[0].message
          } else {
            message = Object.values(apiError.data.detail)[0][0]?.message
          }
        } else {
          message =
            Object.values(apiError.data.detail)[0][0]?.message ||
            apiError.data.error
        }

        toast(
          message ||
            'Unable to complete order. Check entry details and try again.',
          'error'
        )
      }

      return
    }

    window.sessionStorage.setItem(
      'order',
      JSON.stringify(response.data.payload)
    )
    navigateWithParams({
      pathname: resolvePathname(ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.path)
    })
  }

  return (
    <div className='w-full max-w-3xl'>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {formik => (
              <ShipmentForm
                formik={formik}
                onUpdateOrder={handleUpdateOrder}
                hubs={hubs}
              />
            )}
          </Formik>
        </>
      )}
    </div>
  )
}
