import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ShipmentModal from '../../components/orders/ShipmentModal'
import PaymentDialogue from '../../components/payment/PaymentDialogue'
import ordersApi from '../../api/orders'
import ROUTES from '../../constants/routes'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import { parseError } from '../../utils'

export default function Payment ({ metaTitle }) {
  const [searchParams] = useSearchParams()

  const multipieceId = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const orderData = useMemo(() => {
    if (multipieceId) {
      return JSON.parse(window.sessionStorage.getItem('multipieceOrders'))
    }
    return JSON.parse(window.sessionStorage.getItem('order'))
  }, [multipieceId])

  const multipieceData = JSON.parse(window.sessionStorage.getItem('multipiece'))

  const [shipment, setShipment] = useState(
    multipieceId
      ? orderData.map(() => ({ status: 'listening' }))
      : { status: 'listening' }
  )
  const [orderDetails, setOrderDetails] = useState(
    multipieceId
      ? orderData.map(data => ({
          order_id: data.order.id
        }))
      : { order_id: orderData.order.id }
  )

  const location = useLocation()

  const paymentSocket = useSelector(state => state.paymentSocket)
  const shipmentSocket = useSelector(state => state.shipmentSocket)

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { updateActiveStep, resolvePathname } = useContext(CreateOrderContext)

  const customer = useMemo(() => {
    if (multipieceId) {
      return orderData[0]?.order[billTo]
    } else {
      return orderData?.quote?.bill_to === orderData?.order.sender.customer_id
        ? orderData?.order.sender
        : orderData?.order.receiver
    }
  }, [billTo, multipieceId, orderData])

  useEffect(() => {
    return () => {
      if (shipment.status === 'processed') {
        window.sessionStorage.removeItem('order')
        window.sessionStorage.removeItem('multipiece')
        window.sessionStorage.removeItem('multipieceOrders')
      }
    }
  }, [
    dispatch,
    paymentSocket.status,
    paymentSocket.trialCount,
    shipment.status,
    shipmentSocket.status,
    shipmentSocket.trialCount
  ])

  useEffect(() => {
    updateActiveStep({
      stepId: 7,
      metaTitle,
      back: `${ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.path}${location.search}`
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, metaTitle, updateActiveStep])

  const onPaymentSuccess = () => {
    setTimeout(() => {
      shipment.status === 'listening' && setShipment({ status: 'processing' })
    }, 2000)
  }

  const handleShipmentResponse = (response, onSuccess, onError) => {
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        let error
        if (apiError.data?.status_code === 503) {
          error =
            apiError.data.message ||
            apiError.data.detail?.detail?.code ||
            'An error occured while trying to book shipment, please try again later.'
        } else if (apiError.data?.status_code === 400) {
          const _response = apiError.data.detail?.response
          const _error = Array.isArray(_response?.errors)
            ? _response.errors[0]?.message?.message
            : _response.detail?.message
          error =
            _error ||
            'An error occured while trying to book shipment, please try again.'
        } else if (apiError.data?.status_code === 422) {
          error =
            apiError.data.detail?.detail?.message ||
            'An error occured while trying to book shipment, please try again later.'
        }
        onError(error)
      }
    } else {
      const apiError = parseError(response)
      if (apiError?.data.detail) {
        const _response = apiError.data.detail?.response
        const _error = Array.isArray(_response?.errors)
          ? _response.errors[0]?.message?.message
          : _response?.TransStatusDetails?.message

        const error =
          _error ||
          'An error occured while trying to book shipment, please try again later.'
        onError(error)
      } else {
        onSuccess(response.data.payload.shipment)
      }
    }
  }

  const processOrder = async order_index => {
    if (multipieceId) {
      let requests = [...orderData]

      if (typeof order_index === 'number') {
        requests = orderData.map((data, index) =>
          index === order_index ? data : null
        )
      }

      setShipment(state => {
        requests.forEach((order, index) => {
          if (order) {
            state[index] = { status: 'processing' }
          }
        })

        return state
      })

      const responses = await Promise.all(
        requests.map(order => {
          const request = async () => {
            const response = await ordersApi.processOrder(
              order.order.id,
              order.quote
            )
            return response.data
          }
          return order ? request() : null
        })
      )

      // handle all responses
      const shipmentResponses = [...shipment]
      const _orderDetails = [...orderDetails]

      const onSuccess = (shipment, index) => {
        _orderDetails[index] = {
          ..._orderDetails[index],
          shipment,
          tpl_service: orderData[index].order.tpl_service
        }
        shipmentResponses[index] = { status: 'processed' }
      }

      const onError = (error, index) => {
        shipmentResponses[index] = { status: 'error', error }
      }

      responses.forEach((response, index) => {
        if (response) {
          handleShipmentResponse(
            response,
            shipment => onSuccess(shipment, index),
            error => onError(error, index)
          )
        }
      })

      setOrderDetails(_orderDetails)
      setShipment(shipmentResponses)
    } else {
      setShipment({ status: 'processing' })

      const response = await ordersApi.processOrder(
        orderData.order.id,
        orderData.quote
      )
      const onSuccess = shipment => {
        setOrderDetails(state => ({
          ...state,
          shipment,
          tpl_service: orderData.order.tpl_service
        }))
        setShipment({ status: 'processed' })
      }

      const onError = error => {
        setShipment({ status: 'error', error })
      }

      handleShipmentResponse(response, onSuccess, onError)
    }
  }

  const handleRetryProcessShipment = order_index => {
    processOrder(order_index)
  }

  const onCloseShipment = () => {
    navigate(ROUTES.ORDERS.path)
  }

  const isShipmentOpen = useMemo(() => {
    const status = multipieceId ? shipment[0].status : shipment.status

    return (
      status === 'processing' || status === 'processed' || status === 'error'
    )
  }, [multipieceId, shipment])

  if (!orderData) {
    return (
      <Navigate
        replace
        to={resolvePathname(`${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`)}
      />
    )
  }

  return (
    <div>
      {isShipmentOpen && (
        <ShipmentModal
          isOpen={isShipmentOpen}
          orderDetails={orderDetails}
          shipment={shipment}
          onRetry={handleRetryProcessShipment}
          shouldClose
          onClose={onCloseShipment}
          isMultipiece={!!multipieceId}
        />
      )}

      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>Payment</h2>

      <div className='flex flex-col items-center pb-8'>
        <PaymentDialogue
          orderData={orderData}
          customer={customer}
          onSuccess={onPaymentSuccess}
          setOrderDetails={setOrderDetails}
          onProcessOrder={processOrder}
          onShipment={setShipment}
          multipiece={
            multipieceId
              ? {
                  id: multipieceId,
                  total: multipieceData.total,
                  bill_to: billTo
                }
              : null
          }
        />
      </div>
    </div>
  )
}
