import React, { useMemo, useState } from 'react'
import { HiOutlineBuildingOffice } from 'react-icons/hi2'
import { HiOutlineUserCircle } from 'react-icons/hi2'
import ReactModal from 'react-modal'
import { PiUserCircleGear } from 'react-icons/pi'
import { BsPersonStanding } from 'react-icons/bs'
import ROLES from '../../constants/roles'
import States from '../../fixtures/states'
import { IoBriefcaseOutline } from 'react-icons/io5'
import { orgsActions } from '../../slices/orgsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { PiMapPinLine } from 'react-icons/pi'
import { IoLocationOutline } from 'react-icons/io5'
import useCountry from '../../hooks/useCountry'

const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '649px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}
function OrganizationFilter ({
  isBranchSpecific = true,
  isOpen,
  onClose,
  onApplyFilter,
  activeTab,
  showExternalFilter = false
}) {
  const orgs = useSelector(state => state.orgs)
  const [branch, setBranch] = useState('')
  const [status, setStatus] = useState('')
  const [role, setRole] = useState('')
  const [gender, setGender] = useState('')
  const [employmentType, setEmploymentType] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')

  const branches = useMemo(() => {
    if (!isBranchSpecific) {
      return orgs.branches
    }
  }, [isBranchSpecific, orgs.branches])

  const Country = useCountry()
  const countries = Country.getAllCountries()

  const statuses = ['Active', 'Inactive', 'Suspended']
  const genders = ['Male', 'Female']
  const employmentTypes = ['Full time', 'Part-time', 'Contract', 'Internship']
  const roleNames = Object.values(ROLES).map(role => role.name)
  const statesNames = Object.values(States).map(role => role.name)
  const dispatch = useDispatch()

  const isValid = useMemo(() => {
    return (
      branch || status || gender || employmentType || role || state || country
    )
  }, [branch, status, gender, employmentType, role, state, country])
  const handleApply = () => {
    const filter = {
      branch_name: branch,
      status,
      role,
      gender,
      employmentType,
      state,
      country
    }
    dispatch(orgsActions.filter(filter))
    onClose()
    onApplyFilter()
  }
  const onCancel = () => {
    onClose()
  }

  return (
    <ReactModal style={styles} isOpen={isOpen}>
      <div>
        <h3 className='text-lg font-semibold mb-1'>Filter Organization</h3>
      </div>
      <div className='flex flex-col gapy-3 my-3'>
        {!isBranchSpecific && activeTab !== 'Areas' && activeTab !== 'Regions' && (
          <div className='mb-5'>
            <div className='py-3 flex items-center gap-4'>
              <div className='flex gap-2 items-center'>
                <HiOutlineBuildingOffice color='#6c6c6c' size={23} />

                <p className='text-base leading-6 font-bold'>Branch</p>
              </div>
              <div>
                <select
                  className='rounded border border-gray-300'
                  placeholder='year'
                  value={branch}
                  onChange={({ target }) => {
                    setBranch(target.value)
                  }}
                >
                  <option value=''>Select branch</option>
                  {branches?.map((branch, ind) => (
                    <option key={ind} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {(activeTab === 'Areas' || activeTab === 'Regions') && (
          <div className='mb-5'>
            <div className='py-3 flex items-center gap-4'>
              <div className='flex gap-2 items-center'>
                <PiMapPinLine color='#333333E5' size={23} />

                <p className='text-base leading-6 font-bold'>Area</p>
              </div>
              <div>
                <select
                  className='rounded border border-gray-300'
                  placeholder='year'
                  value={branch}
                  onChange={({ target }) => {
                    setBranch(target.value)
                  }}
                >
                  <option value=''>Select Area</option>
                  {branches?.map((branch, ind) => (
                    <option key={ind} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Employees' && (
          <div>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <HiOutlineUserCircle color='#333333E5' size={23} />

                  <p className='font-bold text-base'> Status </p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='year'
                    value={status}
                    onChange={({ target }) => {
                      setStatus(target.value)
                    }}
                  >
                    <option value=''>Select status</option>
                    {statuses?.map((status, ind) => (
                      <option key={ind} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <PiUserCircleGear color='#333333E5' size={23} />
                  <p className='font-bold text-base'> Roles </p>
                </div>
                <div className='ml-2'>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='Select Role'
                    value={role}
                    onChange={({ target }) => {
                      setRole(target.value)
                    }}
                  >
                    <option value=''>Roles</option>
                    {roleNames?.map((role, ind) => (
                      <option key={ind} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <BsPersonStanding color='#333333E5' size={23} />
                  <p className='font-bold text-base'> Gender </p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='Select gender'
                    value={gender}
                    onChange={({ target }) => {
                      setGender(target.value)
                    }}
                  >
                    <option value=''>Gender</option>
                    {genders?.map((item, ind) => (
                      <option key={ind} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <IoBriefcaseOutline color='#333333E5' size={23} />
                  <p className='font-bold text-base'> Employment type </p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='Select gender'
                    value={employmentType}
                    onChange={({ target }) => {
                      setEmploymentType(target.value)
                    }}
                  >
                    <option value=''>Select Employment type</option>
                    {employmentTypes?.map((item, ind) => (
                      <option key={ind} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {(activeTab === 'Branches' || activeTab === 'Areas') && (
          <div>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <HiOutlineLocationMarker color='#333333E5' size={23} />

                  <p className='font-bold text-base'> State </p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='year'
                    value={state}
                    onChange={({ target }) => {
                      setState(target.value)
                    }}
                  >
                    <option value=''>Select state</option>
                    {statesNames?.map((state, ind) => (
                      <option key={ind} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'Regions' && (
          <div className='mb-5'>
            <div className='py-3 flex items-center gap-4'>
              <div className='flex gap-2 items-center'>
                <PiMapPinLine color='#333333E5' size={23} />

                <p className='text-base leading-6 font-bold'>Region</p>
              </div>
              <div>
                <select
                  className='rounded border border-gray-300'
                  placeholder='year'
                  value={branch}
                  onChange={({ target }) => {
                    setBranch(target.value)
                  }}
                >
                  <option value=''>Select Region</option>
                  {branches?.map((branch, ind) => (
                    <option key={ind} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {showExternalFilter && (
          <>
            {activeTab === 'Regulars' && (
              <div className='mb-5'>
                <div className='py-3 flex items-center gap-4'>
                  <div className='flex gap-2 items-center '>
                    <HiOutlineUserCircle color='#333333E5' size={23} />

                    <p className='font-bold text-base'> Status </p>
                  </div>
                  <div>
                    <select
                      className='rounded border border-gray-300'
                      placeholder='year'
                      value={status}
                      onChange={({ target }) => {
                        setStatus(target.value)
                      }}
                    >
                      <option value=''>Select status</option>
                      {statuses?.map((status, ind) => (
                        <option key={ind} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center '>
                  <IoLocationOutline color='#333333E5' size={23} />

                  <p className='font-bold text-base'> Location </p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='year'
                    value={country}
                    onChange={({ target }) => {
                      setCountry(target.value)
                    }}
                  >
                    <option value=''>Select location</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className='flex justify-end gap-2'>
        <button className='btn btn-outline text-gray-800' onClick={onCancel}>
          Cancel
        </button>
        <button
          className={`btn ${
            !isValid ? 'btn-neutral btn-disabled' : 'btn-primary'
          }`}
          disabled={!isValid}
          onClick={handleApply}
        >
          Save
        </button>
      </div>
    </ReactModal>
  )
}

export default OrganizationFilter
