import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartners } from '../../slices/customersSlice'
import Pagination from '../globals/pagination/ServerPagination'
import AddressBookItem from './AddressBookItem'
import noDataIcon from '../../assets/no-data.webp'
import Search from '../globals/Search/Search'
import SearchResultsDescription from '../globals/Search/SearchResultsDescription'
import { Loader } from '../globals'

const searchOptions = [
  {
    name: 'Name',
    value: 'full_name'
  },
  {
    name: 'Company',
    value: 'business_name'
  }
]

export default function AddressBookPartners ({
  activeTab,
  queryParams,
  setQueryParams,
  searchBy,
  setSearchBy,
  searchValue,
  onSearch,
  onServerSearch,
  serverSearch,
  onCloseServerSearch,
  onClickCustomer,
  customerType,
  sender,
  receiver
}) {
  const partners = useSelector(state => state.customers.partners)

  const [serializedData, setSerializedData] = useState(null)

  const dispatch = useDispatch()

  const loadData = useCallback(() => {
    dispatch(fetchPartners(queryParams))
  }, [dispatch, queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadData()
  }, [loadData])

  const customers = useMemo(() => {
    if (serializedData) {
      let results = serializedData
      if (searchValue) {
        results = serializedData.filter(customer =>
          customer[searchBy].toLowerCase().includes(searchValue.toLowerCase())
        )
      }

      return results
    } else return null
  }, [searchBy, searchValue, serializedData])

  const onPage = params => {
    setQueryParams(state => ({ ...state, ...params }))
  }

  const selectedCustomer = useMemo(() => {
    if (customerType === 'receiver') {
      return serializedData?.find(
        customer => customer.id === receiver?.customer_id
      )
    }
    if (customerType === 'sender') {
      return serializedData?.find(
        customer => customer.id === sender?.customer_id
      )
    }
  }, [customerType, receiver, sender, serializedData])

  const searchInputPlaceHolder = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (searchBy) {
      case 'full_name':
        return 'Enter customer name'
      case 'business_name':
        return 'Enter company name'
    }
  }, [searchBy])

  return (
    <div>
      <div className='border-b pb-2 space-y-1.5'>
        <div className='flex flex-wrap flex-row gap-2 justify-between items-start'>
          <Search
            value={searchValue}
            searchBy={searchBy}
            multiple
            searchOptions={searchOptions}
            onSearchOptionChange={option => setSearchBy(option)}
            inputPlaceHolder={searchInputPlaceHolder}
            handleSearch={onSearch}
            allowServerSearch
            onServerSearch={onServerSearch}
            floatPosition='left'
          />
          <div className='ml-auto'>
            <Pagination
              tableId='partners-table'
              pageSize={partners.meta?.page_size}
              totalCount={partners.meta?.count}
              data={partners.data}
              setSerializedData={setSerializedData}
              onPage={onPage}
              page={partners.meta?.page}
              // findItem={selectedCustomer?.s_n}
            />
          </div>
        </div>
        {serverSearch && (
          <SearchResultsDescription
            searchState={serverSearch}
            onClose={onCloseServerSearch}
          />
        )}
      </div>

      <div className='pt-2 flex flex-col min-h-[50vh] max-h-[80vh] overflow-y-auto'>
        {!customers ? (
          <Loader />
        ) : customers.length ? (
          customers.map(item => (
            <AddressBookItem
              selectedCustomer={selectedCustomer}
              isSender={sender?.customer_id === item.id}
              key={item.id}
              item={item}
              handleOnClick={onClickCustomer}
              tab={activeTab}
            />
          ))
        ) : (
          <div className='py-5 w-full flex flex-col gap-2 items-center justify-center'>
            <img
              src={noDataIcon}
              className='w-40 h-40 object-contain'
              alt='no data icon'
            />
            <p className='no_data_description_text'>No customer found</p>
          </div>
        )}
      </div>
    </div>
  )
}
