import { getUrlQueryString } from '../utils'
import bookingClient from './clients/bookingClient'

const endpoint = 'transactions/'

const initiate = async payload => {
  const response = await bookingClient.post(endpoint, payload)
  return response
}

const fetchTransactions = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(
    search_params ? `${endpoint}?${search_params}` : endpoint
  )

  return response
}

const confirmCashPayment = async ref => {
  const response = await bookingClient.post(
    `${endpoint}${ref}/confirm_payment/`
  )
  return response
}

const fetchUnremittedCashTransactions = async ref => {
  const response = await bookingClient.get(
    `${endpoint}get_unremitted_transactions/`
  )
  return response
}

const remitCashTransactions = async payload => {
  const response = await bookingClient.patch(
    `${endpoint}remit_cash_transactions/`,
    payload
  )
  return response
}

const exportTransactions = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(
    `${endpoint}export/?${search_params}`
  )
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initiate,
  fetchTransactions,
  confirmCashPayment,
  fetchUnremittedCashTransactions,
  remitCashTransactions,
  exportTransactions
}
